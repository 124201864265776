export class FormatParcialSave {
    instanceId:  string;
    containerId: string;
    values: any[];
    constructor(instanceId: string, containerId: string, values: any[]) {
        this.instanceId = instanceId.toUpperCase();
        this.containerId = containerId;
        this.values = values;
    }

    getValues() {
        const result = {
            instanceId: this.instanceId,
            containerId: this.containerId,
            values: this.values
        };

        return result;
    }
}
