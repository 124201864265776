export class MenuItem {
    name: string;
    url: string;
}

export class MenuItems {
    title: string;
    items: MenuItem[];
}

