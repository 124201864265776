import { DtoDatafrontElementEnableRule } from './DtoDatafrontElementEnableRule';
import { HelperType } from '../../enums';
import { DtoDatafrontSection } from './DtoDatafrontSection';

export class DtoDatafrontElement {
  label: string;
  fullName: string;
  description: string;
  elementType: number;
  isRequired = false;
  isHidden = false;
  isTitle = false;
  readOnly = false;
  backgroundType: number;
  helperText: string;
  helperDescription: string;
  helperType: HelperType;
  enableRule: DtoDatafrontElementEnableRule;
  latitude: string;
  longitude: string;
  mapFunction: DtoDatafrontElementEnableRule;
  mask: string;
  maskAllowNegative: boolean;
  maskPrefix: string;
  maskSuffix: string;
  maskDescription: string;
  selectionIndex: number;
  cols = new Array<any>();
  sections = new Array<DtoDatafrontSection>();
  formatFunction: any;
  columnFunction: any;
  showInTable = false;
  formEditSelectionType: number;
  formEditAllowEdit = true;
  formEditAllowAdd = true;
  formEditAllowDelete = true;
  formEditAllowQuery = true;
  formEditAllowDuplicate = true;
  formEditMaxRows = null;
  errorText?: string;

  constructor(label?: string, fullName?: string, isRequired?: boolean, elementType?: number) {
    this.label = label;
    this.fullName = fullName;
    this.isRequired = isRequired;
    this.elementType = elementType;
  }
}
