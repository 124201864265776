import { ElementRef, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'titleStyle'
})

export class CustomTitlePipe implements PipeTransform {
  constructor(private readonly _sanitizer: DomSanitizer, private readonly _element: ElementRef, private readonly _render: Renderer2) {
  }

  transform(text: string): any {
    if (!text) return;
    const words = text.split(' ');
    return this._sanitizer.bypassSecurityTrustHtml(`<strong>${ words.shift() }</strong> ${ words.join(' ') }`);
  }
}
