export function getCookieValue(flag: string) {
  const cookieList = document.cookie;
  const cookies = cookieList.split(';');
  return cookies.filter(e => e.indexOf(flag) > -1).map(e => e.substr(flag.length).trim());
}

export function getAdalConfig() {
  return {
    navigateToLoginRequestUrl: true,
    tenant: window['__env'].APIS.TENANTID,
    clientId: window['__env'].OFFICE365.CLIENT_ID,
    redirectUri: window.location.origin,
    cacheLocation: window['__env'].OFFICE365.LOCATION,
    postLogoutRedirectUri: window['__env'].OFFICE365.POSTREDIRECT
  };
}


export function getMsalConfig() {
  
  const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
  const _env = window['__env'];
  
  return {
    mainConfiguration: {
      auth: {
        clientId: _env.MSAL.CLIENT_ID,
        authority: _env.MSAL.AUTHORITY,
        validateAuthority: false,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: _env.OFFICE365.POSTREDIRECT,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: _env.OFFICE365.LOCATION,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
        tokenRenewalOffsetSeconds: 30,
      },
    },
    projectConfiguration: {
      popUp: false,
      consentScopes: _env.MSAL.SCOPES
    }
  };
}