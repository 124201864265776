import { ProducMenu } from '../../../modules/products/pages/product-edit/produc-menu.enum';
import { ProductEditService } from '../../../modules/products/pages/product-edit/product-edit.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ContainerLevel } from '../../enums/product-designer.enum';


@Component({
  selector: 'app-product-browser-item',
  templateUrl: './product-browser-item.component.html',
  styleUrls: ['./product-browser-item.component.scss']
})
export class ProductBrowserItemComponent {
  producMenu = ProducMenu;
  @Input() data: ItemTree;
  @Input() selected: any;
  @Output() children = new EventEmitter();
  @Output() menu = new EventEmitter();
  @Input() enableRadioBtn = false;
  @Input() enableCheckBtn = false;
  ContainerLevel = ContainerLevel;

  constructor(private productEditService: ProductEditService) {
  }

  @HostListener('click') clickItem() {
    this.openChildren(this.data.containerId, this.selected);
  }

  menuAction(id, action) {
    this.menu.emit({ id: id, action: action });
  }

  openChildren(id, selected) {
    if (selected !== id) {
      this.children.emit(id);
    }
  }

  toggleCheck(ev) {
    this.productEditService.browserMap.get(this.data.containerId).check = ev.checked;
  }
}

interface ItemTree {
  containerId: string;
  title: string;
  status: number;
  customStatus: number;
  statusEvent: number;
  customStatusInfo: string;
  check?: boolean;
  parents?: string[];
}
