import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OriginationsService } from '../../../../shared/services/originations.service';
import { catchError, takeWhile } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alerts.service';

@Component({
  selector: 'app-modal-task-attention',
  styleUrls: ['./modal-task-attention.component.scss'],
  template: `
    <h2 mat-dialog-title>ATENDER</h2>
    <div mat-dialog-content>
      <div engineLoaderContainer>
        <app-non-blocking-modal-loader name="assignDocumentToAgent"></app-non-blocking-modal-loader>
        <div style='margin-bottom: 15px;'>
          <span style='font-weight: 500;'>¿Usted está seguro que desea atender las tareas abiertas en esta solicitud?</span>
        </div>
        <div>
          <span style='font-size: 14px;'>
            Una vez que acepte atender las tareas abiertas en esta solicitud, será el responsable de darle termino.
          </span>
        </div>
      </div>
    </div>
    <div mat-dialog-actions style="display: flex; flex-direction: row-reverse; width: 100%;">
      <scc-custom-button [disabled]="isProcessing" kind="confirm" type="submit" (customClick)="acceptAttend()">
        ATENDER
      </scc-custom-button>
      <scc-custom-button [disabled]="isProcessing" kind="cancel" (customClick)="dialogRef.close()">
        CANCELAR
      </scc-custom-button>
    </div>
  `
})
export class ModalTaskAttentionComponent implements OnDestroy {
  isProcessing = false;
  private cancelRequest = false;

  constructor(public dialogRef: MatDialogRef<ModalTaskAttentionComponent>, private readonly _originations: OriginationsService,
              @Inject(MAT_DIALOG_DATA) 
              private readonly data: string, 
              private _alert: AlertService) {

    dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    this.cancelRequest = true;
  }

  acceptAttend() {
    if (!this.data) throw new Error('Instancia desconocida en la asignación.');
    this.isProcessing = true;
    this._originations.assign(this.data).pipe(
      takeWhile(() => !this.cancelRequest),
      catchError(err => {
        this.isProcessing = false;
        this.dialogRef.close();
        return [];
      })).subscribe(() => {
      this._alert.success('Solicitud tomada con éxito.');
      this.dialogRef.close(true);
    });
  }
}
