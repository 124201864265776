import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BrowserComponent } from '../browser/browser.component';
import { IBrowserColumnItem } from '../browser/browser-column-item';
import { DOCUMENT } from '@angular/common';

export enum CrType {
  CHECKBOX,
  RADIOBUTTON
}

@Component({
  selector: 'app-browser-cr',
  templateUrl: './cr-browser.component.html',
  styleUrls: ['./cr-browser.component.scss']
})
export class CrBrowserComponent extends BrowserComponent implements OnInit {
  @Input() type: CrType;
  @Input() isFolder = false;
  @Output() itemToggled = new EventEmitter<string>();
  private id: string;
  private ids: Array<string> = [];

  constructor(@Inject(DOCUMENT) protected _document: Document) {
    super(_document);
  }

  ngOnInit() {
    if (this.type === undefined) {
      throw new Error('Se requiere especificar un tipo');
    }
  }

  public container(): boolean {
    return this.type === CrType.RADIOBUTTON;
  }

  prepare(item: IBrowserColumnItem, isParent = false) {
    let c = 0;
    this._onSelect.emit(item.id);
    this.columns.forEach(col => {
      c++;
      if (col.items.indexOf(item) >= 0) {
        this.columns.length = c;
        return;
      }
    });
    if (item.childrens.length > 0 && !isParent) {
      this.columns.push({
        title: this.title(this.columns.length),
        items: this.buildBrowserColumnItems(item),
        selected: true
      });
    }
    item.active = true;
    this.decoration(item);

    if (CrType.RADIOBUTTON === this.type) {
      this.columns.forEach(col => {
        col.items.forEach(i => {
          i.icon = 'radio_button_unchecked';
        });
      });
      item.icon = 'radio_button_checked';
      this.id = item.id;
    }
  }

  public selectedId(): string {
    return this.id;
  }

  public selectedIds(): Array<string> {
    return this.ids;
  }

  public setSelectedIds(ids: Array<string>) {
    this.ids = ids;
  }

  public select(item: IBrowserColumnItem) {
    if (this.ids.indexOf(item.id) < 0) {
      this.ids.push(item.id);
    } else {
      this.ids.splice(this.ids.indexOf(item.id), 1);
    }
    item.selected = !item.selected;
    this.itemToggled.emit(item.id);
  }

  public checkSelected(sid: string): boolean {
    return this.ids.some((id) => (id === sid));
  }

  public selectChilds(id: string) {
    if (this.ids.indexOf(id) < 0) {
      this.ids.push(id);
    }
    const item = this.findBrowserItem(id);
    item.childrens.forEach(cid => {
      this.ids.push(cid);
    });
  }

  public checkSelection(selectedIds: Array<string>): void {
    selectedIds.forEach(id => {
      if (this.ids.indexOf(id) < 0) {
        this.ids.push(id);
      }
    });
  }

  public checkChildren(parentId: string): void {
    const parentItem = this.findBrowserItem(parentId);
    if (parentItem.childrens.length) {
      parentItem.childrens.forEach(childId => {
        if (this.ids.indexOf(childId) < 0) {
          this.ids.push(childId);
          this.checkChildren(childId);
        }
      });
    }
  }

  public unCheckChildren(parentId: string): void {
    const parentItem = this.findBrowserItem(parentId);
    if (parentItem.childrens.length) {
      parentItem.childrens.forEach(childId => {
        const index = this.ids.indexOf(childId);
        this.ids.splice(index, 1);
        this.unCheckChildren(childId);
      });
    }
  }

  public canShowSelectAllOption(parentId: string): boolean | undefined {
    const parentItem = this.findBrowserItem(parentId) || undefined;
    if (parentItem) {
      return !!parentItem.childrens.find(childId => !this.ids.includes(childId));
    }
    return true;
  }

  public canShowDeselectAllOption(parentId: string): boolean {
    const parentItem = this.findBrowserItem(parentId);
    if (parentItem) {
      return !!parentItem.childrens.find(childId => this.ids.includes(childId));
    }
    return false;
  }
}


