export enum InstanceContainerStatus {
  CLOSED = 0,
  ERROR = 1,
  PROCESSING = 2,
  OPEN = 3,
  TOCONFIRM = 4,
  INVALID = 5,
  VALID = 6,
  CONFIRMED = 7,
  REJECTED = 8
}
