import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
declare let Chart: any;

// #region Constants

const CHART_LIB_URL = 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/4.2.1/chart.min.js';
const BASE_COLOR = '0, 77, 107';

// #endregion

/**
 * Component to render the MongoDB Charts using the library   
 * MongoDB Charts Embedding SDK
 * https://www.npmjs.com/package/@mongodb-js/charts-embed-dom
 * Example of use:
 * <mg-chart></mg-chart>
 */
@Component({
  selector: 'app-chart',
  template: '<div><canvas #chart></canvas></div>',
})
export class AppChartComponent {

  // #region Inputs

  @Input() chartType: string = '';

  @Input() data: number[] = [];

  // #endregion

  @ViewChild('chart') chartRef: ElementRef;

  constructor(private renderer: Renderer2) { }

  // function to render the chart when data changes
  public ngOnChanges(): void {

    if (this.data) {

      this.renderChart();
    }
  }

  renderChart() {

    const labels = Object.keys(this.data);
    const data = Object.values(this.data);
    const colors = this.generateColors(labels.length);
    const element = this.chartRef.nativeElement;
    const type = this.chartType;

    const script = this.loadLibrary();
    script.onload = () => {

      //console.log(this.chartRef.nativeElement);
      new Chart(element, {
        type: type,
        options: {
          responsive: true,
          legend: {
            display: false,
            position: 'bottom',
          }
        },
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: colors,
            hoverOffset: 4
          }]
        }
      });
    }
  }

  loadLibrary() {

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = CHART_LIB_URL;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  generateColors(count: number) {

    const colors = [];
    
    for (let i = 0; i < count; i++) {

      const opacity = (i + 1) / count;
      colors.push(`rgba(${BASE_COLOR}, ${opacity})`);
    }

    return colors;
  }
}
