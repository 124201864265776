import { Injectable } from '@angular/core';
import { getCLS, getFID, getLCP } from 'web-vitals';
import { MonitorService } from './monitor.service';

@Injectable({
    providedIn: 'root'
})
export class WebVitalsService {

    private url: string = '';

    constructor(
        private readonly _monitorService: MonitorService
    ) {}

    logWebVitals(url: string): void {

        this.url = url;

        getCLS(this.sendToAnalytics);
        getFID(this.sendToAnalytics);
        getLCP(this.sendToAnalytics);
    }

    sendToAnalytics = (metric: any) => {

        const logEvent: string = `web-vitals:${metric.name.toLowerCase()}`;
                
        const data = {
            url: this.url,
            metrics: metric
        };

        this._monitorService.logEvent(logEvent, data);
    }
}