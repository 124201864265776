import { MatCheckbox, MatSort } from '@angular/material';
import { TableColumn, TableConfig } from './table-config.interface';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

export abstract class CustomTable<C extends TableConfig = TableConfig> {
  // WARNING: Propiedades reservadas.
  public readonly MENU_PROP_NAME = '__menu';
  public readonly CHECKBOX_PROP_NAME = '__checkbox';

  private _dataSource: TableVirtualScrollDataSource<any> = new TableVirtualScrollDataSource<any>();
  private _sort: MatSort;
  private _configuration: C;
  private _columns: Array<string> = new Array<string>();

  get dataSource() {
    return this._dataSource;
  }

  set sort(s: MatSort) {
    this._sort = s;
  }

  get configuration() {
    return this._configuration as C;
  }

  get headerColumns() {
    return this.configuration && 'showHeader' in this.configuration
      && ((this.configuration.showHeader && this._columns) || (!this.configuration.showHeader && [])) || this._columns;
  }

  get columns() {
    return this._columns;
  }

  set columns(data: string[]) {
    this._columns = data;
  }

  /**
   * Función que valida la existencia de una plantilla externa en la columna.
   * @param column Columna.
   */
  isCellTemplate(column: TableColumn): boolean {
    return !!column.template;
  }

  /**
   * Función que regresa el valor correspondiente de la celda.
   * @param row Registro.
   * @param column Columna.
   */
  cellValue(row: any, column: TableColumn): any {
    const value = row[column.field];
    const valueTransformed = column.transform && column.transform(value) || value;
    return valueTransformed && `${ column.prefix || '' }${ valueTransformed }${ column.suffix || '' }`;
  }

  /**
   * Función ejecuta una serie de mecanismos para preparar la lista de la tabla.
   * @param config Configuración.
   * @private
   */
  protected _initSource(config: C): void {
    if (!config) return;
    this._reset();
    this._prepareConfiguration(config);
    this._defaultFilter();
    this._sortColumns();
    this._adjust();
  }

  /**
   * Función que detona el filtrado en la tabla.
   * @param search Texto a buscar
   * @private
   */
  protected _searchFor(search: string): void {
    this._dataSource.filter = search;
  }

  /**
   * Función que establece el predicado a utilizar en el filtrado de la tabla.
   * @private
   */
  protected _defaultFilter(): void {
    this._dataSource.filterPredicate = (item) => {
      return this._getFilterResult(item);
    };
  }

  /**
   * Función que realiza la busqueda en la tabla.
   * @param row Registro de la tabla.
   * @private
   */
  protected _getFilterResult(row: any): boolean {
    const values = this._getOnlyValuesRegardColumns(row);
    for (let i = 0; i < values.length; i++) {
      const res = values[i] && values[i].toString().toLowerCase().includes(this._dataSource.filter.toLowerCase());
      if (res) return true;
    }
  }

  /**
   * Función que retorna un nuevo modelo con solo las propiedades que estan definidas en las columnas.
   * @param obj Modelo.
   * @private
   */
  protected _getOnlyValuesRegardColumns(obj: any): any[] {
    const destructuredRow = Object.entries(obj).filter(prop => this._configuration.columns
      .findIndex(p => (p.field === prop[0] || this._isRowValid(prop))) > -1);
    return destructuredRow.map(prop => prop[1]);
  }

  /**
   * Función abstracta que tendra que ser implementada en cada tipo de tabla existente en engine.
   * @private
   */
  protected abstract _adjust(): void;

  /**
   * Función que ordena las columnas de la configuración con las propias de la tabla.
   * @private
   */
  protected abstract _sortColumns(): void;

  /**
   * Función abstracta que valida la entrada del registro al source de la tabla.
   * @param row Registro.
   * @private
   */
  protected abstract _isRowValid(row: [string, any]): boolean;

  /**
   * Function that is used by selectable table components.
   * @param index
   * @param value
   * @param control
   */
  public selectItem(index: number, value: any, control: MatCheckbox): void {
  }

  /**
   * Función que reinicia el datasource de la tabla.
   * @private
   */
  private _reset(): void {
    this._dataSource = new TableVirtualScrollDataSource<any>([]);
    this._dataSource.sort = this._sort;
  }

  /**
   * Función que realiza procesos requeridos y adicionales a los que ya existen.
   * @param config Configuración.
   */
  private _prepareConfiguration(config: C): void {
    this._configuration = config;
    this._configuration.source = JSON.parse(JSON.stringify(config.source));
  }
}

