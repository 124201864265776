import { User } from './index';

export class Credit {
  //#region Required properties JSON
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  amount: number;
  status: string;
  //#endregion
}

export class Relation {
  //#region Required properties JSON
  id: string;
  title: string;
  user: User;
  clientType: string;
  productName: string;
  idProduct: string;
  credits: Credit[];
  //#endregion
}
