const maskConfigs =  {
    defaultAllowKeyCodes : /Delete|Backspace|Enter|ArrowRight|ArrowLeft|ArrowDown|ArrowUp|Home|PageUp|PageDown|End|F5|Tab/,
    'comma_separator.0':{
        type: 'numeric',
        allowChars: ['\\d'],
        allowDecimals: false,
        milesSeparetor: ',',
        lockKeysRegex: /\d|\-/,
        validation: false,
        sendFormatedValue: false
    },
    'comma_separator.2':{
        type: 'numeric',
        allowChars: ['\\d','\\.'],
        allowDecimals: true,
        milesSeparetor: ',',
        lockKeysRegex: /\d|\.|\-/,
        separetorKeyRegex : /\./,
        validation: false,
        sendFormatedValue: false
    },
    '0*':{
        type: 'numeric',
        allowChars: ['\\d'],
        allowDecimals: false,
        milesSeparetor: '',
        lockKeysRegex: /\d|\-/,
        validation: false,
        sendFormatedValue: false
    },
    'IP':{
        type: 'block',
        allowChars: ['\\d','\\.'],
        lockKeysRegex: /\d|\./,
        separetorKeyRegex : /\./,
        validation: true,
        sendFormatedValue: true,
        blocks:[
            {
                "min": 1,
                "max": 255,
                "minLength": 1,
                "maxLength": 3,
                "separetor": "."
            },
            {
                "min": 0,
                "max": 255,
                "minLength": 1,
                "maxLength": 3,
                "separetor": "."
            },
            {
                "min": 0,
                "max": 255,
                "minLength": 1,
                "maxLength": 3,
                "separetor": "."
            },
            {
                "min": 0,
                "max": 255,
                "minLength": 1,
                "maxLength": 3,
                "separetor": ""
            }
        ]
    },
    'Hh:m0:s0':{
        type: 'block',
        allowChars: ['\\d','\\:'],
        lockKeysRegex: /\d/,
        separetorKeyRegex : /:/,
        validation: true,
        sendFormatedValue: false,
        blocks:[
            {
                "min": 0,
                "max": 23,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ":"
            },
            {
                "min": 0,
                "max": 59,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ":"
            },
            {
                "min": 0,
                "max": 59,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ""
            }
        ]
    },
    '00/00/0000':{
        type: 'block',
        allowChars: ['\\d','\\/'],
        lockKeysRegex: /\d/,
        separetorKeyRegex : /\//,
        validation: true,
        sendFormatedValue: false,
        blocks:[
            {
                "min": 1,
                "max": 31,
                "minLength": 2,
                "maxLength": 2,
                "separetor": "/"
            },
            {
                "min": 1,
                "max": 12,
                "minLength": 2,
                "maxLength": 2,
                "separetor": "/"
            },
            {
                "min": 0,
                "max": 9999,
                "minLength": 4,
                "maxLength": 4,
                "separetor": ""
            }
        ]
    },
    '00/00/0000 Hh:m0:s0':{
        type: 'block',
        allowChars:  ['\\d','\\/','\\:','\\ '],
        lockKeysRegex: /\d/,
        separetorKeyRegex : /\/|:/,
        validation: true,
        sendFormatedValue: false,
        blocks:[
            {
                "min": 1,
                "max": 31,
                "minLength": 2,
                "maxLength": 2,
                "separetor": '/'
            },
            {
                "min": 1,
                "max": 12,
                "minLength": 2,
                "maxLength": 2,
                "separetor": '/'
            },
            {
                "min": 0,
                "max": 9999,
                "minLength": 4,
                "maxLength": 4,
                "separetor": ' '
            },
            {
                "min": 0,
                "max": 23,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ':'
            },
            {
                "min": 0,
                "max": 59,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ':'
            },
            {
                "min": 0,
                "max": 59,
                "minLength": 2,
                "maxLength": 2,
                "separetor": ''
            }
        ]
    },
};

export default maskConfigs;