import { Injectable } from '@angular/core';
import { CatalogPermissions } from '../enums/catalog-permissions.enum';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BehaviorSubject } from 'rxjs';
import { CATALOGS } from '../enums/catalogs.enum';
import { ProfileService } from '../../shared/services/profile.service';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly LOCAL_STORAGE_KEY = 'permissions';
  private readonly toolTipText = 'No tienes permiso para realizar esta acción';

  // Permisos de catalogo principal
  CAN_CREATE = false;
  CAN_READ = false;
  CAN_UPDATE = false;
  CAN_DELETE = false;

  TOOLTIP_CREATE: string;
  TOOLTIP_READ: string;
  TOOLTIP_UPDATE: string;
  TOOLTIP_DELETE: string;

  // Permisos de catalogo externo al que se quiere accesar si es que se tiene.
  CAN_CREATE_EXTERNAL = false;
  CAN_READ_EXTERNAL = false;
  CAN_UPDATE_EXTERNAL = false;
  CAN_DELETE_EXTERNAL = false;

  TOOLTIP_CREATE_EXTERNAL: string;
  TOOLTIP_READ_EXTERNAL: string;
  TOOLTIP_UPDATE_EXTERNAL: string;
  TOOLTIP_DELETE_EXTERNAL: string;

  catalogPermissions = CatalogPermissions;
  permissions: any;
  idsPermissionsToIgnore = [CATALOGS.CREATE_INSTANCE, CATALOGS.REASSIGNATION, CATALOGS.DATA_EXPORT];
  urlsAllowed: Array<string> = [];

  permissionsReady = new BehaviorSubject<boolean>(false);

  constructor(private readonly translate: TranslateService, private readonly _localStorage: LocalStorage,
              private readonly _profile: ProfileService) {
    this.recoverPermissions();
    this._profile.userProfile$.pipe(filter(info => !!info), take(1)).subscribe(user => {
      this.permissions = user.permissions.permissions;
      this.permissionsReady.next(true);
    });
  }

  public setPermissions(idModule: string, idCatalog: string, idExternalModule?: string, idExternalCatalog?: string): void {
    const catalogFound = this.findCatalog(idModule, idCatalog);
    let externalCatalogFound;
    if (idExternalModule && idExternalCatalog) {
      externalCatalogFound = this.findCatalog(idExternalModule, idExternalCatalog);
    }
    if (catalogFound) this.initializeFlags(catalogFound, externalCatalogFound);
    this.savePermissions();
  }

  findCatalog(idModule: string, idCatalog: string) {
    const moduleFound = this.permissions.find(m => m.id === idModule);
    if (moduleFound) {
      return moduleFound.catalogs.find(c => c.id === idCatalog);
    }
  }

  canReadCatalog(idModule: string, idCatalog: string): boolean {
    const catalogFound = this.findCatalog(idModule, idCatalog);
    return !!catalogFound['actions'].find((a: any) => a === this.catalogPermissions.READ);
  }

  savePermissions(): void {
    this._localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.permissions)).subscribe();
  }

  recoverPermissions(): void {
    this._localStorage.getItem(this.LOCAL_STORAGE_KEY).subscribe(data => {
      if (data) {
        this.permissions = JSON.parse(data);
        this._localStorage.removeItem(this.LOCAL_STORAGE_KEY).subscribe();
      }
    });
  }

  private initializeFlags(catalog: any, externalCatalog?: any): void {
    this.CAN_CREATE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.CREATE);
    this.CAN_READ = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.READ);
    this.CAN_UPDATE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.UPDATE);
    this.CAN_DELETE = !!catalog['actions'].find((p: any) => p === this.catalogPermissions.DELETE);

    if (externalCatalog) {
      this.CAN_CREATE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.CREATE);
      this.CAN_READ_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.READ);
      this.CAN_UPDATE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.UPDATE);
      this.CAN_DELETE_EXTERNAL = !!externalCatalog['actions'].find((p: any) => p === this.catalogPermissions.DELETE);
    }

    this.getToolTips(externalCatalog);
  }

  private getToolTips(externalCatalog?: any): void {
    this.TOOLTIP_CREATE = this.CAN_CREATE ? '' : this.toolTipText;
    this.TOOLTIP_READ = this.CAN_READ ? '' : this.toolTipText;
    this.TOOLTIP_UPDATE = this.CAN_UPDATE ? '' : this.toolTipText;
    this.TOOLTIP_DELETE = this.CAN_DELETE ? '' : this.toolTipText;

    if (externalCatalog) {
      this.TOOLTIP_CREATE_EXTERNAL = this.CAN_CREATE_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_READ_EXTERNAL = this.CAN_READ_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_UPDATE_EXTERNAL = this.CAN_UPDATE_EXTERNAL ? '' : this.toolTipText;
      this.TOOLTIP_DELETE_EXTERNAL = this.CAN_DELETE_EXTERNAL ? '' : this.toolTipText;
    }

  }
}
