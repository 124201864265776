import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from '../../shared/models';
import { Observable } from 'rxjs';
import { ProfileService } from '../../shared/services/profile.service';
import { UserInfoService } from '../services/user-info.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProfileResolverService implements Resolve<User> {

  constructor(private readonly _profileSrv: ProfileService, private readonly _userInfo: UserInfoService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this._profileSrv.getUser().pipe(tap(user => this._userInfo.userProfile$.next(user)));
  }
}
