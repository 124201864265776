import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomTableV2Component } from '../../custom-table-v2.component';
import { TableConfig } from '../../table-config.interface';
import { MatCheckbox, MatSort } from '@angular/material';
import { SelectableCheckboxTableItem } from '../../models/selectable';

@Component({
  selector: 'app-selectable-checkbox',
  templateUrl: '../../custom-table-v2.component.html',
  styleUrls: ['./selectable-checkbox.component.scss', '../../custom-table-v2.component.scss']
})
export class SelectableCheckboxComponent extends CustomTableV2Component {
  private _selections = new Map<number, any>();
  private _selectedItems = 0;

  @Input() set source(config: TableConfig) {

    config = this._customizeConfig(config);
    // this.resetSelections();
    this._initSource(config);
  }

  @Input() set filter(search: string) {
    this._searchFor(search);
  }

  get filter() {
    return this.dataSource.filter;
  }

  @Input() limitSelection: number;

  @Output() scrollIndex = new EventEmitter<number>();
  @Output() selected = new EventEmitter<SelectableCheckboxTableItem>();

  @ViewChild(MatSort) set sortCmp(s: MatSort) {
    this.sort = s;
  }

  /**
   * Sets the state of selections
   * @param data
   */
  set selections(data: Map<number, any>) {
    this._selections = data;
  }

  /**
   * Emits an object containing index and element selected.
   * @param index Index of the element
   * @param value Element selected
   * @param control MatCheckbox
   */
  public selectItem(index, value, control: MatCheckbox): void {

    const selection = { index: this.dataSource.data.indexOf(value), value } as SelectableCheckboxTableItem;
    // Remove for now the limitation of selection
    // if (control.checked && this.limitSelection !== undefined && !this._isSelectable()) {
    //   control.checked = false;
    //   return;
    // }
    if (control.checked) {
      this._selections.set(selection.index, value);
    } else {
      this._selections.delete(selection.index);
    }
    value[this.CHECKBOX_PROP_NAME] = control.checked;
    this._selectedItems = this.dataSource.data.filter(item => !!item[this.CHECKBOX_PROP_NAME]).length;
    this.selected.emit(selection);
  }

  /**
   * Returns the user selections count.
   */
  public getSelectionsCount(): number {
    return this._selectedItems;
  }

  /**
   * Returns the selections by the user.
   */
  public getSelections(): ReadonlyArray<any> {
    return Array.from(this._selections) as ReadonlyArray<any>;
  }

  /**
   * Resets the selections in table by the user.
   */
  public resetSelections(): void {
    this._selections.clear();
    this.dataSource.data.forEach(item => item[this.CHECKBOX_PROP_NAME] = false);
    this._selectedItems = 0;
  }

  /**
   * Implementación de función abstracta.
   * @private
   */
  protected _sortColumns(): void {
    const columns = this.configuration.columns.map(c => c.field);
    if (Array.isArray(this.configuration.menu) && this.configuration.menu.length) {
      this.columns = [this.CHECKBOX_PROP_NAME, columns[0], this.MENU_PROP_NAME, ...columns.slice(1)];
    } else {
      this.columns = columns;
    }
  }

  /**
   * Adds extra data to configuration object.
   * @param source Source defined by the user
   * @param columns Columns defined by the user
   * @param rest Rest of the properties
   * @private
   */
  private _customizeConfig({ source, columns, ...rest }): TableConfig {

    const transformedSource = Object.assign([],source)  as Array<any>;
    
    transformedSource.forEach(
      (item, index) => {

        item[this.CHECKBOX_PROP_NAME] = this._selections.has(index);
      }
    );

    return { source: transformedSource, columns, ...rest };
  }

  private _isSelectable(): boolean {
    return Number.isInteger(+this.limitSelection) && this._selectedItems < this.limitSelection;
  }

  isAllSelected(): boolean {

    return this.dataSource.data.length > 0 && this.dataSource.data.every(item => item[this.CHECKBOX_PROP_NAME]);
  }

  isIndeterminate(): boolean {

    return this.dataSource.data.some(item => item[this.CHECKBOX_PROP_NAME]) && !this.isAllSelected();
  }
  
  onToggleAll(event): void {

    if(event.checked) {

      this._selections.clear();
      this.dataSource.data.forEach(
        (item,index) => {

          item[this.CHECKBOX_PROP_NAME] = true;
          this._selections.set(index, item);
        }
      );
      this._selectedItems = this.dataSource.data.length;
    }
    else{

      this.resetSelections();
    }
  }
}
