import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-expansive-row',
  templateUrl: 'expansive-row.component.html',
  styleUrls: ['expansive-row.component.scss']
})
export class ExpansiveRowComponent implements OnInit {
  @ContentChildren(MatCheckbox, { descendants: true }) checkboxes: QueryList<MatCheckbox>;
  @Input() expandable = true;
  expand = false;

  ngOnInit(): void {
  }

  expandFunction(): void {
    this.expand = !this.expand;
  }
}
