import { DtoDataType } from '../../shared/models/Definition/DtoDataType';
import { FormEditReservedProps } from '../../shared/enums/formEdit-reserved-props.enum';

export function transformToRaw<T extends any>(dataValues: T): T {
  const clone = JSON.parse(JSON.stringify(dataValues, (key, value) => {
    if (value === undefined) return null;
    return value;
  }));
  Object.keys(clone).forEach(key => {
    const value = clone[key];
    if (Array.isArray(value)) {
      !value.length ? clone[key] = null : clone[key] = value.map(selection => selection.key);
    }
  });
  return clone;
}

export function completeModelWithDatatype<T, D extends DtoDataType>(datatype: D, model: T) {
  if (!datatype || (!!datatype && !Object.keys(datatype).length)) {
    throw new Error('El tipo de dato no debe estar vacio para acompletar el modelo.');
  }
  Object.keys(datatype.components).forEach(key => {
    // Se pone asi la validacion si no afecta a los datos booleanos false
    if (model[key] === undefined) model[key] = null;
  });
}

export function transformDataSource(dataSource: Array<any>): Array<any> {
  const source = JSON.parse(JSON.stringify(dataSource));
  source.forEach(item => {
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key])) {
        item[key] = item[key].map(arrItem => arrItem.value);
      } else if (key === FormEditReservedProps[FormEditReservedProps.isSelected]) item['select'] = !!item[key];
    });
  });
  return source;
}
