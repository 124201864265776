import { DtoFiltrosBase } from './DtoFiltroBase';

export class DtoAdvancedSearch {
  agentIds = new Array<string>();
  isFavorite = true;
  filterDesign: Array<AdvancedSearchBaseFilter>;

  constructor(public name: string, filters: Array<AdvancedSearchBaseFilter>, public periods: AdvancedSearchPeriod) {
    this.filterDesign = filters;
  }
}

export interface AdvancedSearchPeriod {
  typeOfPeriod?: AdvancedSearchPeriodsEnum;
  initial: string;
  end: string;
}

export enum AdvancedSearchPeriodsEnum {
  LAST10,
  ACTUAL_MONTH,
  ACTUAL_WEEK,
  ACTUAL_DAY,
  RANGE
}

export interface AdvancedSearchBaseFilter {
  fieldName: string;
  label: string;
  selected: boolean;
  customStatus: Array<DtoFiltrosBase>;
  status: Array<DtoFiltrosBase>;
  value: any;
  groupValues: Array<DtoFiltrosBase>;
}
