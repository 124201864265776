import { Component, Input } from '@angular/core';
import { LoaderType } from '../loaderType.enum';
import { CommonLoaderDirective } from '../CustomLoader.class';

@Component({
  selector: 'app-non-blocking-loader',
  styleUrls: ['non-blocking-loader.component.scss'],
  template: `
    <div class="loader-container" *ngIf="loaderState$ | async">
      <section>
        <mat-progress-spinner [diameter]="55" class="loader-spinner" mode="indeterminate">
        </mat-progress-spinner>
        <span class="loader-span"></span>
      </section>
    </div>
  `
})

export class NonBlockingLoaderComponent extends CommonLoaderDirective {
  @Input() set name(name: string) {
    super.selector(name);
  }
}

export interface LoaderState {
  state: boolean;
  message?: string;
  type: LoaderType;
}
