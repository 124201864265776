import { Component, Input, ViewChild, AfterContentInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { DynamicTemplateDirective } from 'src/app/shared/directives/dynamic-tab';
import { FormElement } from '../../enums';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent implements AfterContentInit {

  @ViewChild(DynamicTemplateDirective) dynamicTabPlaceholder: DynamicTemplateDirective;

  @Input() template: TemplateRef<any>;
  @Input() title: string;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();
  isHide: boolean;

  constructor() { }

  /*
  Se asigna a la variable de template, la referencia al template genérico que contiene el componente a mostrar en el tab
   */
  ngAfterContentInit() {
    this.template = this.dynamicTabPlaceholder.templateRef;
  }

  hideTab(formElement: FormElement) {
    const hide = formElement !== FormElement.AGENT_SELECTION;
    this.isHide = hide;
    this.hide.emit(hide);
  }
}
