import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CATALOGS_SIDEBAR_VIEWS } from '../enums/catalogs-sidebar-views.enum';

@Injectable({
  providedIn: 'root'
})
export class CatalogsSidebarService {
  // Defaults
  open = new BehaviorSubject(false);
  view = new BehaviorSubject(CATALOGS_SIDEBAR_VIEWS.HELPER);
  history = new BehaviorSubject<() => Observable<any>>(undefined);
}
