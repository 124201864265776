import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { GenericRow } from './folder-finder.utils';

@Component({
  selector: 'app-folder-finder',
  template: `
    <h4 *ngIf="title">{{title}}</h4>
    <mat-form-field appearance="outline" class="toggle-element" color="primary" floatLabel="always"
                    style="width: 100%;">
      <mat-icon matPrefix>search</mat-icon>
      <input [formControl]="searchFolderByName" matInput>
    </mat-form-field>
    <div folder-container-list>
      <ng-container *ngIf="genericRowsArray && genericRowsArray.length; else noResults">
        <ng-container *ngFor="let row of genericRowsArray">
          <scc-row-base
            (rowClicked)="rowClicked.emit(row.unMappedElement)"
            (rowSelected)="rowSelected(row)"
            (rowOptionSelected)="rowOptionSelected(row)"
            [row]="row"
            [hasRadio]="hasRadio">
          </scc-row-base>
        </ng-container>
      </ng-container>
      <ng-template #noResults>
        <div style="text-align: center">
          <small>No se encontrarón resultados.</small>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['folder-finder.component.scss']
})
export class FolderFinderComponent implements OnInit, OnDestroy {

  searchFolderByName = new FormControl();
  @Input() genericRowsArray: Array<GenericRow>;
  @Input() title: string;
  @Input() hasRadio = false;
  @Output() rowOutput = new EventEmitter<any>();
  @Output() rowOptionOutput = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  private stopSubscriptions = false;

  ngOnInit(): void {
    this.startListening();
  }

  ngOnDestroy(): void {
    this.stopSubscriptions = true;
  }

  rowSelected(selectedRow: GenericRow): void {
    this.genericRowsArray.forEach(row => {
      row.isChecked = row.id === selectedRow.id;
    });
    this.rowOutput.emit(selectedRow.unMappedElement);
  }

  rowOptionSelected(selectedRow: GenericRow): void {
    this.rowOptionOutput.emit(selectedRow.unMappedElement);
  }

  private startListening(): void {
    this.searchFolderByName.valueChanges.pipe(
      takeWhile(() => !this.stopSubscriptions),
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(searchText => this.search.emit(searchText));
  }

}
