import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { IBrowserColumn } from './browser-column';
import { IBrowserItem } from './browser-item';
import { IBrowserColumnItem } from './browser-column-item';
import { IBrowserSelection } from './selection';
import { DOCUMENT } from '@angular/common';
import { asap } from 'rxjs/internal/scheduler/asap';

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements OnInit {

  @Input() showSearch = false;
  @Input() titles: Array<string> = [];
  @Input() ribbon = false;
  @Input() selection: IBrowserSelection = {
    id: null
  };
  @Output() _onSelect = new EventEmitter<string>();
  _items: Array<IBrowserItem> = [];
  nodes = [];
  columns: Array<IBrowserColumn> = [];

  constructor(@Inject(DOCUMENT) protected _document: Document) {
  }

  ngOnInit() {
    asap.schedule(() => {
      const columnChildActive = document.querySelectorAll('.column > .active')[0];
      if (columnChildActive && columnChildActive.parentElement.scrollHeight > columnChildActive.parentElement.clientHeight) {
        columnChildActive.scrollIntoView(true);
      }
    }, 500);
  }

  @Input('items') set items(value: Array<IBrowserItem>) {
    this._items = value;
    this.columns = [];
    this.buildFirstColumn();
    if (this.selection.id != null) {
      this.locate(this.selection.id);
      this.selection.id = null;
    }
  }

  protected buildFirstColumn() {
    if (this._items.length !== 0) {
      const items: Array<IBrowserColumnItem> = [];
      this._items.forEach(item => {
        if (item.root) {
          items.push(this.buildBrowserColumnItem(item));
        }
      });
      this.columns.push({
        title: this.title(0),
        items: items,
        selected: true
      });
    }
  }

  title(pos: number): string {
    return this.titles.length >= 1 ? this.titles[pos] : '';
  }

  protected findBrowserItem(id: string): IBrowserItem {
    return this._items.find(item => {
      return item.id === id;
    });
  }

  protected buildBrowserColumn(bci: IBrowserColumnItem): IBrowserColumn {
    const column: IBrowserColumn = {
      title: '',
      items: this.buildBrowserColumnItems(bci),
      selected: false
    };
    return column;
  }

  public locate(id: string) {
    if (!id) {
      return;
    }
    this.selection.id = id;
    if (this._items.length >= 1) {
      let columns: Array<IBrowserColumn> = [];
      let bi: IBrowserItem = this.findBrowserItem(id);
      const isParent = !bi.parent;
      let bci: IBrowserColumnItem = this.buildBrowserColumnItem(bi);
      const bcii = bci;

      do {
        const idp = bi.parent || bi.id;
        bi = this.findBrowserItem(idp);
        bci = this.buildBrowserColumnItem(bi);
        bci.active = bci.id === id;
        if (bci.childrens.length) {
          columns.push(this.buildBrowserColumn(bci));
        }
        if (!bi.parent) {
          bi = this.findBrowserItem(bi.id);
          bci = this.buildBrowserColumnItem(bi);
          columns.push(this.buildBrowserRootColumn(bci));
        }
      } while (bi.parent !== undefined && bi.parent !== null);

      columns = columns.reverse();
      let t = 0;
      columns.forEach(col => {
        col.title = this.title(t);
        t++;
      });
      this.columns = columns;
      // this.decoration(bcit);

      this.prepare(bcii, isParent);
    }
  }

  public delete(id: string): boolean {
    const item = this._items.find(i => {
      return i.id === id;
    });
    if (item !== undefined) {
      this._items.splice(this._items.indexOf(item), 1);
      if (item.parent !== undefined) {
        this.locate(item.parent);
      }
      return true;
    }
    return false;
  }

  buildBrowserRootColumn(bci: IBrowserColumnItem): IBrowserColumn {
    const column: IBrowserColumn = {
      title: '',
      items: this.buildBrowserColumnRootItems(bci),
      selected: false
    };
    return column;
  }

  buildBrowserColumnRootItems(bci: IBrowserColumnItem): Array<IBrowserColumnItem> {
    const items: Array<IBrowserColumnItem> = [];
    this._items.forEach(item => {
      if (item.root) {
        items.push(this.buildBrowserColumnItem(item));
      }
    });
    return items;
  }

  protected buildBrowserColumnItem(item: IBrowserItem): IBrowserColumnItem {
    return {
      id: item.id,
      icon: item.icon,
      title: item.title,
      description: item.description,
      actions: item.actions,
      childrens: item.childrens,
      channels: item.channels,
      parent: item.parent,
      selected: false,
      active: false
    };
  }

  public addItem(id: string, item: IBrowserItem) {
    this._items.push(item);
    if (this.columns.length >= 1) {
      for (let c = 0; c <= this.columns.length - 1; c++) {
        this.columns[c].items.forEach(i => {
          if (i.id === id) {
            i.childrens.push(item.id);
            if (i.childrens.length > 1) {
              this.columns[c + 1].items.push({
                id: item.id,
                icon: item.icon,
                title: item.title,
                description: item.description,
                actions: item.actions,
                childrens: item.childrens,
                parent: item.id,
                channels: item.channels,
                selected: false,
                active: false
              });
            } else {
              this.columns.push({
                items: [{
                  id: item.id,
                  icon: item.icon,
                  title: item.title,
                  description: item.description,
                  actions: item.actions,
                  childrens: item.childrens,
                  parent: item.id,
                  channels: item.channels,
                  selected: false,
                  active: false
                }],
                selected: false,
                title: this.title(c + 1)
              });
            }
          }
        });
      }
    } else {
      this.columns.push({
        items: [{
          id: item.id,
          icon: item.icon,
          title: item.title,
          description: item.description,
          actions: item.actions,
          childrens: item.childrens,
          parent: item.id,
          channels: item.channels,
          selected: false,
          active: false
        }],
        selected: false,
        title: this.title(0)
      });
    }
  }


  prepare(item: IBrowserColumnItem, isParent = false) {
    let c = 0;
    this._onSelect.emit(item.id);
    this.columns.forEach(col => {
      c++;
      if (col.items.indexOf(item) >= 0) {
        this.columns.length = c;
        return;
      }
    });
    if (item.childrens.length > 0 && !isParent) {
      this.columns.push({
        title: this.title(this.columns.length),
        items: this.buildBrowserColumnItems(item),
        selected: true
      });
    }
    item.active = true;
    this.decoration(item);
  }

  protected buildBrowserColumnItems(item: IBrowserColumnItem): Array<IBrowserColumnItem> {
    const items: Array<IBrowserColumnItem> = [];
    item.childrens.forEach(id => {
      this._items.forEach(i => {
        if (i.id === id) {
          items.push({
            id: i.id,
            icon: i.icon,
            title: i.title,
            description: i.description,
            actions: i.actions,
            childrens: i.childrens,
            parent: item.id,
            channels: i.channels,
            selected: false,
            active: false
          });
        }
      });
    });
    return items;
  }

  protected decoration(item: IBrowserColumnItem) {
    for (let i = this.columns.length - 1; i >= 0; i--) {
      const element = this.columns[i];
      element.items.forEach(e => {
        e.active = item.id === e.id;
        e.selected = item.parent === e.id;
        if (item.parent === e.id) {
          item = e;
        }
      });
    }
  }
}
