import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { BrowserService } from '../../../../shared/services/browser.service';
import { FeatureFlagEnum } from '../../../../../app/shared/enums/featureFlag.enum';

const AGENT_LABELS_KEY = 'agentlabels';
const HIERARCHY_LOADER_TAG = 'hierarchy_modal_loader';

@Injectable({
  providedIn: 'root'
})

export class FolderTreeService extends BrowserService {
  baseUrl = `${ window['__env'].APIS.ORIGINATIONS }/Hierarchy`;
  folders = new Map();
  private baseUrlTenantsApi = `${this._env.APIS.TENANTS}/organizations/${this._env.APIS.TENANTID}`;

  /*
  * This method is used to get the current agent hierarchy
  * 
  * @param {string} agentId - The agent id
  * @param {boolean} detail - The flag to get the agent/folder detail (Total tasks and instances)
  */
  getByAgentId(agentId: String, detail: Boolean = false): Observable<any> {

    let request = null;

    if (this.featureFlagService.getFlagValue(FeatureFlagEnum.AGENT_FOLDER_INFO_API)) {

      request = super.get(`/agents/${agentId}:folderInfo`, { 
        baseUrl: this.baseUrlTenantsApi, 
        loaderName: HIERARCHY_LOADER_TAG,
      }, {detail}).pipe(
        map(response => response.folders.map(folder => ({

          ...folder,
          agent: response.agentData,
          instancesNumber: folder.totalInstance,
          tasksNumber: folder.totalTask,
        })) || [])
      );
    }
    else {

      request = super.post('nodes/searchByAgentId', { agentId: agentId }, { loaderName: HIERARCHY_LOADER_TAG }).pipe(
        map((response) => response.value || [])
      );
    }

    return request.pipe(
      tap(
        (response)=>{

          // get agent labels and save in storage
          if(response && Array.isArray(response) && response.length > 0){

            const firstFolder = response[0];

            const labels = firstFolder.agent.labels;
            
            localStorage.setItem(AGENT_LABELS_KEY, JSON.stringify(labels));
          }
        }
      )
    );
  }

  post(name: string): Observable<any> {
    return super.post(`Root`, { name: name });
  }

  get(id = null): Observable<any> {
    if (id) {
      this.catalogsSidebar.history
        .next(super.getLog(id, ['NodeUpdated']));
    }
    return !id ? super.get('', { loader: true }) : super.post(`nodes/searchById`, { NodeId: id }, { loader: true });
  }

  postFolder(parentId: string, { name, description, groupType, NoFolderToCreate }): Observable<any> {
    return super.post(`nodes/${ parentId }/childNode`, {
      groupType,
      name,
      description,
      NoFolderToCreate
    }, { loader: true, loaderName: 'newFolder' });
  }

  updateFolder(parentId: string, { name, description, tags, agentId }): Observable<any> {

    let observableRequest: Observable<any> = null;
    const parallelUpdate = this.featureFlagService.getFlagValue(FeatureFlagEnum.FOLDER_ALLOW_PARALLEL_UPDATE);
    const folderData = {
      agentId: agentId || undefined,
      name,
      description,
      tags,
    };

    if (parallelUpdate) {

      observableRequest = super.put(`hierarchies/${parentId}`, folderData, { baseUrl: this.baseUrlTenantsApi }).pipe(
        // Need simulate the response from the old endpoint when the parallel update is success (status = 0) for next validations
        map(() => ({ status: 0 }))
      );
    }
    else {

      observableRequest = super.put(`nodes/${parentId}/childNode`, folderData, { loader: true });
    }

    return observableRequest;
  }

  delete(parentId: string): Observable<any> {
    return super.delete(`nodes/${ parentId }/childNode`, { loader: true });
  }

  searchByName(query: string): Observable<any> {
    return super.post(`nodes/searchByName`, { name: query }, { loader: true });
  }

  setMapHierarchy(obj, parent = null, index = 0) {
    const _parent = parent ? Array.from(this.browserMap.get(parent).parent) : [];
    const parents = _parent;
    if (parent) {
      parents.push(parent);
    }

    let children;
    if (obj.groups) {
      children = obj.groups.map(el => el.id);
    }


    this.browserMap.set(
      obj.id,
      {
        id: obj.id,
        parent: parents,
        name: obj.name,
        description: obj.description,
        groupType: obj.groupType,
        agent: obj.agent,
        children: children,
        status: obj.status,
        statusEvent: 0,
        index: index
      }
    );
    if (obj.groups) {
      index++;
      obj.groups.forEach(folder => {
        this.setMapHierarchy(folder, obj.id, index);
      });
    }
  }

  setCol(id) {
    const grid = [];
    const folder = this.browserMap.get(id);
    if (folder.parent.length) {
      grid.push([folder.parent[0]]);
      folder.parent.forEach(parentId => {
        grid.push(Array.from(this.browserMap.get(parentId).children));
      });
      if (this.browserMap.get(id).children !== undefined) {
        grid.push(Array.from(this.browserMap.get(id).children));
      }
    } else {
      grid.push(Array.from([id]));
      grid.push(Array.from(this.browserMap.get(id).children));
    }
    this.browserGrid$.next(grid);
    this.setStatus(id);
    this.selectedItemId$.next(id);

  }

  setStatus(id) {
    const folder = this.browserMap.get(id);
    if (folder.children !== undefined) {
      folder.children.forEach(childId => {
        this.browserMap.get(childId).statusEvent = 0;
      });
    }
    if (folder.parent.length) {
      this.browserMap.get(folder.parent[folder.parent.length - 1]).children.forEach(childId => {
        this.browserMap.get(childId).statusEvent = 0;
      });
    }
    folder.statusEvent = 1;
  }
}
