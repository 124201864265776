import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DateHelperService {

  // If input is an string, convert to moment before
  momentToTimestamp(date: any, endOfDay = false) {

    let result = null;
    if(typeof date === 'string'){

      date = moment(date);
    } 
    
    if (endOfDay) {
      
      result = date.endOf('day').valueOf();
    }
    else {

      result = date.startOf('day').valueOf();
    }

    return result;
  }

  stringToTimestamp(input: any, endOfDay = false){  

    let result = null;
    const date = moment(input); 
    if (endOfDay) {
      
      result = date.endOf('day').valueOf();
    }
    else {

      result = date.startOf('day').valueOf();
    }

    return result;
  }

  /**
   * Determine if the date is today or tomorrow and return the appropriate string
   * @param input timestapm date
   * @returns 
   */
  dayAgo(input: any){  

    let result = '';
    const date = moment(input).startOf('day');;
    const today = moment().startOf('day'); 
    const dif = today.diff(date, 'days');

    switch(dif){

      case 0:

        result = 'Hoy';
        break;
      case 1:

        result = 'Ayer';
        break;
      default:

        result = date.format('DD/MM/YYYY');
        break;
    }

    return result;
  }
}
