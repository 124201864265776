import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-browser-item-result',
  templateUrl: './product-browser-item-result.component.html',
  styleUrls: ['./product-browser-item-result.component.scss']
})
export class ProductBrowserItemResultComponent implements OnInit {
  @Input() itemResult: any;

  constructor() { }

  ngOnInit() {
  }

}
