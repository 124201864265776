import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { filter, map, switchMap } from 'rxjs/operators';
import { LoaderService } from '../components/loader/loader.service';
import { ProfileService } from '../services/profile.service';
import { StoragesService } from '../services/storages.service';
import { asap } from 'rxjs/internal/scheduler/asap';
import { Observable } from 'rxjs';
import { LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouteHistory2Service<T extends RouteHistory = RouteHistory> {
  private _home = { url: '/' } as T;
  private _log = new Array<T>(this._home);

  constructor(private readonly _engine: Router, private readonly _localStorage: LocalStorage, private readonly _loader: LoaderService,
              private readonly _profile: ProfileService, private readonly _storages: StoragesService,
              private readonly _active: ActivatedRoute, private readonly _location: LocationStrategy,) {
    this.getPrevHistoryState();
  }

  /**
   * Guarda el estado de la aplicación en la acción de refresco de la pagina.
   */
  saveHistoryState(): void {
    this._storages.setNavegationStorage(JSON.stringify(this._log)).subscribe();
  }

  /**
   * Recupera y establece el estado de la aplicación.
   */
  private getPrevHistoryState(): void {
    asap.schedule(() => {
      this._profile.userProfile$.pipe(
        filter(info => !!info),
        switchMap(() => this.getNavigation())
      ).subscribe(state => {
        this._log = state;
        this.restoreUrlsTo(this._engine.url);
        this.start();
      });
    });
  }

  /**
   * Actualiza el historia a la ruta especificada.
   * @param route Ruta.
   */
  private restoreUrlsTo(route: string): void {
    for (let i = 0; i < this._log.length; i++) {
      const url = this._log[i].url;
      if (url === route) {
        this._log = this._log.splice(0, i + 1);
        break;
      }
    }
  }

  /**
   * Obtiene el observable de la navegación del sistema.
   */
  private getNavigation(): Observable<Array<T>> {
    return this._storages.getNavegationStorage<Array<T>>().pipe(
      filter(state => {
        const resVal = Array.isArray(state) && !state.some(s => typeof s === 'string');
        if (!resVal) {
          this.start();
        }
        return resVal;
      }),
      switchMap(state => this._storages.removeNavigationStorage().pipe(map(() => state)))
    );
  }

  /**
   * Redirecciona el estado de la aplicación a la ruta recibida.
   * @param route Ruta.
   */
  navigateTo(route: T): void {
    this.addRoute(route);
    this._engine.navigateByUrl(route.url);
  }

  /**
   * Regresa el estado de la aplicación a la URL anterior a la actual.
   */
  back(): void {
    let beforeTheLast: RouteHistory;
    for (let i = this._log.length - 2; i > -1; i--) {
      beforeTheLast = this._log[i];
      if (beforeTheLast.ignore) {
        continue;
      }
      break;
    }
    this._location.back();
  }

  /**
   * Remove the last route added
   */
  removeLastRoute(): void {
    this._log.pop();
  }

  /**
   * Se subscribe al evento de cambio de URL para realizar los procesos correspondientes al control de estado de la aplicación.
   */
  private start(): void {
    this._engine.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const route = { url: event.url, ignore: false } as T;
        this.processRoute(route);
        this._loader.show();
      } else if (event instanceof NavigationEnd) {
        this._loader.hide();
      }
    });
  }

  /**
   * Procesa la ruta recibida para guardarla o actualizar las rutas.
   * @param route Ruta.
   */
  private processRoute(route: T): void {
    if (this.isRouteLogged(route)) {
      this.updateRoutes(route);
    } else {
      this.addRoute(route);
    }
  }

  /**
   * Agrega la ruta al log de rutas.
   * @param route Ruta.
   */
  private addRoute(route: T): void {
    this._log.push(route);
  }

  /**
   * Actualiza las rutas registradas en el log con respecto a la ruta actual.
   * @param route Ruta.
   */
  private updateRoutes(route: T): void {
    const routePosition = this._log.findIndex((r: T) => r.url === route.url);
    this._log = this._log.slice(0, routePosition + 1);
  }

  /**
   * Verifica si la ruta ya existe en el log.
   * @param route Ruta.
   */
  private isRouteLogged = (route: T) => this._log.some((r: T) => r.url === route.url);
}


export interface RouteHistory {
  url: string;
  ignore: boolean;
}
