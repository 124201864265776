export class ReducedContainer {
  instanceId: string;
  containerId: string;
  isHiddenClose: boolean;
  values: ContainerValues;
  customStatusContainer: number;
  statusContainer: number;
  tenantId: string;
  status: number;
}

export class ContainerValues {
  containerId: string;
  title: string;
  status: number;
  customStatus: number;
  isHiddenClose: boolean;
  data: any;
  dataFront: string;
  customStatusInfo: any;
}
