import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-chips-v2',
  templateUrl: './custom-chips-v2.component.html',
  styleUrls: ['./custom-chips-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomChipsV2Component),
      multi: true
    }
  ]
})
export class CustomChipsV2Component implements ControlValueAccessor {
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() placeholder: string;
  @Input() allowRepeat = false;
  @Input() onlyDataSource = false;

  @Input() set autocompleteSource(source: Array<any>) {
    this._setSource(source);
  }

  @Input() textNames: {[key: string]: number} = {}; //A keys values with customName for each value of datasource

  get autocompleteSource() {
    return this._autocompleteSource;
  }

  @Output() addItemCallback = new EventEmitter<Function>();

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public dataSelected = [];
  public filteredColumns$: Observable<string[]>;
  public columnsCtrl = new FormControl(null);

  private _autocompleteSource = new Array<any>();
  private _sourceBackup = new Array<any>();
  private _propagateChange = (_: any) => {
  };

  constructor() {
  }

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    if (obj && Array.isArray(obj) && obj.length > 0) {
      this.dataSelected = [...this.dataSelected, ...obj];
    } else {
      this.dataSelected = [];
    }
  }

  /**
   * Adds the item written by the user.
   * @param event Event
   */
  public add(event: MatChipInputEvent): void {

    // only can add items from the autocomplete if onlyDataSource is true
    if (!this.onlyDataSource) {

      const input = event.input;
      const value = (event.value || '').trim();

      if (value) {

        this.dataSelected.push(value);
        this._propagateChange(Array.from(this.dataSelected));
      }

      if (input) {

        input.value = '';
      }

      this.columnsCtrl.setValue(null);
    }
  }

  /**
   * Removes the column selected.
   * @param column Column selected.
   */
  public remove(column): void {
    const deleted = this.dataSelected.splice(this.dataSelected.findIndex(c => c === column), 1);
    this._propagateChange(Array.from(this.dataSelected));
    this._setSource([...this.autocompleteSource, ...deleted]);
  }

  /**
   * Selects, saves and emits the column.
   * @param event Event from Autocomplete
   */
  public selected(event: MatAutocompleteSelectedEvent): void {

    const selection = event.option.value;
    if (!this.allowRepeat && this._ifExist(selection, this.dataSelected)) return;
    this.dataSelected.push(selection);
    this._propagateChange(Array.from(this.dataSelected));
    this._autocompleteSource = this._autocompleteSource.filter(item => item !== selection);
    this.userInput.nativeElement.value = '';
    this.columnsCtrl.setValue(null);
  }

  /**
   * Adds an extra item to the source.
   * @param item Item to add
   */
  public addFromOutside(item: string): void {
    if (!this._ifExist(item, this.autocompleteSource)) {
      this.autocompleteSource.push(item);
      this._setSource(this.autocompleteSource);
    }
    if (!this._ifExist(item, this._sourceBackup)) {
      this._sourceBackup.push(item);
    }
  }

  /**
   * Looks for the value matches in the autocomplete source.
   * @param value Value to search
   * @private
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.autocompleteSource.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Sets the source in the autocomplete angular material control
   * @param source Source
   * @private
   */
  private _setSource(source: Array<any>): void {
    if (Array.isArray(source)) {
      this._autocompleteSource = source;
      this._sourceBackup = Array.from(source);
      this.filteredColumns$ = this.columnsCtrl.valueChanges.pipe(
        startWith(null),
        map((data: string | null) => data ? this._filter(data) : this.autocompleteSource.slice())
      );
    }
  }

  /**
   * Validates if an item exist in the source.
   * @param item Item to validate
   * @param source Source to check
   * @private
   */
  private _ifExist(item: string, source: any[]): boolean {
    return source.findIndex(i => i === item) > -1;
  }

  public textByKey(key:string){

    if (this.textNames.hasOwnProperty(key)) {
      return this.textNames[key];
    }
    return key;
  }
}
