import { Pipe, PipeTransform } from '@angular/core';
import { APP_SETTINGS } from 'src/app/configs/app-settings.config';

@Pipe({
  name: 'noImage'
})
export class NoImagePipe implements PipeTransform {
  transform(src: any): string {
    let result = src;
    if (!src || src === '') {
      result = APP_SETTINGS.IMAGES.NO_IMAGE;
    }
    return result;
  }
}
