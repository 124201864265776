import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'scc-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  typeEnum = NotificationType;

  constructor(private snackBarRef: MatSnackBarRef<NotificationsComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: NotificationsComponentData) {
  }

  ngOnInit() {
  }

  closeButton() {
    this.snackBarRef.dismiss();
  }
}

export interface NotificationsComponentData {
  message: string;
  isI18nMessage: boolean;
  messageType: NotificationType;
}

export enum NotificationType {
  INFO = 0,
  SUCCESS = 1
}
