import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'feature-flag',
  template: `
    <ng-content *ngIf="show == true" select="[flagEnabled]"></ng-content>
    <ng-content *ngIf="show == false" select="[flagDisabled]"></ng-content>
  `
})
export class FeatureFlagComponent implements OnInit, OnDestroy {

  // name of the flag to check
  @Input() flag: string;
  // internal flag to show/hide the content
  show: boolean = null;
  
  constructor(private featureFlag: FeatureFlagService) { }
  
  ngOnInit() {

    this.featureFlag.getFlagObservable(this.flag).subscribe((flagValue) => {

      this.show = flagValue;
    });
  }

  ngOnDestroy() {

    this.featureFlag.unSubscribeFlagObservable(this.flag);
  }
}