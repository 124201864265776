export class SubsidiaryProduct {
  //#region Required properties JSON
  id: string;
  title: string;
  //#endregion
}

export class SubsidiaryProductFolder {
  id: string;
  name: string;
  agents: number;
}

export class AgentsByFolder {
  id: string;
   agents: any[];
}
