import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DtoProductList } from '../../models/DtoProductList';
import { ProductRemoveInfo } from '../../models/ModalContracts/ProductRemoveInfo';
import { ProductsService } from '../../../modules/products/shared/products.service';
import { AlertService } from '../../services/alerts.service';
import { AuthService } from 'src/app/core/services/auth-service.service';

@Component({
  selector: 'app-custom-product-info-modal',
  templateUrl: 'custom-product-info-modal.component.html',
  styleUrls: ['custom-product-info-modal.component.scss']
})

export class CustomProductInfoModalComponent implements OnInit {
  selectedProduct: DtoProductList;
  onlyViewer = false;

  constructor(
              @Inject(MAT_DIALOG_DATA) data: ProductRemoveInfo, 
              public _dialog: MatDialogRef<any>,
              private productService: ProductsService, 
              private _alert: AlertService, 
              private _auth: AuthService) {

    this.selectedProduct = data.product;
    this.onlyViewer = data.onlyViewer;
  }

  ngOnInit() {
  }

  removeProduct(): void {
    
    const data = {
      parameters: {
        '@@TenantId': window['__env'].APIS.TENANTID,
        '@@AgentId': this._auth.userInfo.uid
      }
    };
    this.productService.remove(this.selectedProduct.id, data).subscribe(res => {
      this.handleResponse(res);
    });
  }

  private handleResponse(result): void {
    switch (result.status) {
      case 0: {
        this._alert.success('Producto retirado correctamente', true);
        this._dialog.close(true);
        break;
      }
      case 3: {
        this._alert.error('Error al retirar el producto', false);
      }
    }
  }
}
