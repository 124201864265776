import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export class CustomModal<T extends ICustomModal> implements ICustomModal {
  title: string;
  message: string;
  constructor(@Inject(MAT_DIALOG_DATA) protected data: T, protected _dialog: MatDialogRef<any>) {
    this.title = data.title;
    this.message = data.message;
  }
}

export interface ICustomModal {
  title: string;
  message: string;
}
