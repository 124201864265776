import { Component, ContentChildren, QueryList, AfterContentInit, Output, EventEmitter, ViewChild, OnInit, Input } from '@angular/core';
import { TabContentComponent } from '../tab-content/tab-content.component';
import { MatTabGroup } from '@angular/material';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements AfterContentInit, OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @Input() selectedTabIndex: number;
  dynamicTabs: TabContentComponent[] = [];

  @ContentChildren(TabContentComponent) tabs: QueryList<TabContentComponent>;
  @Output() tabChange = new EventEmitter<any>();

  constructor() { }

  /*
  Obtenemos todas la referencias de los componentes TabContent y se itera sobre ellas para
  crear los MatTab dinamicamente
  */
  ngAfterContentInit() {
    this.tabs.toArray().forEach(tab => {
      Array<TabContentComponent>();
      this.dynamicTabs.push(tab as TabContentComponent);
      tab.hide.subscribe(childTab => this.hideTab(childTab));
    });
  }

  /*
  Oculta las tabs con el atriburo de ocultar
  */
  hideTab(showTab: any) {
    if (this.tabs) {
      this.tabs.toArray().forEach((tab, index) => {
        Array<TabContentComponent>();
        this.dynamicTabs[index].isHide = tab.isHide;
      });
    }
  }

  getTabs() {
    return this.dynamicTabs.filter(dynamicTab => !dynamicTab.isHide);
  }

  ngOnInit(): void {
    if (this.selectedTabIndex) this.tabGroup.selectedIndex = this.selectedTabIndex;
  }
}
