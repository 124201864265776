import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SearchModalComponent, SearchModalConfig } from './search-modal/search-modal.component';
import { EngineTable } from '../custom-table-v2/table-config.interface';

@Component({
  selector: 'app-administration-navbar-v2',
  template: `
    <div [ngStyle]="{ backgroundColor: bg }" [ngClass]="{ 'border-style': border }">
      <section>
        <ng-content></ng-content>
      </section>
      <section>
        <ng-container *ngIf="!hideIcons">
          <button *ngIf="allowSearch" mat-icon-button (click)="searchModal()" [matBadgeHidden]="isFiltered" matBadge="-"
                  matBadgeColor="primary">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button (click)="refresh.emit()" *ngIf="allowRefresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </ng-container>
      </section>
    </div>
  `,
  styleUrls: ['administration-navbar-v2.component.scss']
})

export class AdministrationNavbarV2Component {
  @Input() bg = 'transparent';
  @Input() hideIcons = false;
  @Input() allowSearch = false;
  @Input() allowRefresh = false;
  @Input() border = false;
  @Input() table: EngineTable;
  @Output() search = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<void>();
  @ViewChild('searchModalTemplate') private _searchModal: TemplateRef<any>;

  get isFiltered() {
    return this.table && !this.table.filter;
  }

  constructor(private readonly _dialog: MatDialog) {
  }

  /**
   * Función que abre la modal de busqueda.
   */
  searchModal(): void {
    this._dialog.open(SearchModalComponent, {
      width: '500px',
      data: {
        previousSearch: this.table && this.table.filter,
        searchCallback: (text: string) => {
          this.search.emit(text);
          this.table.filter = text;
        }
      } as SearchModalConfig
    });
  }
}
