export enum DataTransfer {
  QUESTION = 0,
  INSTANCE_ID = 1,
  URL = 2,
  PROFILE = 3,
  FROM_USER_ID = 4,
  CATALOG_PROFILE = 5,
  OPTION = 6,
  MEMBER_ID = 7,
  FOLDER_ID = 8,
  FOLDER_TREE = 9,
  FROM_TASK = 10
}
