export enum FormElement {
  TEXT_EDIT = 0,
  TEXT_AREA = 1,
  CHECKBOX_BOOLEAN = 2,
  RADIO_BOOLEAN = 3,
  RADIO_GROUP = 4,
  CHOICELIST = 5,
  CHECKBOX_GROUP = 6,
  IFRAME = 7,
  DATE_PICKER_INLINE = 8,
  TITLE = 9,
  IMAGE = 10,
  MAP = 11,
  CHOICELIST_GROUP = 12,
  HELPER = 13,
  FORM_EDIT = 14,
  ATTACHMENT = 15,
  PARTIAL_FUNCTION = 16,
  AGENT_SELECTION = 17
}

