import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'channel'
})
export class ChannelPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'web':
        return 'computer';
        break;
      case 'mobile':
        return 'smartphone';
        break;
    }
    return '';
  }

}
