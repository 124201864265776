/**
 * Clase base abstracta para el filtrado de catalogos que a su vez extiende la clase base abstracta de catalogos sin filtrado.
 */
import { BaseCatalog } from './BaseCatalog';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { NavbarSearchComponent } from '../../components/administration-navbar/navbar-search/navbar-search.component';
import { takeWhile } from 'rxjs/operators';

export abstract class BaseFilterCatalog<T> extends BaseCatalog<T> implements AfterViewInit, OnDestroy {
  @ViewChild(NavbarSearchComponent) searchComponent: NavbarSearchComponent;
  protected _stopSearch = false;

  ngAfterViewInit(): void {
    this.cleanSearch();
    this.searchComponent.search.pipe(takeWhile(() => !this._stopSearch))
      .subscribe(value => {
        this.tableComponent.filter = { data: value };
        if (!value) {
          this.catalogLoaded.emit();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._stopSearch = true;
  }

  cleanSearch(): void {
    this.searchComponent.close.pipe(takeWhile(() => !this._stopSearch))
      .subscribe(() => this.searchComponent.control.setValue(null));
  }
}
