import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { EnvService } from '../../core/services/env.service';
import { UserInfoService } from '../../core/services/user-info.service';
import { AlertService } from './alerts.service';
import { BehaviorSubject } from 'rxjs';
import { EngineNotification } from '../models/notification/notification.interface';

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  LOADING = 'loading',
  LINK = 'link', // This is calculated. It is not part of the notification types.
}

@Injectable()
export class NotificationsService {
  private readonly _socket;
  private readonly _notificationView$ = new BehaviorSubject(false);
  private readonly _notificationsList$ = new BehaviorSubject<EngineNotification[]>([]);
  //Added new BehaviorSubject to send 
  public readonly notificationReceived$ = new BehaviorSubject<EngineNotification>(null);
  

  public newPendingNotifications = false;

  public totalPendingNotifications = 0;

  get toggleNotification$() {
    return this._notificationView$.asObservable();
  }

  get notifications$() {
    return this._notificationsList$.asObservable();
  }

  get notificationsList() {
    return this._notificationsList$.getValue();
  }

  constructor(private readonly _environment: EnvService, private readonly _profile: UserInfoService,
              private readonly _alert: AlertService) {
    this._socket = io(`${ _environment.APIS.WEBSOCKET }/${ _environment.APIS.TENANTID }`, {
      transports: ['websocket'],
      withCredentials: true
    });
    this._socket.on('connect', () => this._initialize());
  }

  /**
   * Opens or close the notifications view.
   */
  public toggleNotificationView(): void {
    this._updateNotificationBadge();
    const state = this._notificationView$.getValue();
    this._notificationView$.next(!state);
  }

  /**
   * Requests new notifications.
   */
  public getAll(): void {
    this._socket.emit('getAllNotificationsByAgentId');
  }

  /**
   * Marks all notifications as read. This event emits internally the readNotification event that must be caught by _listenForRead function.
   */
  public markAllAsRead(): void {
    this._socket.emit('readNotifications');
    this._markAllAsReadInMemory();
  }

  /**
   * Marks a notification as read. This event emits internally the readNotification event that must be caught by _listenForRead function.
   * @param id Notification Id.
   */
  public markAsRead(id: string): void {
    this._socket.emit('readNotification', id);
    this._markAsReadInMemory(id);
  }

  /**
   * Each time user marks a notification or all as read, another users are going to catch that event.
   * @private
   */
  private _listenForRead(): void {
    this._socket.on('readNotification', (id: string) => {
      console.log('readNotification', id);
      this._markAsReadInMemory(id);
    });
    this._socket.on('readNotifications', data => {
      console.log('readNotifications', data);
      this._markAllAsReadInMemory();
    });
  }

  /**
   * Initializes all events needed to work with notifications.
   * @private
   */
  private _initialize(): void {
    this._socket.on('logged', data => {
      if (!data) this._alert.error('No se pudo conectar al sistema de notificaciones.');
    });
    this._login();
    this._listenNewNotifications();
    this._listenForRead();
    this._getNotificationsWhenExistNewOnes();
    this.getAll();
  }

  /**
   * Listens for coming notifications. They could be new or existing ones.
   * This listeners is invoked by getAll function.
   * @private
   */
  private _getNotificationsWhenExistNewOnes(): void {
    this._socket.on('getAllNotificationsByAgentId', (notifications: EngineNotification[]) => {

      this._notificationsList$.next(notifications.map(notification => this.applyLinkInfo(notification)));
      if (!this._isViewOpened()) this._updateNotificationBadge();
    });
  }

  /**
   * Logs in API with the actual agent id.
   * @private
   */
  private _login(): void {
    this._socket.emit('login', this._profile.userProfile$.getValue().id);
  }

  /**
   * Listens for new notifications.
   * @private
   */
  private _listenNewNotifications(): void {
    this._socket.on('newNotification', (data: EngineNotification) => {
      
      data = this.applyLinkInfo(data);
      if(this.previewOption && !data.isRead){ //Emit event notifications only if options enabled
        
        const existNotification = this.notificationsList.find(n => n.notificationId == data.notificationId);
        
        if(!existNotification || (existNotification.timestamp != data.timestamp)){

          //emit event with notification item
          this.notificationReceived$.next(data); 
        }
      }
      
      // This a temporary solution for duplicate triggers in backend.
      const foundIndex = this.notificationsList.findIndex(n => n.notificationId === data.notificationId);
      const notifications = [...this.notificationsList];
      if (foundIndex > -1) {
        notifications[foundIndex] = data;
      } else {
        notifications.unshift(data);
      }
      this._notificationsList$.next(notifications);
      this._updateNotificationBadge();
    });
  }

  /**
   * Checks if the notifications view is toggled.
   * @private
   */
  private _isViewOpened(): boolean {
    return this._notificationView$.getValue();
  }

  /**
   * Removes the notification matched with the notification id.
   * @param id Notification Id
   * @private
   */
  private _markAsReadInMemory(id: string): void {

    const notification = this.notificationsList.find(n => n.notificationId === id);
    if (notification) notification.isRead = true;
    this._notificationsList$.next([...this.notificationsList]);
    this._updateNotificationBadge();
  }

  /**
   * Marks all notification as read in memory.
   * @private
   */
  private _markAllAsReadInMemory(): void {
    this.notificationsList.forEach(n => n.isRead = true);
    this._notificationsList$.next([...this.notificationsList]);
    this._updateNotificationBadge();
  }

  private _updateNotificationBadge(): void {

    this.totalPendingNotifications = this.notificationsList.filter(n => !n.isRead).length;
    this.newPendingNotifications = this.totalPendingNotifications > 0 ;
  }

  /**
   * Update the option to show or not the alert with inbound notifications
  */
  set previewOption(value){

    this._profile.setLocalOption('showPreviewNotification',value);
  }

  /**
   * Get the option to show or not the alert with inbound notifications
  */
  get previewOption(){
    
    const option = this._profile.getLocalOption('showPreviewNotification');
    return option == undefined || option == 'true'; //option == undefined because the default value is true when option is not setted yet
  }

  applyLinkInfo(notification:EngineNotification ){

    if(notification.link){
      
      notification.linkCaption = 'Descargar adjunto';
      if(notification.link.startsWith(window.origin) || !notification.link.startsWith('http')){

        notification.linkCaption = 'Ver solicitud';
      }
    }
    return notification;
  }
}
