import { InjectionToken } from '@angular/core';

interface EngineLocales {
  sp: any;
}

const spanishLocale = {
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'hace unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    w: 'una semana',
    ww: '%d semanas',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años'
  }
};

const locales = {
  sp: spanishLocale
} as EngineLocales;

const MOMENT_LOCALES = new InjectionToken('locales');
const LocalesProvider = {
  provide: MOMENT_LOCALES,
  useValue: locales
};

export { LocalesProvider, MOMENT_LOCALES, EngineLocales };
