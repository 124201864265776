import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-custom-column',
  templateUrl: 'custom-column.component.html'
})

export class CustomColumnComponent implements OnInit {
  @Input() name: string;
  @Input() field: string;
  @Input() fullWidth: boolean;
  @Input() sort = false;
  @Input() brief = false;
  @Input() mask: Function;
  @Input() footerFn: Function;
  @Input() template: TemplateRef<any>;
  constructor() {
  }

  ngOnInit() {
    if (this.footerFn) this.footerFn();
  }
}
