import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HistoricalReportModel, InstanceHistoryReportService } from './instance-history-report.service';

const CONTAINERS_TYPES = [
  {
    value: null,
    text: 'Todos'
  },
  {
    value: 'PRODUCT',
    text: 'Producto'
  },
  {
    value: 'STAGE',
    text: 'Etapa'
  },
  {
    value: 'REQUIREMENT',
    text: 'Requisito'
  },
  {
    value: 'TASK',
    text: 'Tarea'
  }
];

const DEFAULT_CONTAINER = 'PRODUCT';

/**
 * Component to render the history report of an instance
 * Example of use:
 * <instance-history-report instanceId="id"></instance-history-report>
 */
@Component({
  selector: 'instance-history-report',
  templateUrl: './instance-history-report.component.html',
  styleUrls: ['./instance-history-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [InstanceHistoryReportService]
})
export class InstanceHistoryReportComponent implements OnInit, AfterViewInit {

  // #region Inputs

  @Input() instanceId: string = '';

  // #endregion

  // #region vars

  data: HistoricalReportModel = {
    headerValues: [],
    historicalReport: []
  };

  columns = ['containerName', 'previouStatus', 'finalStatus', 'dateExecution', 'userName', 'userMail'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  containerTypeSelected = DEFAULT_CONTAINER;
  containersTypes = CONTAINERS_TYPES;
  // #endregion

  constructor(public readonly _service: InstanceHistoryReportService) { }

  ngAfterViewInit() {

    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.containerType == filter;
    };
    
    this._service.getHistory(this.instanceId).subscribe(
      (result: HistoricalReportModel) => {

        this.data = result;
        this.dataSource.data = this.data.historicalReport;
        this.onFilterContainer();
      }
    );
  }

  ngOnInit(): void {

    
  }

  // #region Paginator

  get paginationInfo(): string {

    let currentPage = this.paginator.pageIndex + 1;
    let totalPages = this.paginator.getNumberOfPages();
    return `${currentPage < 10 ? `0${currentPage}` : currentPage} de ${totalPages < 10 ? `0${totalPages}` : totalPages}`;
  }
  // #endregion

  // #region HeaderValues

  get headerValues(): string {
    
    let result = '';
    if (this.data && this.data.headerValues && this.data.headerValues.length > 0) {

      result = this.data.headerValues.map((headerValue) => (`${headerValue.name} - ${headerValue.value}`)).join(' | ');
    }
    return result;
  }

  // #endregion

  // #region Filter containers

  onFilterContainer(): void {

    this.dataSource.filter = this.containerTypeSelected;
  }

  // #endregion
}