import { Notification } from '../models';
import { RedirectToAction } from 'src/app/shared/enums';

export const NOTIFICATIONS: Notification[] = [
  {
    id: '17705935-16e9-4879-b94f-b28a2c7a786d',
    title: 'Tareas pendientes',
    shortDescription: 'Tonya Sanders',
    longDescription: 'sum | reward | add',
    taskCounter: 85,
    taskDate: '2018-07-27T20:24:38.694Z',
    tags: ['clock', 'light', 'horse'],
    iconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/e/eb/Blue_check.svg',
    redirectToAction: RedirectToAction.ORIGINATION
  },
  {
    id: 'c733a68a-5897-4087-af48-8fc5bff6026a',
    title: 'Tareas pendientes',
    shortDescription: 'Trevor Lewis',
    longDescription: 'fight | live',
    taskCounter: 70,
    taskDate: '2018-07-26T20:24:38.694Z',
    tags: ['zoo'],
    iconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/e/eb/Blue_check.svg',
    redirectToAction: RedirectToAction.ORIGINATION
  },
  {
    id: 'f84361c7-609a-44c8-ac68-4261cc232cc4',
    title: 'Tareas pendientes',
    shortDescription: 'Verna Davenport',
    longDescription: 'enforce | round | select | close | back | drop | new',
    taskCounter: 19,
    taskDate: '2018-07-25T20:24:38.694Z',
    tags: ['rain', 'alligator', 'whale', 'brand', 'advertisement', 'dentist'],
    iconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/e/eb/Blue_check.svg',
    redirectToAction: RedirectToAction.ORIGINATION
  },
  {
    id: 'ed39754b-e859-4aae-829d-efb9df6c91af',
    title: 'Tareas pendientes',
    shortDescription: 'Antone Collier',
    longDescription: 'record',
    taskCounter: 16,
    taskDate: '2018-07-24T20:24:38.694Z',
    tags: [],
    iconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/e/eb/Blue_check.svg',
    redirectToAction: RedirectToAction.ORIGINATION
  },
  {
    id: 'a2865549-4dcc-4ed2-b7d7-fca1d127072e',
    title: 'Tareas pendientes',
    shortDescription: 'Zelma Oneal',
    longDescription: 'project',
    taskCounter: 131,
    taskDate: '2018-07-23T20:24:38.694Z',
    tags: ['picnic'],
    iconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/e/eb/Blue_check.svg',
    redirectToAction: RedirectToAction.ORIGINATION
  }
];
