import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterPipe } from './filter.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { FilterSelfExcludePipe } from './filter-array-exclude';
import { ContainerNamePipe } from './container-name-pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { DecimalDynamicPipe } from './decimal-dynamic.pipe';

@NgModule({
  declarations: [SafeHtmlPipe, FilterPipe, SafeUrlPipe, FilterSelfExcludePipe, ContainerNamePipe, TimeAgoPipe,DecimalDynamicPipe],
  exports: [SafeHtmlPipe, FilterPipe, SafeUrlPipe, FilterSelfExcludePipe, ContainerNamePipe, TimeAgoPipe, DecimalDynamicPipe],
  imports: [CommonModule]
})
export class PipesModule {}
