import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../shared/models';

@Injectable()
export class UserInfoService {
  userProfile$ = new BehaviorSubject<User>(undefined);

  constructor() {
  }

  getUser(): User {
    return this.userProfile$.getValue();
  }

  onLoad(): Observable<User> {
    return this.userProfile$.asObservable();
  }

  /**
   * Set in local storage some option asociated with current User
   * @param option Key of option
   * @param value  Value of option
  */
  setLocalOption(option:string, value: any){

    const optionKey = this.userOptionsKey(option);
    localStorage.setItem(optionKey,value);
  }

  /**
   * Get from local storage some option asociated with current User
   * @param option Key of option
  */
  getLocalOption(option: string){

    const optionKey = this.userOptionsKey(option);
    return localStorage.getItem(optionKey);
  }

  /**
   * Return a key to current User and Option
   * @param option 
  */
  userOptionsKey(option){

    return `option_${this.getUser().id}_${option}`;
  }
}
