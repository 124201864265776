import { Injectable } from '@angular/core';

@Injectable()
export class FormFileUploaderSharedService {
  public queueFilesToDelete = new Map<string, Function>();
  public queueFilesToUpload = new Map<string, Function>();
  public ignorePartialSavePermission = false;
  public inEngine = false;
  public inFormEdit = false;

  constructor() {
    this.ignorePartialSavePermission = window['__env'].IGNORE_PARTIAL_SAVE_PERMISSION.ON_IMAGES_CONTROLS || false;
  }
}
