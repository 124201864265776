import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DtoProductList } from '../../models/DtoProductList';
import { ProductPublisher } from '../../models/ModalContracts/ProductPublisher';
import { ProductsService } from '../../../modules/products/shared/products.service';
import { AlertService } from '../../services/alerts.service';
import { AuthService } from 'src/app/core/services/auth-service.service';

export enum ProductPublisherState {
  PUBLISH,
  REMOVE
}

const config = {
  [ProductPublisherState[ProductPublisherState.PUBLISH]]: {
    firstStep: {
      title: 'Información general del producto que se desea publicar',
      subtitle: ''
    },
    secondStep: {
      title: '¿Usted está seguro que desea publicar este producto?',
      subtitle: 'Una vez que se realice la publicación podrán originarse solicitudes con este producto.'
    }
  },
  [ProductPublisherState[ProductPublisherState.REMOVE]]: {
    firstStep: {
      title: '¿Usted está seguro que desea retirar este producto?',
      subtitle: 'Una vez que se retire no se podrán originar nuevas solicitudes con este producto.'
    }
  }
};

@Component({
  selector: 'app-custom-product-publisher-modal',
  templateUrl: 'custom-product-publisher-modal.component.html',
  styleUrls: [ 'custom-product-publisher-modal.component.scss' ]
})

export class CustomProductPublisherModalComponent implements OnInit {
  selectedProduct: DtoProductList;
  selectedState: ProductPublisherState;
  productType = 'cb7c879f-df04-443a-998e-e9f8f55e2ac4';

  get title() {
    return this.selectedState === ProductPublisherState.REMOVE ? 'Retirar producto' : 'Publicar producto';
  }

  constructor(private _dialog: MatDialogRef<any>, 
              @Inject(MAT_DIALOG_DATA) productConf: ProductPublisher,
              private productService: ProductsService, 
              private _auth: AuthService, 
              private _alert: AlertService) {
                
    _dialog.disableClose = true;
    this.selectedProduct = productConf.product;
    this.selectedState = productConf.state;
  }

  ngOnInit() {
  }

  close(): void {
    this._dialog.close();
  }

  publishProduct(): void {

    const data = {
      parameters: {
        '@@TenantId': window['__env'].APIS.TENANTID,
        '@@AgentId': this._auth.userInfo.uid
      }
    };

    this.productService.publish(this.selectedProduct.id, this.productType, data).subscribe(res => {
      this.handleResponse(res);
    });
  }

  private handleResponse(result): void {
    switch (result.status) {
      case 0: {
        this._alert.success('Producto publicado correctamente', true);
        this._dialog.close(true);
        break;
      }
      case 3: {
        this._alert.error('Error al publicar el producto', false);
      }
    }
  }
}
