import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { APP_SETTINGS } from 'src/app/configs/app-settings.config';
import { Upload } from '../models/upload';

@Injectable({
  providedIn: 'root'
})
export class BlobService {
  constructor(private http: HttpClient, private handler: HttpBackend) {
  }

  getBase64(fileUrl: string): Observable<ArrayBuffer> {
    const httpClient = new HttpClient(this.handler);

    return httpClient.get(fileUrl, { responseType: 'arraybuffer' });
  }

  getBlob(fileUrl: string): Observable<Blob> {
    const httpClient = new HttpClient(this.handler);

    return httpClient.get(fileUrl, { responseType: 'blob' });
  }

  postFileInBlob(instanceId: string, containerId: string, fileToName: string, fileToFullname: string,
                 fileToPost: any): Observable<HttpEvent<any>> {

    const formData = { fileName: fileToName, fullname: fileToFullname, contentFile: fileToPost };

    const uploadReq = new HttpRequest('POST', `${ window['__env'].APIS.ORIGINATIONS }/${
        APP_SETTINGS.GATHER_API.CONTROLLERS.FILES_CONTROLLER
      }/${ instanceId }/${ containerId }`,
      formData);

    return this.http.request(uploadReq);
  }

  postFileProgress(upload: Upload): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', upload.file, upload.name);

    const uploadReq = new HttpRequest('POST', `${ window['__env'].APIS.ORIGINATIONS }/${ APP_SETTINGS.GATHER_API.CONTROLLERS.BULK_UPLOAD }`,
      formData, { reportProgress: true, });

    return this.http.request(uploadReq);
  }

  deleteFileInBlob(instanceId: string, containerId: string, fileToFullname: string): Observable<any> {
    return this.http.delete<any>(
      `${ window['__env'].APIS.ORIGINATIONS }/${
        APP_SETTINGS.GATHER_API.CONTROLLERS.FILES_CONTROLLER
      }/${ instanceId }/${ containerId }/${ fileToFullname }`
    );
  }
}
