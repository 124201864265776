import { Application, Company } from '../models';
import { ApplicationType } from 'src/app/shared/enums';
import { APP_SETTINGS } from 'src/app/configs/app-settings.config';

export const COMPANY: Company = {
  name: 'Formiik',
  primaryLogoUrl: APP_SETTINGS.IMAGES.PRIMARY_COMPANY_LOGO,
  secondaryLogoUrl: APP_SETTINGS.IMAGES.SECONDARY_COMPANY_LOGO,
  loginScreenUrl: APP_SETTINGS.IMAGES.LOGIN_SCREEN
} as Company;

export const APPLICATIONS: Application[] = [
  {
    id: 'e4dab23e-5978-4413-b5d6-88fa58a36400',
    title: 'Administración Formiik Engine',
    iconUrl: '',
    materialIcon: 'view_compact',
    type: ApplicationType.INTERNAL,
    url: '/administration'
  }
];
