interface Array<T> {
  groupBy: <R extends GroupByConstructor>(predicate: (obj: T) => any) => Array<R>;
  select: <R>(predicate: (obj: T) => R) => Array<R>;
  sum: (predicate: (obj: T) => number) => number;
  first: () => T;
  last: () => T;
  arrayToObject: (keyField: string, deleteKeyFieldProperty: boolean) => T;
}

Array.prototype.first = function () {
  return (this.length && this[0]) || undefined;
};

Array.prototype.last = function () {
  return (this.length && this[this.length - 1]) || undefined;
};

Array.prototype.sum = function (predicate) {
  let sum = 0;
  if (this.length) this.forEach(s => sum += predicate(s));
  return sum;
};

/**
 * Función que de una lista selecciona lo que el predicado defina.
 * @param predicate Predicado/expresión lamda a usar.
 */
Array.prototype.select = function <R>(predicate) {
  const obj = new Array<R>();
  if (this.length) {
    this.forEach(s => obj.push(predicate(s)));
  }
  return obj;
};

/**
 * Función que agrupa una lista con respecto al predicado pasado como parametro.
 * @param predicate Predicado/expresion lambda.
 */
Array.prototype.groupBy = function <R extends GroupByConstructor>(predicate) {
  const obj = new Array<R>();
  if (this.length) {
    this.forEach(model => {
      const record = obj.find(m => m.key === predicate(model));
      if (record) {
        record.group.push(model);
      } else {
        const pos = obj.push({ key: predicate(model), group: [] } as R);
        obj[pos - 1].group.push(model);
      }
    });
  }
  return obj;
};

Array.prototype.arrayToObject = function (keyField: string, deleteKeyFieldProperty: boolean) {
  return this.reduce((obj, item) => {
    obj[item[keyField]] = item;
    if (deleteKeyFieldProperty) delete obj[item[keyField]][keyField];
    return obj;
  }, {});
};

/**
 * Interface para los agrupamientos
 */
interface GroupByConstructor {
  key: any;
  group: Array<any>;
}

/**
 * Clase que implementa la interface de agrupamiento.
 */
class GroupByTemp implements GroupByConstructor {
  group: Array<any> = new Array<any>();

  constructor(public key: any) {
  }
}
