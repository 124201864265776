import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

const TIME_AGO_BAD_TEXT = 'hace hace';

@Pipe({
  name: 'timeAgo'
})

export class TimeAgoPipe implements PipeTransform {

  transform(value: any, target: Moment = moment()): any {

    //TODO: Find info about moment issue and investigate if it's posible to use another date library
    let timeAgo = moment(value).from(target);

    if (timeAgo.includes(TIME_AGO_BAD_TEXT)) {
      
      timeAgo = timeAgo.replace(TIME_AGO_BAD_TEXT, 'hace');
    }

    return timeAgo
  }
}
