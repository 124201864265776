import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditorComponent } from 'ngx-monaco-editor';
import { asap } from 'rxjs/internal/scheduler/asap';
import { JavascriptEditorService } from '../../../modules/dashboard/services/javascript-editor.service';
import { filter, takeWhile } from 'rxjs/operators';

export const editorAnimations = trigger('showEditor', [
  state('hide', style({ height: '0' })),
  transition('* => *', animate('300ms'))
]);

type EditorType = 'json' | 'javascript';

@Component({
  selector: 'app-javascript-editor',
  templateUrl: 'javascript-editor.component.html',
  styleUrls: ['javascript-editor.component.scss'],
  animations: [editorAnimations]
})

export class JavascriptEditorComponent implements OnInit, AfterViewInit {
  animationEnd = false;
  previousHeight: any;
  expanded = false;
  opacity = 1;
  /**
   * Emite un valor cada que se introduce contenido al editor.
   */
  @Output() codeChange = new EventEmitter<string>();
  /**
   * Emite un valor cada que se expande el editor.
   */
  @Output() floatingEditorOpened = new EventEmitter<boolean>();
  /**
   * Visibilidad por default del editor.
   */
  @Input() show = 'hide';
  /**
   * Define el lenguaje del editor
   */
  language: EditorType = 'javascript';
  /**
   * Altura del editor.
   */
  @Input() height = '200px';
  @Input() canExpand = true;
  @Input() title = 'código js';
  @ViewChild(EditorComponent) editor: EditorComponent;
  private stopSubscription = false;

  /**
   * Habilita escribir codigo por default en el editor.
   */

  _readOnly = false;

  @Input('readOnly')
  set readOnly(value: boolean) {
    this._readOnly = value;
    this.setLanguage(this.language);
  }

  private _code: string;

  get code(): string {
    return this._code;
  }

  /**
   * Almacena el código escrito en el editor.
   */
  @Input()
  set code(value: string) {
    this._code = value;
    this.codeChange.emit(value);
  }

  private _float = false;

  get float(): boolean {
    return this._float;
  }

  /**
   * Cuando es TRUE oculta el editor y cuando se da la instrucción de mostrarse con la propiedad "SHOW" emerge al nivel de su contenedor
   * padre con posición absoluta y con la altura especificada en su propiedad "HEIGHT".
   *
   * Cuando es FALSE se posiciona en su contenedor padre como cualquier otro componente.
   */
  @Input() set float(value: boolean) {
    if (!value) asap.schedule(() => this.show = 'open');
    this._float = value;
  }

  constructor(private _detectChanges: ChangeDetectorRef, private _editorService: JavascriptEditorService) {
  }

  @HostListener('click') checkClickOutside($event) {

  }

  ngOnInit() {
    this._editorService.statusEditor.pipe(
      takeWhile(() => !this.stopSubscription),
      filter(() => this.float)
    ).subscribe(value => {
      if (this.show !== value.status) {
        this.show = value.status;
      }
      if (value.expand) this.expandEditor();
      this.readOnly = value.readonly;
    });
  }

  ngAfterViewInit(): void {
    this.setLanguage(this.language);
  }

  /**
   * Función para establecer el tipo de sintaxis del editor.
   * @param language Tipo de sintaxis
   */
  setLanguage(language: EditorType = 'json'): void {
    this.language = language;
    this.editor.options = {
      theme: 'vs-dark',
      language: this.language,
      automaticLayout: true,
      contextmenu: false,
      readOnly: this._readOnly,
      autoIndent: true,
      formatOnPaste: true,
      formatOnType: true
    };
  }

  hideEditor(stateEditor: boolean): void {
    if (!this.float) {
      return;
    } else if (this.animationEnd && stateEditor && this.show === 'open') this.show = 'hide';
  }

  closeEditor() {
    if (this.canExpand) {
      if (!this.expanded) {
        this.show = 'hide';
      } else {
        this.expandEditor();
      }
    }
  }

  expandEditor(): void {
    if (!this.expanded) {
      this.expanded = true;
      this.previousHeight = this.height;
      this.height = '95vh';
      this.opacity = 0.99;
    } else {
      this.expanded = false;
      this.height = this.previousHeight;
      this.opacity = 1;
      this.show = 'hide';
      this._editorService.close.next(true);
    }
  }

  openExpandedEditor(event: any): void {
    if (this.canExpand) {
      event.stopPropagation();
      if (this.float) {
        this.expandEditor();
      } else {
        this.floatingEditorOpened.emit(true);
        this._editorService.statusEditor.next({ status: 'open', code: this._code, expand: true, readonly: false });
      }
    }
  }
}
