import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[click-out-side]' })
export class ClickOutSideDirective {
  @Output() clickOutSide = new EventEmitter<any>();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event']) click(e) {
    if (!this._elementRef.nativeElement.contains(e.target)) {
      this.clickOutSide.emit(true);
    }
  }
}
