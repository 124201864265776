import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit {

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger; 
  
  @Input() positionRight = 18; //Default value in px

  constructor(public readonly notificationSrv: NotificationsService) {
  }

  ngOnInit() {
  }

  onClose(){

    this.menu.closeMenu();
  }
}
