import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FunctionsService } from './functions.service';
import { HttpClient } from '@angular/common/http';
import { APP_SETTINGS } from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  constructor(private functionsService: FunctionsService, private http: HttpClient) {
  }

  searchById(id: string): Observable<any> {
    const body = {
      nodeId: id
    };
    return this.http.post<any>(
      `${ window['__env'].APIS.ORIGINATIONS }/${ APP_SETTINGS.GATHER_API.CONTROLLERS.HIERARCHY }/${ APP_SETTINGS.GATHER_API.HIERARCHY.NODES }/${
        APP_SETTINGS.GATHER_API.HIERARCHY.SEARCHNODE }`,
      body
    );
  }

  searchByFolderId(folderId: string): Observable<any> {
    return this.http.get<any>(
      `${ window['__env'].APIS.ORIGINATIONS }/${ APP_SETTINGS.GATHER_API.HIERARCHY.SEARCHFOLDERID }/${ folderId }`
    );
  }

  searchByName(name: string): Observable<any> {
    const body = {
      name: name
    };
    return this.http.post<any>(
      `${ window['__env'].APIS.ORIGINATIONS }/${ APP_SETTINGS.GATHER_API.CONTROLLERS.HIERARCHY }/${ APP_SETTINGS.GATHER_API.HIERARCHY.NODES }/${
        APP_SETTINGS.GATHER_API.HIERARCHY.SEARCHNAME }`,
      body
    );
  }
}

