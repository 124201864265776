import { Pipe, PipeTransform } from '@angular/core';

// Pipe to format a value to a fixed decimal number, when is a number
@Pipe({
  name: 'decimalDynamic'
})
export class DecimalDynamicPipe implements PipeTransform {

  transform(value: any, decimalPlaces: number = 2): any {

    let result = value;

    // check if value is a number is not decimal
    const number = Number(value);

    if (!isNaN(number) && number % 1 != 0) {

      result = number.toFixed(decimalPlaces);
    }

    return result;
  }
}
