import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PermissionsService } from '../../../core/services/permissions.service';

@Component({
  selector: 'app-main-card-v2',
  template: `
    <div id="add" *ngIf="!disableAddButton">
      <button mat-fab (click)="add.emit()" *ngIf="permissions.CAN_CREATE">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['main-card-v2.component.scss']
})

export class MainCardV2Component {
  @Input() disableAddButton = false;

  @Input() set fullPaddingY(state: boolean) {
    this.paddingTop = state ? '0px' : 'unset';
    this.paddingBottom = this.paddingTop;
  }

  @Output() add = new EventEmitter<void>();

  @HostBinding('style.padding-top') paddingTop: string;
  @HostBinding('style.padding-bottom') paddingBottom: string;

  constructor(public readonly permissions: PermissionsService) {
  }
}
