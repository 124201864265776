import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileType } from 'src/app/shared/enums/file-type.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { Upload } from 'src/app/shared/models/upload';
import { ImageViewerOptions } from 'src/app/shared/models/image-viewer-options';
import { FeatureFlagEnum } from 'src/app/shared/enums/featureFlag.enum';
import { FilePreviewAction } from 'src/app/shared/enums/file-preview-action.enum';

// #region Constants

const ROTATE_180 = 180;
const ROTATE_360 = 360;
const CANVAS_ELEMENT = 'canvas';
const CANVAS_CONTEXT = '2d';

// #endregion

@Component({
  selector: 'scc-image-loader-fullscreen',
  templateUrl: './image-loader-fullscreen.component.html',
  styleUrls: ['./image-loader-fullscreen.component.scss']
})
export class ImageLoaderFullscreenComponent implements OnInit {

  featureFlagEnum = FeatureFlagEnum;
  
  @Output() emitHidden = new EventEmitter();
  filetypeEnum = FileType;
  iframeUrl: any;
  blobData: Blob;
  url: string;
  options: ImageViewerOptions = new ImageViewerOptions();
  originalOptions: ImageViewerOptions = new ImageViewerOptions();

  @ViewChild('image') elementImage: ElementRef;

  get imageRotated(): boolean {

    return this.options.rotation % ROTATE_360 !== 0;
  }

  get imageStyle(): {} {
    return {
      'transform': 'scale(' + this.options.scaling + ') rotate(' + this.options.rotation + 'deg) ',
      'width': this.options.width + 'px',
      'height': this.options.height + 'px',
      'transform-origin': '0px 0px',
      'margin-top': this.options.marginTop + 'px',
      'margin-left': this.options.marginLeft + 'px'
    };
  }

  constructor(public dialogRef: MatDialogRef<ImageLoaderFullscreenComponent>, public domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: Upload
  ) { }

  ngOnInit() {
    this.blobData = this.createBlob(this.data.base64, this.data.mimeType);
    const blob = new Blob([this.blobData], { type: this.data.mimeType });
    this.url = this.createUrl(blob);
    this.iframeUrl = this.getSafeBase64(this.url);
    this.originalOptions.height = this.data.height;
    this.originalOptions.width = this.data.width;
    this.options = { height: this.data.height, width: this.data.width, marginTop: 0, marginLeft: 0, scaling: 1.0, rotation: 0 };
  }

  close(deleted: any) {

    this.dialogRef.close({
      action: FilePreviewAction.DELETE,
      data: deleted
    });
  }

  createBlob(fileBase64, fileMimeType): Blob {
    return this.base64toBlob(fileBase64, { type: fileMimeType });
  }

  createUrl(blobData): string {
    return window.URL.createObjectURL(blobData);
  }

  refresh() {
    this.options = {
      height: this.originalOptions.height,
      width: this.originalOptions.width,
      marginTop: 0, marginLeft: 0, scaling: 1.0, rotation: 0
    };
  }

  zoomIn() {
    this.options.scaling *= 1.25;
    this.transform();
  }

  zoomOut() {
    this.options.scaling *= 0.8;
    this.transform();
  }

  rotateLeft() {
    this.options.rotation += -90;
    this.transform();
  }

  rotateRight() {
    this.options.rotation += +90;
    this.transform();
  }

  private transform() {
    if (!this.options || !this.originalOptions) {
      return;
    }

    this.options.width = this.originalOptions.width * this.options.scaling;
    this.options.height = this.originalOptions.height * this.options.scaling;

    const effectiveRotation = (this.options.rotation % 360 + 360) % 360;
    switch (effectiveRotation) {
      case 0:
        this.options.marginTop = 0;
        this.options.marginLeft = 0;
        break;
      case 90:
        this.options.marginTop = 0;
        this.options.marginLeft = this.options.height * this.options.scaling;
        break;
      case 180:
        this.options.marginTop = this.options.height * this.options.scaling;
        this.options.marginLeft = this.options.width * this.options.scaling;
        break;
      case 270:
        this.options.marginTop = this.options.width * this.options.scaling;
        this.options.marginLeft = 0;
        break;
      default:
        console.error('Error de rotacion');
        break;
    }
  }

  download() {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.blobData, this.data.name);
    } else {
      let link: any = document.getElementById('tmpA');
      if (!link) {
        link = document.createElement('a');
      }
      link.id = 'tmpA';
      link.href = this.url;
      link.download = this.data.name;
      link.click();
    }
  }

  print() {
    let iframe: any = document.getElementById('tmpIframe');
    if (!iframe) {
      iframe = document.createElement('iframe');
    }
    iframe.id = 'tmpIframe';
    document.body.appendChild(iframe);

    iframe.src = this.url;
    iframe.style.display = 'none';
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  getSafeBase64(unsafeUrl) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
  }

  /**
  * Function to convert element image to canvas element and then to blob to download
  */
  saveEditedImage() {

    const canvas = document.createElement(CANVAS_ELEMENT);
    const ctx = canvas.getContext(CANVAS_CONTEXT);

    const originalWidth = this.elementImage.nativeElement.width;
    const originalHeight = this.elementImage.nativeElement.height;

    let resultWidth = originalWidth;
    let resultHeight = originalHeight;

    if (this.options.rotation % ROTATE_180 != 0) {

      resultWidth = originalHeight;
      resultHeight = originalWidth;
    }

    canvas.width = resultWidth;
    canvas.height = resultHeight;

    // Translate the canvas to the center of the image
    ctx.translate(resultWidth / 2, resultHeight / 2);

    // Rotate the canvas
    const radians = (this.options.rotation * Math.PI) / ROTATE_180;
    ctx.rotate(radians);

    ctx.drawImage(this.elementImage.nativeElement, -originalWidth / 2, -originalHeight / 2, originalWidth, originalHeight);

    const dataURL = canvas.toDataURL(this.data.mimeType);
    const base64Result = dataURL.split(',')[1];

    this.dialogRef.close({
      action: FilePreviewAction.SAVE,
      data: base64Result
    });
  }

}
