import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base-service.service';

// #region Constants
  
const XSESSIONID = 'X-Session-Id';

// #endregion

// #region Interfaces

interface ActiveSessionResponse {
  activeSession: boolean;
}

// #endregion

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService extends BaseService {

  baseUrl = `${this._env.APIS.TENANTS}/organizations/${this._env.APIS.TENANTID}`;

  activeSession(): Observable<boolean>  {
    
    const headers = {
      [XSESSIONID]: this._auth.sessionIdentifier
    };

    return this.post(`/agents/${this._auth.userId}:activeSession`, {}, { headers }).pipe(
      switchMap(
        (response: ActiveSessionResponse) => {
           
          return of(response.activeSession);
        }
      )
    );
  }

  closeSession() {

    const headers = {
      [XSESSIONID]: this._auth.sessionIdentifier
    };

    return this.post(`/agents/${this._auth.userId}:closeSessions`, {}, { headers });
  }
}
