import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base-service.service';
import { IBaseCatalogService } from '../bases/services/IBaseCatalogService';
import { APP_SETTINGS } from '../../configs/app-settings.config';
import { Observable } from 'rxjs';

@Injectable()
export class HierarchyNewService extends BaseService implements IBaseCatalogService {
  private endpoint = APP_SETTINGS.GATHER_API.CONTROLLERS.HIERARCHY;

  add(data: any): Observable<any> {
    return undefined;
  }

  update(id?: string, data?: any): Observable<any> {
    return undefined;
  }

  getHierarchyByTags(mailBoxTagsArray: Array<string>, folderTags: Array<string>): Observable<any> {
    const body = {
      'mailBoxTags': mailBoxTagsArray,
      'folderTags': folderTags
    };
    return super.post(`${ this.endpoint }/nodes/tagged`, body, { loaderName: 'assignmentModal' });
  }

}
