import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericRow } from '../folder-finder/folder-finder.utils';

@Component({
  selector: 'scc-row-base',
  templateUrl: 'row-base.component.html',
  styleUrls: ['row-base.component.scss']
})
export class RowBaseComponent {
  @Output() rowClicked = new EventEmitter<GenericRow>();
  @Output() rowSelected = new EventEmitter<GenericRow>();
  @Output() rowOptionSelected = new EventEmitter<GenericRow>();
  @Input() row: GenericRow;
  @Input() hasRadio = false;

  RowBaseTypes = RowBaseTypes;
}

export enum RowBaseTypes {
  GENERIC,
  BULK_LOAD
}
