import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-base',
  templateUrl: 'modal-base.component.html',
  styles: [
    '::ng-deep .mat-dialog-actions { display: block !important; width: 100%; overflow:hidden; margin-bottom: -10px !important;}'
  ]
})

export class ModalBaseComponent {
  @Input() title: string;
  @Input() message: string;

  constructor() {
  }
}
