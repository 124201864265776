import { Injectable } from '@angular/core';
import { APP_SETTINGS } from 'src/app/configs/app-settings.config';
import { FormUtilitiesService } from 'src/app/shared/services/form-utilities.service';
import { OriginationsService } from './originations.service';
import { GlobalService } from './global.service';

@Injectable()
export class InstanceService {
  constructor(private formUtilitiesService: FormUtilitiesService, private originationsService: OriginationsService,
              private globalSvc: GlobalService) {
  }

  public getValue(fullName: string) {
    let valueToSetAny: any = null;
    const selectedInstance = this.globalSvc.getInstance().getValue();
    const selectedContainer = this.globalSvc.getContainer().getValue();

    if (selectedInstance && selectedInstance.values && selectedInstance.values.containers && selectedContainer) {
      const selectedReducedContainer = this.globalSvc.getReducedContainer().getValue();
      const currentContainer = selectedInstance.values.containers[selectedContainer.containerId];
      const completedFullName = fullName && fullName.split('.').join('.components.');

      if (fullName && fullName.includes(APP_SETTINGS.VALIDATION_CONSTANTS.EXTERNAL_CONTAINER_SEPARATOR)) {
        if (selectedReducedContainer && selectedReducedContainer.externals && selectedReducedContainer.externals.hasOwnProperty(fullName)) {
          valueToSetAny = selectedReducedContainer.externals[fullName];
        }
      } else {
        let fieldToSearch: any = null;

        if (currentContainer) {
          try {
            if (currentContainer.data.autosave) {
              fieldToSearch = this.formUtilitiesService.findInObject(currentContainer.data.autosave, completedFullName);
            }
          } catch (error) {
            valueToSetAny = null;
          }
          try {
            if (!Object.keys(fieldToSearch).length && currentContainer.data.values) {
              fieldToSearch = this.formUtilitiesService.findInObject(currentContainer.data.values, completedFullName);
            }
          } catch (error) {
            valueToSetAny = null;
          }

          if (fieldToSearch) {
            if (fieldToSearch.hasOwnProperty('value')) {
              valueToSetAny = fieldToSearch['value'];
            } else if (fieldToSearch.hasOwnProperty('selection')) {
              const result: any = [];
              valueToSetAny = fieldToSearch['selection'];

              if (valueToSetAny !== null) {
                for (const singleValue of valueToSetAny) {
                  result.push(singleValue.key);
                }
                valueToSetAny = result;
              }
            } else {
              valueToSetAny = null;
            }
          }
        }
      }
    }
    return valueToSetAny;
  }
}
