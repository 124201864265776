import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-text-label',
  templateUrl: './result-text-label.component.html',
  styleUrls: ['./result-text-label.component.scss']
})
export class ResultTextLabelComponent implements OnInit {
  @Input() labelText: string;
  showFullText = false;
  textValue = '';
  maxLength = 800;

  constructor() { }

  ngOnInit() {
    this.textValue = this.labelText.substring(0, this.maxLength);
  }

  toggleText(){
    this.showFullText = !this.showFullText;
    if(this.showFullText) this.textValue = this.labelText;
    else this.textValue = this.labelText.substring(0, this.maxLength);
  }
}
