import { Component, OnInit } from '@angular/core';
import { CustomModal } from '../CustomModal';

@Component({
  selector: 'scc-warning-modal',
  template: `
    <h1 mat-dialog-title>{{ title || 'ERROR' }}</h1>
    <div mat-dialog-content>
      <p>{{ message }}</p>
    </div>
    <div mat-dialog-actions style="display: flex !important;">
      <span class="flex-fill"></span>
      <scc-custom-button kind="confirm" (customClick)="yes()">Aceptar</scc-custom-button>
    </div>
  `
})

export class ErrorModalComponent extends CustomModal<any> implements OnInit {

  yesAction: Function;

  ngOnInit() {
    if (this.data.actions) this.yesAction = this.data.actions.yes;
  }

  yes(): void {
    if (this.yesAction) this.yesAction();
    this._dialog.close();
  }
}

