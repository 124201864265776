export enum GroupNodeType {
  NODE = 0,
  LEAF = 1,
  NODE_ARRAY = 2,
  REPEATED_NODE = 128,
  REPEATED_LEAF = 129
}

export enum GroupNodePosition {
  BEFORE = -1,
  INTO = 0,
  AFTER = 1
}
