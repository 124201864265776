import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder-item-result',
  templateUrl: './folder-item-result.component.html',
  styleUrls: ['./folder-item-result.component.scss']
})
export class FolderItemResultComponent implements OnInit {
  @Input() resultSearch;

  constructor() {
  }

  ngOnInit() {
  }

  public icon(agent: any, type: number): string {
    return type === 0 ? agent !== undefined ? 'folder_shared' : 'folder_open' : 'markunread_mailbox';
  }
}
