import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDrawerContainer } from '@angular/material';

@Component({
  selector: 'app-catalog-crud-container',
  templateUrl: 'catalog-crud-container.component.html',
  styleUrls: ['catalog-crud-container.component.scss'],
  animations: [
    trigger('showHide', [
      state('hide', style({
        bottom: '-70px'
      })),
      state('show', style({
        bottom: 0
      })),
      transition('show => hide, hide => show', [
        animate('0.3s')
      ])
    ])
  ]
})

export class CatalogCrudContainerComponent implements OnInit {
  private _lastFormState: string;
  private _form: AbstractControl;
  private _formSubscription: Subscription;
  private _validator: () => boolean;
  private _crudViewValidatorType: CRUDViewValidator;

  showActionsPanel = false;
  sidebarOptionSelected: CRUDViewSidebarOptions;
  sidebarOption: typeof CRUDViewSidebarOptions = CRUDViewSidebarOptions;

  // ENTRADAS
  @Input() viewTitle: string;
  @Input() titleBorder: boolean;
  @Input() hideIcons: boolean;

  @Input() set form(form: AbstractControl) {
    if (!form) {
      throw new Error(`[${ this.viewTitle }] Formulario inválido.`);
    }
    this._validator = undefined;
    this._lastFormState = JSON.stringify(form.value);
    this._form = form;
    this._startListeningForm();
    this._crudViewValidatorType = CRUDViewValidator.FORM;
  }

  @Input() set validator(fn: () => boolean) {
    if (!fn) throw new Error(`[${ this.viewTitle }] Validador inválido.`);
    this._form = undefined;
    this._validator = fn;
    this._crudViewValidatorType = CRUDViewValidator.CUSTOM_VALIDATOR;
  }

  // SALIDAS
  @Output() save = new EventEmitter<void>();
  @Output() saveAndClose = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() sidebar = new EventEmitter<CRUDViewSidebarOptions>();

  @ViewChild('sdbar') private _sidebar: MatDrawerContainer;

  get canSave() {
    return this._crudViewValidatorType === CRUDViewValidator.FORM ? this._form.valid : this._validator();
  }

  ngOnInit() {
    if (!this._crudViewValidatorType) throw new Error(`[${ this.viewTitle }] No se encontro ningún tipo de validador.`);
  }

  selectSidebarOption(option: CRUDViewSidebarOptions): void {
    if (this.sidebarOptionSelected === option) {
      this._sidebar.close();
      this.resetSidebar();
      return;
    } else if (!option) throw new Error(`[${ this.viewTitle }] Opción no valida.`);
    this.sidebarOptionSelected = option;
    this.sidebar.emit(this.sidebarOptionSelected);
    this._sidebar.open();
  }

  resetSidebar(): void {
    this.sidebarOptionSelected = undefined;
    this.sidebar.emit(undefined);
  }

  private _startListeningForm(): void {
    if (this._formSubscription) this._formSubscription.unsubscribe();
    this._formSubscription = this._form.valueChanges.pipe(
      map(data => JSON.stringify(data))
    ).subscribe(data => {
      if (data === this._lastFormState) {
        this.showActionsPanel = false;
      } else if (!this.showActionsPanel) {
        this.showActionsPanel = true;
      }
    });
  }


}

enum CRUDViewValidator {
  FORM = 1,
  CUSTOM_VALIDATOR
}

export enum CRUDViewSidebarOptions {
  HISTORY = 1,
  ANNOTATIONS,
  TUTORIALS
}
