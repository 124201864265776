import { RedirectToAction, ColumnType, IconType, ColumnFormat } from '../enums';

export class Column {
  //#region Required properties JSON
  order: number;
  title: string;
  columnId: string;
  type: ColumnType;
  isHidden: boolean;
  isOrdered?: boolean;
  //#endregion

  format: ColumnFormat;
  iconType?: IconType;
}

export interface Row {
  // id: string;
}

export class SpecialCellData {
  id: string;
  formattedValue: string;
  value: string;
  color: string;
}

export class ColumnFilter {
  columnId: string;
  filterBy: FilterByColumn[];
}
export class FilterByColumn {
  value: string;
  columns: Column[];
}

export class TabTable {
  //#region Required properties JSON

  order: number;
  name: string;
  columns: Column[];
  rows: Row[];
  columnFilter: ColumnFilter | null | undefined;
  rowAction: RedirectToAction;

  useCheckBox?: boolean;
  contextMenu?: ContextMenuTabTable[];
  //#endregion
  filterByValue?: string;
}

export class ContextMenuTabTable {
  title: string;
  materialIcon?: string;
  subContextMenu?: ContextMenuTabTable[];
}
