import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base-service.service';

export interface SurveyResponse {
  isSurveyEnabled: boolean;
  isAnswered: boolean;
}

export interface SurveyRequest {
  value: number;
  comment: string;
}

enum SurveyConst {
  EndpointCheck = 'surveys:isEnabled',
  EndpointSend = 'surveys',
  SurveySkipped = 'survey_skipped'
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService extends BaseService {

  public currentSurvey$ = new BehaviorSubject<boolean>(false);

  baseUrl = `${this._env.APIS.TENANTS}/organizations/${this._env.APIS.TENANTID}`;

  /** Verify is the user has some survey active */
  check() {

    // Need build the url to add user id to the request after login
    const endpoint = `agents/${this._auth.userId}/${SurveyConst.EndpointCheck}`;

    // If survey is skipped don't make the request
    if (this.isSurveySkipped()) {

      return of();
    }

    return this.get(endpoint, {
      loader: true,
      loaderMessage: 'Verificando encuesta...',

    }).pipe(
      catchError((err) => {

        this.currentSurvey$.next(false);

        return throwError(err);
      }),
      tap(
        (response: SurveyResponse) => {

          this.currentSurvey$.next(response.isSurveyEnabled && !response.isAnswered)
        }
      )
    );
  }

  /** Send survey response of user */
  send(data: SurveyRequest) {

    const endpoint = `agents/${this._auth.userId}/${SurveyConst.EndpointSend}`;

    return this.post(endpoint, data, {
      loader: true,
      loaderMessage: 'Enviando encuesta...',
      errorMsg: 'Ha ocurrido un error inesperado. No se ha podido enviar su respuesta a la encuesta.'
    }).pipe(
      switchMap(
        () => {

          this._alerts.success(`La encuesta se ha enviado correctamente.`);
          return of(true);
        }
      )
    );
  }

  setSurveySkipped() {

    localStorage.setItem(SurveyConst.SurveySkipped, 'true');
  }

  clearSurveySkipped() {

    localStorage.removeItem(SurveyConst.SurveySkipped);
  }

  isSurveySkipped(): boolean {

    const isIgnored = localStorage.getItem(SurveyConst.SurveySkipped);
    return isIgnored ? !!isIgnored : false;
  }
}
