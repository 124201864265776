export class ReducedInstance {
  datafront: DataFront;
  instanceId: string;
  containerId: string;
  values: any;
  externals: any;
  tenantId: string;
  agentId: string;
  agetUserName: string;
  status: number;
}

export class DataFront {
    description: string;
    isReadOnly: boolean;
    isToValidate: boolean;
    sections: any[];
}
