import { Component} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

/**Component to render a dialog with Authorization Error */
@Component({
  selector: 'auth-error-alert',
  templateUrl: './auth-error-alert.component.html',
  styleUrls: ['./auth-error-alert.component.scss']
})
export class AuthErrorAlertComponent  {
  
  constructor(
    private readonly _router: Router,
    private _dialogRef: MatDialogRef<any>
    ) {
  }

  goHome(){
    
    this._dialogRef.close();
    this._router.navigateByUrl('/');
  }
}
