import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-navbar-search',
  template: `
    <div style="display: grid; grid-template-columns: 5vw auto 4vw;">
      <div style="position: relative;display: inline-block; color: #bdbdbd; font-size: 26px; text-align: center;">
        <mat-icon [inline]="true" style="position: relative; top: 50%; transform: translateY(-50%)">search</mat-icon>
      </div>
<!--      <div>-->
        <mat-form-field appearance="none" floatLabel="never">
          <input #input type="text" matInput [formControl]="control"
                 placeholder="{{'DASHBOARD_COMPONENT.SEARCH_PLACEHOLDER' | translate}}">
        </mat-form-field>
<!--      </div>-->
      <div style="text-align: end">
        <button mat-icon-button (click)="emitClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['navbar-search.component.scss']
})

export class NavbarSearchComponent implements OnInit, OnDestroy {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Output() close = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  control = new FormControl(null);
  private statusSubscribes$ = new Subject();

  constructor() {
  }

  ngOnInit() {
    this.control.valueChanges.pipe(takeUntil(this.statusSubscribes$), distinctUntilChanged(), debounceTime(500))
      .subscribe(data => this.search.emit(data));
  }

  ngOnDestroy(): void {
    this.statusSubscribes$.next(true);
    this.statusSubscribes$.complete();
  }

  emitClose(): void {
    this.close.emit(true);
  }

  setFocus(): void {
    this.input.nativeElement.focus();
  }
}
