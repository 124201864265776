import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from './global.service';
import { FormElement } from '../enums';
import { ElementsForm, SectionForm } from '../models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormIgnoredElements } from '../../modules/form/enums/form-ignored-elements.enum';
import { HttpClient } from '@angular/common/http';
import { APP_SETTINGS as AS } from 'src/app/configs/app-settings.config';
import { transformToRaw } from '../../modules/form/form.utils';
import { DtoDataType } from '../models/Definition/DtoDataType';

@Injectable()
export class FormDataService {
  lastProcessedContainerId: string;
  isFormEditSectionLoaded$ = new Subject();
  blockAllTaskSelects = false;
  isPollingForm = false;
  someFieldFunctionActivated = false;
  isSaving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeFormEdit: string;
  private _formEditStatus = true;
  index: number;
  data: any;
  catalogsInMemory = new Map<string, []>();
  formEditModelsSelected = [];
  formEditSections = new Map<string, Array<SectionForm>>();
  public affectedFullNames = new Map<string, { control: AbstractControl; element: ElementsForm }>([]);
  private _dataTypes = new Map<string, DtoDataType>([]);
  private _fullNameKeynameDictionary = new Map<string, string>([]);
  private _autoSave = new Map<string, {}>([]);
  private _formContainer = new Map<string, FormGroup>([]);
  private _formGroupFormEdit = new Map<string, FormGroup>([]);
  private _formTableDataSource = new Map<string, Array<any>>([]);
  private _formEditRawValues = new Map<string, Array<any>>([]);
  private _formEditEmitters = new Map<string, BehaviorSubject<[]>>();
  private _containerSections = new Map<string, Array<any>>([]);
  private _comments = new Map<string, Array<any>>([]);
  private _fullNameProperty = new Map<string, Map<string, string>>([]);
  private _datafrontElements = new Array<ElementsForm>();
  private _datafrontElementsHelper = new Array<ElementsForm>();
  private _partialFunctions = new Map<string, BehaviorSubject<boolean>>();

  set formEditStatus(state: boolean) {
    this._formEditStatus = state;
  }

  get formEditStatus() {
    return this._formEditStatus;
  }

  /**
   * Función Helper que ayuda en la creación del Control a partir de un Elemento.
   * @param element Elemento
   */
  static createControl(element: ElementsForm): AbstractControl {
    return new FormControl(undefined, element.isRequired ? [Validators.required] : []);
  }

  /**
   * Función Helper que ayuda en la creación del Control a partir de un archivo.
   * @param element Elemento
   */
  static createFileControl(element: ElementsForm): AbstractControl {
    const validators = element.isRequired ? [Validators.required] : [];
    return new FormGroup(
      {
        'FileName': new FormControl(null, validators), //Changed to null as default value
        'FileUrl': new FormControl(null, validators), //Changed to null as default value
        'MimeType': new FormControl()
      }
    );
  }

  constructor(private readonly _globalSvc: GlobalService, private readonly _http: HttpClient) {
  }

  /**
   * Función que agrega un formulario de formedit por su fullname
   * @param fullName Propiedad fullname del formedit
   */
  addFormEditFormGroup(fullName: string): FormGroup {
    const formCreated = new FormGroup({});
    this._formGroupFormEdit.set(fullName, formCreated);
    if (!this._formEditEmitters.get(fullName)) this._formEditEmitters.set(fullName, new BehaviorSubject<[]>([]));
    return formCreated;
  }

  getFormEditEmitterByFullName(fullName: string): BehaviorSubject<[]> {
    return this._formEditEmitters.get(fullName);
  }

  removeFormEditEmitters(): void {
    this._formEditEmitters.clear();
  }

  /**
   * Función que obtiene un formulario de formedit por su fullname
   * @param fullName Propiedad fullname del formedit
   */
  getFormEditFormGroup(fullName: string): FormGroup {
    return this._formGroupFormEdit.get(fullName);
  }

  updateFormEditFormGroup(fullName: string, formCreated): FormGroup {
    this._formGroupFormEdit.set(fullName, formCreated);
    return this._formGroupFormEdit.get(fullName);
  }

  /**
   * Función que agrega una seccion con fieldfunction
   * @param sectionForm Id del contenedor donde esta la seccion
   */
  addSectionsGroup(sectionForm: SectionForm) {
    const idContainer = this._globalSvc.getContainer().getValue().containerId;
    const sectionFormGroup: SectionForm[] = this._containerSections.get(idContainer) || [];
    if (!sectionFormGroup.find(section => section.selectionIndex === sectionForm.selectionIndex)) sectionFormGroup.push(sectionForm);
    this._containerSections.set(idContainer, sectionFormGroup);
  }

  addDatafrontElement(element: ElementsForm): void {
    this._datafrontElements.push(JSON.parse(JSON.stringify(element)));
  }

  getDatafrontElement(fullName: string): ElementsForm {
    return this._datafrontElements.find(f => fullName === f.fullName);
  }

  getDatafrontElements(): Array<ElementsForm> {
    return this._datafrontElements;
  }

  removeAllDatafrontElements(): void {
    this._datafrontElements = [];
  }

  addDatafrontElementHelper(element: ElementsForm): void {
    this._datafrontElementsHelper.push(JSON.parse(JSON.stringify(element)));
  }

  getDatafrontElementHelper(fullName: string, elementType?: number, selectionIndex?: number): ElementsForm {
    if (elementType === FormElement.CHOICELIST_GROUP) {
      return this._datafrontElementsHelper.find(e => e.fullName === `${ fullName }${ selectionIndex }`);
    }
    return this.getDatafrontElement(fullName);
  }

  getDatafrontElementsHelper(): ElementsForm[] {
    return this._datafrontElementsHelper;
  }

  removeAllDatafrontElementsHelper(): void {
    this._datafrontElementsHelper = new Array<ElementsForm>();
  }

  addPartialFunction(fullName: string, listener: BehaviorSubject<boolean>) {
    this._partialFunctions.set(fullName, listener);
  }

  getPartialFunctionListener(fullName: string): BehaviorSubject<boolean> {
    return this._partialFunctions.get(fullName);
  }

  getPartialFunctionValue(fullName: string): boolean {
    return this._partialFunctions.get(fullName).getValue();
  }

  removePartialFunctionListener(fullName: string): void {
    this._partialFunctions.delete(fullName);
  }

  removeAllPartialFunctionListeners(): void {
    this._partialFunctions.clear();
  }

  /**
   * Función que obtiene las secciones con fieldfunction de un contenedor
   * @param idContainer Id del contenedor donde esta la seccion
   */
  getSectionsGroup(idContainer?: string): Array<any> {
    if (!idContainer) idContainer = this._globalSvc.getContainer().getValue().containerId;
    return this._containerSections.get(idContainer);
  }

  addTableDataSource(fullName: string, dataSource: Array<any>) {
    this._formTableDataSource.set(fullName, dataSource);
    this._formEditRawValues.set(fullName, dataSource.map(m => transformToRaw(m)));
  }

  getTableDataSource(fullName: string = this.activeFormEdit): ReadonlyArray<any> {
    return this._formTableDataSource.get(fullName);
  }

  resetDataSources(): void {
    this._formTableDataSource.clear();
    this._formEditRawValues.clear();
  }

  getRawDataSource(fullName: string = this.activeFormEdit): ReadonlyArray<any> {
    return this._formEditRawValues.get(fullName);
  }

  addItemFromDataSource(item: any, index: number = -1, fullName: string = this.activeFormEdit): void {
    const dataSource = this._formTableDataSource.get(fullName);
    index > -1 ? dataSource[index] = JSON.parse(JSON.stringify(item)) : dataSource.push(item);
  }

  addRawItemFromDataSource(item: any, index: number = -1, fullName: string = this.activeFormEdit): void {
    const dataSource = this._formEditRawValues.get(fullName);
    index > -1 ? dataSource[index] = JSON.parse(JSON.stringify(item)) : dataSource.push(item);
  }

  deleteItemFromDatasource(fullName: string, index: number): [ReadonlyArray<any>, ReadonlyArray<any>] {
    this._formTableDataSource.get(fullName).splice(index, 1);
    this._formEditRawValues.get(fullName).splice(index, 1);
    return [this._formTableDataSource.get(fullName), this._formEditRawValues.get(fullName)];
  }

  duplicateItemFromDatasource(fullName: string = this.activeFormEdit, index: number): [ReadonlyArray<any>, ReadonlyArray<any>] {
    const dataSource = this._formTableDataSource.get(fullName);
    const dataSource2 = this._formEditRawValues.get(fullName);
    const toDuplicate = JSON.parse(JSON.stringify(dataSource[index]));
    const toDuplicate2 = JSON.parse(JSON.stringify(dataSource2[index]));
    dataSource.push(toDuplicate);
    dataSource2.push(toDuplicate2);
    return [dataSource, dataSource2];
  }

  /**
   * Función que agrega un formulario y lo asocia a un contenedor.
   * @param idContainer Identificador del contenedor.
   * @param form Formulario a asociar con el contenedor.
   */
  addFormContainer(idContainer: string, form: FormGroup): void {
    if (!idContainer || !form) throw new Error(`Error: El id del contenedor y formulario son requeridos.`);
    this._formContainer.set(idContainer, form);
  }

  setPropertyByFullNameMap(idContainer: string, map: Map<string, string>): void {
    this._fullNameProperty.set(idContainer, map);
  }

  /**
   * Función que agrega un diccionario con información previamente guardada de guardados parciales y los asocia un contenedor.
   * @param idContainer Identificador del contenedor.
   * @param autoSavedData Diccionario con los fullNames y valores.
   */
  addAutoSavedPlaneObject(idContainer: string, autoSavedData: Object): void {
    if (!idContainer || !autoSavedData) throw new Error(`Error: El id del contenedor e información auto-guardada son requeridos.`);
    this._autoSave.set(idContainer, autoSavedData);
  }

  /**
   * Función que agrega un keyName y lo asocia a un diccionario con información de determinado Datatype.
   * @param keyName Nombre del KeyName.
   * @param dataTypeObj Diccionario con información del Datatype.
   */
  addDataType(keyName: string, dataTypeObj: DtoDataType): void {
    this._dataTypes.set(keyName, dataTypeObj);
  }

  /**
   * Función que retorna el mapa de KeyNames-Objectos datatypes.
   */
  getDataTypesMap(): Map<string, DtoDataType> {
    return this._dataTypes;
  }

  /**
   * Función que agrega un KeyName y lo asocia a un FullName de un elemento.
   * @param fullName FullName del elemento.
   * @param keyName KeyName del datatype.
   */
  addKeyNameByFullName(fullName: string, keyName: string): void {
    this._fullNameKeynameDictionary.set(fullName, keyName);
  }

  /**
   * Función que retorna un mapa de KeyName-FullName.
   */
  getKeyNamesByFullNameMap(): Map<string, string> {
    return this._fullNameKeynameDictionary;
  }

  /**
   * Función que obtiene el formulario del contenedor actual o del Id del contenedor pasado como parametro.
   * @param idContainer (Opcional) Indica el contenedor de donde queremos el formulario.
   */
  getContainerFormGroup(idContainer?: string): FormGroup {
    return this._formContainer.get(idContainer || this._globalSvc.getContainer().getValue().containerId);
  }

  /**
   * Función que retorna el Id del contenedor actual
   */
  getActualContainerId(): string {
    const container = this._globalSvc.getContainer().getValue();
    if (container) return this._globalSvc.getContainer().getValue().containerId;
    return null;
  }

  /**
   * Función que retorna los datos previamente guardados del contenedor actual.
   */
  getActualAutoSavedData(): any {
    return this._autoSave.get(this.getActualContainerId());
  }

  /**
   * Función que actualiza el autosave en local
   * @param fullName Identificador del control
   * @param value Valor a guardar
   */
  setFullNameValueAutoSave(fullName: string, value: any): boolean {
    const res = this.getActualAutoSavedData()[fullName];
    if (res) {
      this.getActualAutoSavedData()[fullName] = value;
      return true;
    }
    return false;
  }

  /**
   * Función que genera y agrega los controladores a partir de las "n" secciones.
   * @param sections Secciones
   * @param formEditFullName FormEdit en donde se agregará los controles, si no se especifica, se agregan en el formulario actual.
   */
  addControlFromSections(sections: Array<SectionForm>, formEditFullName?: string): FormGroup {
    const actualForm = this.getFormEditFormGroup(formEditFullName) || this.getContainerFormGroup();
    // Sections
    sections.forEach((section: any) => {
      // Section Rows
      if (section.rows) {
        section.rows.forEach((row: any) =>
          // TODO: La creación de los controles no contemplan los escenarios donde los elementos de los rows tienen "components"
          // Row Elements
          row.elements && row.elements.forEach(element => {
            if (FormIgnoredElements[element.elementType]) return;
            let control: AbstractControl;
            if (!actualForm.contains(element.fullName.toString())) {
              if (element.elementType === FormElement.CHOICELIST_GROUP) {
                control = new FormArray([]);
              } else if (element.elementType === FormElement.ATTACHMENT ||
                element.elementType === FormElement.IMAGE) {
                const validators = element.isRequired ? [Validators.required] : [];
                control = new FormGroup(
                  {
                    'FileName': new FormControl('', validators),
                    'FileUrl': new FormControl('', validators),
                    'MimeType': new FormControl()
                  }
                );
              } else if (element.elementType === FormElement.MAP) {
                control = new FormGroup({
                  'Latitud': new FormControl(),
                  'Longitud': new FormControl()
                });
              } else {
                control = new FormControl(undefined, element.isRequired ? [Validators.required] : []);
              }

              // Este condicional es un HACK para corregir la data que llega del backend.
              if (element.elementType === FormElement.CHOICELIST) {
                const actualAutoSave = this.getActualAutoSavedData();
                if (!actualAutoSave) return;
                const selectValue = actualAutoSave[element.fullName.toString()];
                if (selectValue && selectValue.push) actualAutoSave[element.fullName.toString()] = selectValue[0];
              }
              actualForm.registerControl(element.fullName.toString(), control);
            }
          }));
      }
    });
    return this._formContainer.get(this._globalSvc.getContainer().getValue().containerId);
  }

  /**
   * Función que agrega un control basado en el elemento especificado en el parametro.
   * @param element Elemento del cual se basara la creación del control.
   * @param formEditFullName FormEdit en donde se agregará el element, si no se especifica, se agrega en el formulario actual.
   */
  addControl(element: ElementsForm, formEditFullName?: string): AbstractControl {
    const formRef = this.getFormEditFormGroup(formEditFullName) || this.getContainerFormGroup();
    let control;
    if (element.elementType === FormElement.IMAGE ||
      element.elementType === FormElement.ATTACHMENT) {
      control = FormDataService.createFileControl(element);
    } else {
      control = FormDataService.createControl(element);
    }
    if (element.elementType === FormElement.CHOICELIST_GROUP) {
      const formArr = (<FormArray>this.getControl(element.fullName));
      if (formArr) {
        formArr.push(control);
      } else {
        formRef.registerControl(element.fullName, new FormArray([]));
        (<FormArray>formRef.controls[element.fullName.toString()]).push(control);
      }
    } else {
      formRef.registerControl(element.fullName, control);
    }
    return control;
  }

  /**
   * Función que agrega un control a un FormArray a partir de un elemento.
   * @param formArrayName Nombre del FormArray
   * @param element Elemento
   * @param formEditFullName FormEdit en donde se agregará el formArray, si no se especifica, se agrega en el formulario actual.
   */
  addControlFormArray(formArrayName: string, element: ElementsForm, formEditFullName?: string): AbstractControl {
    const formRef = this.getFormEditFormGroup(formEditFullName) || this.getContainerFormGroup();
    if (!(formRef as FormGroup).controls[formArrayName]) return;
    const controlArr = FormDataService.createControl(element);
    (<FormArray>formRef.controls[formArrayName]).push(controlArr);
    return controlArr;
  }

  /**
   * Función que obtiene el control a partir del FullName del elemento.
   * @param fullName Nombre del elemento.
   * @param formEditFullName FormEdit en donde se buscara el fullName, si no se especifica, se obtiene del formulario actual.
   */
  getControl(fullName: string, formEditFullName?: string): AbstractControl | null {
    const formRef = this.getFormEditFormGroup(formEditFullName) || this.getContainerFormGroup();
    return formRef.controls[fullName];
  }

  /**
   * Función que obtiene un FormControl de un FormArray basado en su indice.
   * @param formArrayName Nombre del FormArray
   * @param index Indice del control a buscar.
   * @param formEditFullName FormEdit en donde se buscara el formArrayName, si no se especifica, se obtiene del formulario actual.
   */
  getFormArrayControl(formArrayName: string, index: number, formEditFullName?: string): AbstractControl {
    const formRef = this.getFormEditFormGroup(formEditFullName) || this.getContainerFormGroup();
    return <FormArray>formRef.controls[formArrayName] ?
      (<FormArray>formRef.controls[formArrayName]).at(index) : <FormArray>formRef.controls[formArrayName];
  }

  /**
   * Función que desacopla el Id del contenedor del Full Name recibido como parametro y retorna un objeto.
   *
   * Ejemplo:
   * 22174737-f6e4-41ef-9df4-f71a9acfed16\\Identificacion.TipoDoc
   *
   * Retorna:
   * { idContenedor: '22174737-f6e4-41ef-9df4-f71a9acfed16', fullName: 'Identificacion.TipoDoc' }
   *
   * En caso de que el Full Name no este conformado por un GUID, solo regresará el objeto con su propiedad
   * "fullName" seteada.
   *
   * @param fullName Full Name del elemento
   */
  getFullName(fullName: string): { idContenedor?: string, fullName: string } {
    if (!fullName) throw new Error('FormDataService > getFullName: Invalid full name.');
    const data = fullName.split('\\');
    return data.length > 1 ? { idContenedor: data[0], fullName: data[2] } : { fullName: data[0] };
  }

  /**
   * Función que guarda los comentarios del Full Name pasado como parametro.
   * @param fullName Full Name del elemento.
   * @param comments Arreglo de comentarios a guardar.
   */
  setComment(fullName: string, comments: Array<any>): void {
    if (!fullName) throw new Error('No se puede guardar los comentarios sin Full Name.');
    this._comments.set(fullName, comments);
  }

  getGeometry(search: string): Observable<any> {
    return this._http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + search + '&key=' + AS.MAPS.GOOGLE_MAPS.API_KEY);
  }
}
