import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'scc-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  @Input()
  comment: string;

  commentDialogResult = CommentDialogResult;
  commentDialogSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialogData,
    private functionService: FunctionsService
  ) {
    this.commentDialogSubscription = this.functionService.openCommentDialogResult.subscribe(
      valueButton => {
        if (valueButton === this.commentDialogResult.OK_BUTTON) {
          // ok
        }
      }
    );
  }

  ngOnInit() {}

  optionClicked(value: CommentDialogResult) {
    this.functionService.openCommentDialogResult.emit(value);
    this.dialogRef.close();
  }
}

export enum CommentDialogResult {
  CANCEL_BUTTON = 1,
  OK_BUTTON = 2
}

export interface CommentDialogData {
  width: string;
  height: string;
}
