import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericRow } from '../folder-finder.utils';

@Component({
  selector: 'scc-bulk-load-row',
  template: `
    <div row-container>
      <section row-info>
        <mat-icon>insert_drive_file</mat-icon>
        <span>{{ row.title }}</span>
        <span *ngIf="row.text1"><span>|</span>{{ row.text1 }}</span>
        <span *ngIf="row.text2"><span>|</span>{{ row.text2 }}</span>
        <span *ngIf="row.text3"><span>|</span>{{ row.text3 }}</span>
      </section>
      <section row-path>
        {{row.text4}}
      </section>
      <section style="margin-right: 14px">
        {{row.text5}}
      </section>
      <section>
        <mat-menu #menu="matMenu">
          <button mat-menu-item disabled><small style="color: black">Descargar plantilla</small></button>
          <mat-divider></mat-divider>
          <ng-container *ngFor="let option of templateFormats">
            <button mat-menu-item (click)="emitOptionSelected(option)">
              {{ option.description }}
            </button>
          </ng-container>
        </mat-menu>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>
            save_alt
          </mat-icon>
        </button>
      </section>
    </div>
  `,
  styleUrls: ['./bulk-load-row.component.scss']
})
export class BulkLoadRowComponent implements OnInit {
  @Input() row: GenericRow;
  @Output() optionSelected = new EventEmitter<GenericRow>();
  templateFormats: Array<DtoTemplateFormats>;

  ngOnInit(): void {
    this.templateFormats = getTemplateFormats();
  }

  emitOptionSelected(optionSelected: DtoTemplateFormats): void {
    this.row.unMappedElement.isTemplateFull = optionSelected.isFull;
    this.row.unMappedElement.templateFormat = optionSelected.format;
    this.optionSelected.emit(this.row);
  }
}

export class DtoTemplateFormats {
  constructor(public description: string, public type: number, public isFull: boolean, public format: string) {
  }
}

export function getTemplateFormats(): Array<DtoTemplateFormats> {
  return [
    new DtoTemplateFormats('CSV completa', TemplateFormats.CSV, true, 'csv'),
    new DtoTemplateFormats('CSV reducida', TemplateFormats.CSV, false, 'csv'),
    new DtoTemplateFormats('JSON completa', TemplateFormats.JSON, true, 'json'),
    new DtoTemplateFormats('JSON reducida', TemplateFormats.JSON, false, 'json'),
  ];
}

export enum TemplateFormats {
  CSV,
  JSON
}

