export class Member {
  public id: string;
  public name: string;
  public department: string;
  public municipality: string;
  public neighborhood: string;
  public streetAndNumber: string;
  public phone: string;
  public cellPhone: string;
  public normalSchedule: string;
  public extendedSchedule: string;
  public latitude: number;
  public longitude: number;
}
