import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FolderMenu } from '../folders-browser/folder-menu.enum';

@Component({
  selector: 'app-folder-browser-item',
  templateUrl: './folder-browser-item.component.html',
  styleUrls: ['./folder-browser-item.component.scss']
})
export class  FolderBrowserItemComponent {
  @Input() data: ItemTree;
  @Input() selected: any;
  @Output() children = new EventEmitter();
  @Output() menu = new EventEmitter();
  folderMenu = FolderMenu;

  constructor() {}

  menuAction(action: FolderMenu, id: string) {
    this.menu.emit({ id: id, action: action });
  }

  openChildren(id, selected) {
    if (selected !== id) {
      this.children.emit(id);
    }
  }
}

interface ItemTree {
  id: string;
  name: string;
  status: number;
  agent: Agent;
  statusEvent: number;
}
interface Agent {
  nombreCompleto: string;
}
