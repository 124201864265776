import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material';
import { SessionBlockAlertComponent } from '../components/session-block-alert/session-block-alert.component';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { TenantSettingsService } from './tenant-settings.service';

enum Constants {
  BLOCK_DIALOG_ID = 'session-block-dialog',
  LAST_TOUCH_TIME_KEY = 'last-touch'
}

@Injectable({
  providedIn: 'root'
})

export class IdleUserService {

  private timeOut = 30; //This value must be get by configuration , for now 10 minutes
  
  constructor(private readonly _dialog: MatDialog,
              private readonly _router: Router,
              private readonly _auth:AuthService,
              private readonly _setting:TenantSettingsService) {
  }
  
  //Start handle to mousedown and keydown
  async start(){
    
    if(!this._auth.isAuthenticated || this._router.url.includes('login')){ //If user is un-auth or current page is <</login>> is not necesary start service 

      return;
    }
    //If env is not defined the default value must be 15 
    this._setting.loadSettingInactivityTimeout().subscribe(
      (inactivityTimeout)=>{

        if(inactivityTimeout){ 

          this.timeOut = inactivityTimeout;
        }
    
        fromEvent(document, 'mousedown').subscribe((e)=>this.checkTimeout(e));
    
        fromEvent(document, 'keydown').subscribe((e)=>this.checkTimeout(e));  
      }
    ); 
  }
  
  reset(){

    localStorage.setItem(Constants.LAST_TOUCH_TIME_KEY,null);
  }

  //**Set touch date and current auth session token */
  private setStorage(date: Date){
    
    localStorage.setItem(Constants.LAST_TOUCH_TIME_KEY,date.toString());
  }
  
  /**Get last touch date if session auth token if not changed */
  private async getStorage(){

    const dateStorage = localStorage.getItem(Constants.LAST_TOUCH_TIME_KEY);
  
    if(dateStorage){

      return new Date(dateStorage);
    }
    return null;
  }

  /**Check inactive session time */
  public async checkTimeout(e:Event){

    const now = new Date();
    const lastTouchTime = await this.getStorage();
    
    if(lastTouchTime){

      const diferenceMinutes = (Math.abs(lastTouchTime.getTime() - now.getTime()) / (1000 * 60)) % 60;     
     
      if(diferenceMinutes > this.timeOut){

        this.showBlockDialog(e);
        return;
      }
    }
    this.setStorage(now);
  }
  
  /**Show a modal with block message and button to re-login */
  private showBlockDialog(e:Event){
  
    //The control is only out of login route
    if(!this._router.url.includes('login')){

      e.preventDefault();
      const currentDialog = this._dialog.getDialogById(Constants.BLOCK_DIALOG_ID);
      if(!currentDialog){ //To avoid overload modals
  
        this._dialog.open(SessionBlockAlertComponent,{
          id: Constants.BLOCK_DIALOG_ID,
          width: '515px',
          height: '245px',
          disableClose: true,
          backdropClass: 'session-block-backdrop',
          hasBackdrop:true
        });
      }
    }
  }
}
