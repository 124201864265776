import { FieldCommentStatus, InstanceContainerStatus, TypeOfDataType } from '../enums';
import { DtoDataType } from './Definition/DtoDataType';

export class Instance {
  instanceId: string;
  agentId: string;
  values: InstanceValues | any;
  status: number;
  summaryDetail: any;
  isOwner: boolean;
}

export class InstanceValues {
  schemaTitle: string;
  headerValues: string[];
  structure: StructureContainer;
  dataTypes: DataTypeInInstacenValue[] | { [prop: string]: DtoDataType };
  containers: InstanceContainer[];
  documentId: string;
  prefix: string;
  suffix: string;
  uid: string;
  initialDate: string;
}

export class DataTypeInInstacenValue {
  keyName: string;
  version: string;
  type: TypeOfDataType;
  description: string;
  validators: ValidatorOfDataType[];
  selection: SelectionOfDataType[];
  baseKeyName: string;
  customDataSource: boolean;
}

export class ValidatorOfDataType {
  keyName: string;
  expression: string;
  message: string;
}

export class SelectionOfDataType {
  name: string;
  description: string;
  catalog: string;
  parent: string;
}

export class StructureContainer {
  containerId: string;
  containers: StructureContainer[];
}

export class InstanceContainerBase {
  containerId: string;
  status: InstanceContainerStatus;
}

export class InstanceContainer extends InstanceContainerBase {
  parentId: string;
  title: string;
  description: string;
  data: InstanceContainerData;
  customStatus: number;
  customStatusDescription: string[];
  dataFront: string;
  elements?: InstanceContainer[];
  assignedAgent?: AssignedAgent;
  values?: any;
}

export class AssignedAgent {
  agentId: string;
}

export class FieldComment {
  instanceId: string;
  containerId: string;
  field: string;
  keyName: string;
  description: string;
  agent: FieldCommentAgent;
  status: FieldCommentStatus;
}

export class FieldCommentAgent {
  agentId: string;
  name: string;
  email: string;
  jobName: string;
}

export class InstanceContainerData {
  validation: FieldComment[];
  autosave: any;
  values: any;
  comment: any;
  comments: Array<any>;
}
