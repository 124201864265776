import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BreadcrumbTitle } from '../../models/breadcrumb-title';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-custom-breadcrum-title',
  templateUrl: './custom-breadcrum-title.component.html',
  styleUrls: ['./custom-breadcrum-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomBreadcrumTitleComponent implements OnInit {
  private _paths: BreadcrumbTitle[] = [];

  @Input() set paths(paths: BreadcrumbTitle[]) {
    this._verify(JSON.parse(JSON.stringify(paths)));
    this._paths = paths;
  }

  get paths() {
    return this._paths;
  }

  constructor(private readonly _router: Router, private readonly _location: LocationStrategy) {
  }

  ngOnInit() {
  }

  /**
   * Opens the link specified in the clicked path.
   * @param url Url from path
   */
  openLink(url: string): void {
    if (url === 'HOME') {
      this._router.navigate(['/']);
    } else if (url === 'BACK') {
      this._location.back();
    } else if (url.trim()) {
      this._router.navigate([url]);
    }
  }

  /**
   * Verifies that the paths fulfill a correct syntax.
   * @param paths route paths
   * @private
   */
  private _verify(paths: BreadcrumbTitle[]): void {
    if (paths[paths.length - 1].url.trim()) throw new Error('La ultima ruta no debe tener una url.');
    paths.length = paths.length - 1;
    const areValidUrls = paths.every(path => !!path.url.trim());
    if (!areValidUrls) throw new Error('Alguna de las URLs no cuentan con una URL valida.');

  }
}
