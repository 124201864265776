import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericRow, RowType } from '../folder-finder/folder-finder.utils';

@Component({
  selector: 'scc-generic-row',
  template: `
    <div row-container (click)="rowClicked.emit(row)">
      <section *ngIf="hasRadio">
        <mat-radio-button (change)="radioSelected.emit(row)" [checked]="row.isChecked">
        </mat-radio-button>
      </section>
      <section row-info>
        <mat-icon>{{ getRowIcon(row.type) }}</mat-icon>
        <span>{{ row.title }}</span>
        <span *ngIf="row.text1"><span>|</span>{{ row.text1 }}</span>
        <span *ngIf="row.text2"><span>|</span>{{ row.text2 }}</span>
        <span *ngIf="row.text3"><span>|</span>{{ row.text3 }}</span>
      </section>
      <section row-path>
        {{row.text4}}
      </section>
      <section>
        {{row.text5}}
      </section>
    </div>
  `,
  styleUrls: ['generic-row.component.scss']
})
export class GenericRowComponent {

  @Input() row: GenericRow;
  @Input() hasRadio = false;
  @Output() radioSelected = new EventEmitter<GenericRow>();
  @Output() rowClicked = new EventEmitter<GenericRow>();

  getRowIcon(rowType: number): string {
    let icon: string;
    switch (rowType) {
      case RowType.FOLDER: {
        icon = 'folder_shared';
        break;
      }
      case RowType.MAILBOX: {
        icon = 'markunread_mailbox';
        break;
      }
      case RowType.PRODUCT: {
        icon = 'insert_drive_file';
        break;
      }
      default: {
        icon = 'folder_shared';
        break;
      }
    }
    return icon;
  }
}

