import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  isIconSearch: boolean;
  @Input()
  isIconFilter: boolean;
  @Output()
  clickedFilterIcon = new EventEmitter<Boolean>();

  @Output() clickedSearchBtn = new EventEmitter<Boolean>();
  isShowSearch = false;
  constructor() {
    this.title = '';
    this.isIconSearch = false;
    this.isIconFilter = false;
  }

  ngOnInit() {}
  onClickSearch() {
    this.isShowSearch = !this.isShowSearch;
    this.clickedSearchBtn.emit(this.isShowSearch);
  }
  clickFilterIcon(value: boolean) {
    this.clickedFilterIcon.emit(!value);
  }
}
