import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: string[], args: string): string[] {
    const resultFilter = [];
    const arg = args.toLowerCase();
    if (value && value.length) {
      for (const item of value) {
        if (
          (item['name'])  && (item['name']).toLowerCase().indexOf(arg) > -1 ||
          (item['description'])  && item['description'].toLowerCase().indexOf(arg) > -1 ||
          (item['functionName']) && item['functionName'].toLowerCase().indexOf(arg) > -1 ||
          (item['parentKey'])  && item['parentKey'].toLowerCase().indexOf(arg) > -1  ||
          (item['key']) && item['key'].toLowerCase().indexOf(arg) > -1 ||
          (item['value'])  && item['value'].toLowerCase().indexOf(arg) > -1 ||
          (item['tags'])  && JSON.stringify(item['tags']).toLowerCase().indexOf(arg) > 1
         ) {
             resultFilter.push(item);
        }
      }
      return resultFilter;
    }
  }
}
