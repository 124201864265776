import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Self, ViewChild } from '@angular/core';
import { NotificationsService } from '../../../../shared/services/notifications.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EngineNotification } from '../../../../shared/models/notification/notification.interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  @Output() onClose = new EventEmitter<void>();

  public notificationsList$: Observable<EngineNotification[]>;

  public notificationsCounter$: Observable<number>;

  private readonly _subscriptionKiller = new Subject();

  constructor(private readonly _notificationSrv: NotificationsService) {

    this.notificationsList$ = _notificationSrv.notifications$.pipe(map(notifications => notifications.filter(n => !n.isRead)));
    this.notificationsCounter$ = this.notificationsList$.pipe(map(list => list.length));
  }

  ngOnInit() {

    
    this._notificationSrv.toggleNotification$.pipe(takeUntil(this._subscriptionKiller)).subscribe(state => {
      if (state) this._notificationSrv.getAll();
    });
  }

  onOpened(){

    if(this.cdkVirtualScrollViewport){ //If current user not has notifications the virtual scroll not exists

      this.cdkVirtualScrollViewport.checkViewportSize();
    }
  }

  ngOnDestroy() {

    this._subscriptionKiller.next();
    this._subscriptionKiller.complete();
  }

  /**
   * Toggles the notification sidebar and resets the icon badge of notifications.
   */
  public closeView(): void {
    
    this.onClose.emit(); 
  }

  /**
   * Marks a notification as read.
   * @param id Notification id.
   */
  public markAsRead(id: string): void {

    this._notificationSrv.markAsRead(id);
  }

  /**
   * MArks all notifications as read.
   */
  public markAllAsRead(): void {

    this._notificationSrv.markAllAsRead();
  }

  get notDisturbMode(){

    return this._notificationSrv.previewOption == false;
  }

  set notDisturbMode(value){

    this._notificationSrv.previewOption = !value;
  }
}
