import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer-main-card-buttons',
  template: `
    <div class="row-footer">
      <scc-custom-button
        *ngIf="this.numberOfButtons === 3" kind="confirm" type="submit" (customClick)="button3Clicked.emit()" [disabled]="button3Disabled">
        {{ button3Text }}
      </scc-custom-button>
      <scc-custom-button [kind]="kindButton2" type="submit" (customClick)="button2Clicked.emit()" [disabled]="button2Disabled">
        {{ button2Text }}
      </scc-custom-button>
      <scc-custom-button kind="cancel" (customClick)="button1Clicked.emit()" [disabled]="button1Disabled">
        {{button1Text}}
      </scc-custom-button>
    </div>
  `,
  styleUrls: ['footer-main-card-buttons.component.scss']
})
export class FooterMainCardButtonsComponent implements OnInit {
  @Input() button1Text = 'CANCELAR';
  @Input() button2Text = 'GUARDAR';
  @Input() button3Text = 'GUARDAR Y CERRAR';
  @Input() button1Disabled = false;
  @Input() button2Disabled = false;
  @Input() button3Disabled = false;
  @Input() numberOfButtons = 2;

  @Output() button1Clicked = new EventEmitter<any>();
  @Output() button2Clicked = new EventEmitter<any>();
  @Output() button3Clicked = new EventEmitter<any>();
  kindButton2 = 'confirm';

  ngOnInit(): void {
    if (this.numberOfButtons === 3) this.kindButton2 = 'alternate';
  }
}
