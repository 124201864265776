import { MonitorService } from './../services/monitor.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

/**
 * Handler to manage the errors in the application.
 */
@Injectable()
export class ApplicationInsightsErrorHandler extends ErrorHandler {
    
    /**
     * We need injector to get the monitor service to write the error to the app insights.
     * 
     * @param injector injector to get the service
     */
    constructor(private injector: Injector) {

        super();
    }

    /**
     * Override the handleError method to log the error to the app insights.
     * 
     * @param error error to log
     * 
     * @returns {void}
     * 
     * @override
     */
    handleError(error: any): void {

        this.injector.get<MonitorService>(MonitorService).logException(error);

        super.handleError(error);
    }
}