import { Directive, ElementRef, Renderer2, Input, HostBinding, OnInit } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';

// #region Constants

const STYLE_DISPLAY_NONE = 'none';
const STYLE_DISPLAY_BLOCK = 'block';
const HOST_STYLE = 'style.display';

// #endregion

@Directive({ selector: '[featureFlag]' })
export class FeatureFlagDirective implements OnInit {

  @Input() flag: string;

  @Input() expectedValue: boolean;

  @HostBinding(HOST_STYLE) display = STYLE_DISPLAY_NONE;

  constructor(private featureFlag: FeatureFlagService) { }
 
  ngOnInit() {

    this.featureFlag.getFlagObservable(this.flag).subscribe((flagValue) => {

      if (flagValue == this.expectedValue) {

        this.display = STYLE_DISPLAY_BLOCK;
      }
      else {

        this.display = STYLE_DISPLAY_NONE;
      }
    });
  }
}
