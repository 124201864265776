import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth-service.service";
import { EnvService } from "../services/env.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _auth: AuthService,
              private readonly router: Router,
              private readonly _env:EnvService ) {}

  canActivate() {
   
    if (this._auth.isAuthenticated) {

      return true;
    } else {
      
      this.router.navigateByUrl(this._env.OFFICE365.REDIRECT);
      return false;
    }
  }
}