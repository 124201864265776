import { EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomTableComponent } from '../../components/custom-table/custom-table.component';
import { QueryableService } from '../services/QueryableService';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';

/**
 * Clase base abstracta de catalogos sin filtrado de datos.
 */
export abstract class BaseCatalog<T> implements OnInit, OnDestroy {
  @ViewChild(CustomTableComponent) tableComponent: CustomTableComponent;
  catalogTransform: () => Array<any>;
  catalogLoaded = new EventEmitter<void>();
  protected _activatedRoute: ActivatedRoute;
  protected _router: Router;

  protected constructor(private _serviceCatalog: QueryableService) {
  }

  private _catalog = new Array<T>();

  get catalog() {
    return this._catalog;
  }

  set catalog(values: Array<T>) {
    this._catalog = values;
    this.tableComponent.source = this._catalog;
  }

  ngOnInit(): void {
    this.getCatalog();
  }

  ngOnDestroy() {
  }

  /**
   * Función que obtiene los registros del catalogo correspondientes a su servicio.
   */
  getCatalog(data: BaseServiceParameter = {}): void {
    let methodRef: Observable<any>;
    if (data) {
      methodRef = data.callback ? data.callback() : this._serviceCatalog.get(data.value);
    } else {
      methodRef = this._serviceCatalog.get();
    }
    methodRef.subscribe({
      next: res => {
        this.catalog = res;
        if (this.catalogTransform) {
          this.catalog = this.catalogTransform();
          this.tableComponent.source = this.catalog;
        }
      }, complete: () => {
        this.catalogLoaded.emit();
      }
    });
  }

  allowPaginationState(router: Router, activatedRoute: ActivatedRoute): void {
    if (!router || !activatedRoute) {
      throw new Error('Los parametros son obligatorios.');
    }
    this._router = router;
    this._activatedRoute = activatedRoute;
    this._updatePaginationTable();
  }

  savePage(extras: any = {}): void {
    this._router.navigate([], {
      queryParams: { tablePage: this.tableComponent.dataSource.paginator.pageIndex, ...extras },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      relativeTo: this._activatedRoute
    }).then();
  }

  protected _updatePaginationTable(): void {
    this.catalogLoaded.pipe(
      switchMap(() => this._activatedRoute.queryParams.pipe(
        map(params => params.tablePage && +params.tablePage || 0)
      )),
      take(1)
    ).subscribe({
      next: tablePage => {
        this.tableComponent.goToPage(tablePage);
      }
    });
  }
}

export class BaseServiceParameter {
  value?: any;
  callback?: () => Observable<any>;
}
