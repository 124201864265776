import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-card-icons',
  styleUrls: [ 'custom-side-card-icons.component.scss' ],
  template: `
      <mat-card>
          <mat-card-content>
              <mat-toolbar-row style="padding: 0">
                  <button mat-icon-button (click)="buttonFunction()">
                      <mat-icon style="color: #78849E">
                          {{icon}}
                      </mat-icon>
                  </button>
                  <span style="font-size: 14px">{{title}}</span>
                  <span class="flex-fill"></span>
              </mat-toolbar-row>
              <ng-content></ng-content>
          </mat-card-content>
      </mat-card>
  `
})
export class CustomSideCardIconsComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() function: any;

  buttonFunction() {
    if (this.function) {
      this.function();
    } else {
      return;
    }
  }


}
