import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { SubRequestForm } from 'src/app/shared/models';
import { GlobalService } from 'src/app/shared/services/global.service';

@Injectable()
export class FormService {
  private formFields;

  private fieldsValues;

  private formValid: boolean;

  private formValidity: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private formSubscriptions;

  constructor(
    private functionsService: FunctionsService,
    private globalSvc: GlobalService
  ) {
  }

  setFormFields(requestForm) {
    const fields = {};
    this.getElementsFromForm(requestForm, fields);
    this.formFields = fields;
    this.formValid = true;
    this.formValidity.next(this.formValid);
    this.formSubscriptions = null;
  }

  addFormFields(subRequestForm: SubRequestForm) {
    const fields = {};
    let currentFieldsValidity = true;
    this.getElementsFromForm(subRequestForm, fields);
    for (const field in fields) {
      if (fields[field].required) {
        fields[field].valid = !!this.getFieldValue(field);
      }
    }
    currentFieldsValidity = this.getValidity(fields);
    this.formValid = this.formValid && currentFieldsValidity;
    this.formValidity.next(this.formValid);
    this.functionsService.extend(this.formFields, fields);
    subRequestForm.isValid = currentFieldsValidity;
  }

  deleteFormFields(subRequestForm: SubRequestForm) {
    if (this.formFields) {
      const fields = {};
      this.getElementsFromForm(subRequestForm, fields);
      for (const field in fields) {
        delete this.formFields[field];
      }
    }
  }

  public getFieldValue(fullName) {
    let result = null;
    if (fullName && this.fieldsValues) {
      const selectedReducedContainer = this.globalSvc.getReducedContainer().getValue();
      result = this.fieldsValues[fullName];

      if (!result && selectedReducedContainer && selectedReducedContainer.externals) {
        result = selectedReducedContainer.externals[fullName];
      }
    }

    return result;
  }

  public initfieldValues() {
    this.fieldsValues = {};
    const selectedContainer = this.globalSvc.getContainer().getValue();

    if (selectedContainer && selectedContainer.data) {
      this.reduceObjectEx(
        selectedContainer.data.autosave,
        null,
        this.fieldsValues
      );
    }
  }

  reduceObjectEx(container, parent, dictionary) {
    let result = null;
    if (this.isObject(container)) {
      result = {};
      for (const key in container) {
        result[key] = null;
        if (this.isObject(container[key]) && container[key] != null) {
          let fullName = key;
          if (parent) {
            fullName = parent + '.' + fullName;
          }
          if (container[key].hasOwnProperty('components')) {
            result[key] = this.reduceObjectEx(container[key]['components'], fullName, dictionary);
          } else if (container[key].hasOwnProperty('value')) {
            result[key] = container[key]['value'];
            dictionary[fullName] = container[key]['value'];
          } else if (container[key].hasOwnProperty('selection')) {
            let value = null;
            if (Array.isArray(container[key]['selection'])) {
              const selection = container[key]['selection'];
              const selectionLength = selection.length;
              value = [];
              for (let i = 0; i < selectionLength; i++) {
                if (selection[i].hasOwnProperty('key')) {
                  value.push(selection[i]['key']);
                } else {
                  break;
                }
              }
            }
            result[key] = value;
            dictionary[fullName] = value;
          }
        }
      }
    }
    return result;
  }

  isObject(value) {
    return (typeof value).toLowerCase() === 'object';
  }

  private getElementsFromForm(struct, result) {
    if (struct) {
      if (struct.hasOwnProperty('sections')) {
        const sections = struct['sections'];
        for (let h = sections.length - 1; h >= 0; h--) {
          if (sections[h].hasOwnProperty('rows')) {
            const rows = sections[h]['rows'];
            for (let i = rows.length - 1; i >= 0; i--) {
              this.getElementsFromForm(rows[i], result);
            }
          }
        }
      } else if (struct.hasOwnProperty('elements')) {
        const elements = struct['elements'];
        for (let i = elements.length - 1; i >= 0; i--) {
          this.getElementsFromForm(elements[i], result);
        }
      } else {
        result[struct.fullName] = { rejected: false, valid: true, required: struct.isRequired };
      }
    }
  }

  private getValidity(formFields) {
    return Object.keys(formFields).reduce((acc, key) => {
      return acc && formFields[key].valid && !formFields[key].rejected;
    }, true);
  }
}
