export enum ColumnFormat {
  NONE = 0,

  //#region Values to transform only numbers, start from 200 onwards
  AGE_IN_YEARS = 200,
  SALARY_WITH_TWO_DECIMALES = 201,
  SALARY_WITH_NO_DECIMALES = 202,
  //#endregion

  //#region Values to transform only booleans, start from 400 onwards
  MAN_OR_WOMAN = 400,
  MALE_OR_FEMALE = 401,
  //#endregion

  //#region Values to transform only dates, start from 600 onwards
  D_MMM_YYYY = 600,
  //#endregion

  //#region Values to transform only dates, start from 800 onwards
  JOIN_BY_COMA = 800
  //#endregion
}
