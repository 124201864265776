import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsightsLayout } from './insights.layout';
import { InsightsListComponent } from './pages/insights-list/insights-list.component';

const ROUTES: Routes = [
  {
    path: '',
    component: InsightsLayout,
    children: [
      {
        path: '',
        component: InsightsListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class InsightsRoutingModule { }
