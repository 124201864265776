export class SidebarMenu {
    id: string;
    name: string;
    requisitos: Requisito[];
}

export class Requisito {
    id: string;
    name: string;
    subRequisitos: SubRequisito[];
}

export class SubRequisito {
    id: string;
    name: string;
}
