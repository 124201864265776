import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-administration-navbar',
  template: `
    <mat-toolbar>
      <mat-toolbar-row [@openCloseBaseNav]="!searchMechanism ? 'open' : 'close'">
        <div class="row no-gutters w-100">
          <div class="col-sm-3 align-self-center">
            <ng-content select="app-navbar-administration-title"></ng-content>
          </div>
          <div class="controls col-sm-4">
            <ng-content select=".controls"></ng-content>
          </div>
          <div class="icons col-sm-5" style="text-align: end">
            <ng-content select=".extra"></ng-content>
            <div>
              <mat-divider [vertical]="true"></mat-divider>
            </div>
            <button mat-icon-button (click)="openSearchEvent()">
              <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button *ngIf="advanceSearch" (click)="advanceSearchClick.emit()">
              <mat-icon>filter_list</mat-icon>
            </button>
            <button mat-icon-button *ngIf="advanceSearch" (click)="openColumnsSelectionModal.emit()">
              <mat-icon>view_column</mat-icon>
            </button>
            <button mat-icon-button *ngIf="requestsSearch" (click)="refreshSearch.emit()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row [@openCloseSearchBox]="searchMechanism ? 'open' : 'close'">
        <ng-content></ng-content>
      </mat-toolbar-row>
    </mat-toolbar>
  `,
  styleUrls: ['administration-navbar.component.scss'],
  animations: [
    trigger('openCloseSearchBox', [
      state('open', style({
        position: 'absolute',
        top: '0',
        opacity: '1'
      })),
      state('close', style({
        position: 'absolute',
        top: '-100%',
        opacity: '0'
      })),
      transition('open => close', [animate('0.2s')]),
      transition('close => open', [animate('0.1s')])
    ]),
    trigger('openCloseBaseNav', [
      state('open', style({
        position: 'absolute',
        left: '0',
        opacity: '1'
      })),
      state('close', style({
        position: 'absolute',
        opacity: '0'
      })),
      transition('open => close', [animate('0.2s')]),
      transition('close => open', [animate('0.1s')])
    ])
  ]
})

export class AdministrationNavbarComponent implements OnInit {
  searchMechanism = false;
  @Input() advanceSearch = false;
  @Input() requestsSearch = false;
  @Output() openSearch = new EventEmitter<boolean>(false);
  @Output() advanceSearchClick = new EventEmitter<any>();
  @Output() openColumnsSelectionModal = new EventEmitter<any>();
  @Output() refreshSearch = new EventEmitter<any>();

  constructor(private _dialog: MatDialog) {
  }

  ngOnInit() {
  }

  openSearchEvent() {
    this.searchMechanism = !this.searchMechanism;
    this.openSearch.emit(this.searchMechanism);
  }
}
