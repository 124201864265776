import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base-service.service';

const ERROR_REPORT_FILTERS = 'Error al cargar los filtros de los reportes';

interface TenantSettingsLoaded {

  inactivityTimeOut: boolean,
  charts: boolean,
  reports: boolean
}

interface SettingInactivityTimeoutResponse {
  inactivityTimeout: number;
}

interface SettingChartsResponse {
  baseUrl: string;
  solicitudeLifeCycle: string;
  dashboardCharts: string[];
}

interface SettingReportsResponse {
  name: string;
  label: string;
  type: string;
  catalog: string;
}

enum TenantSettingsConst {
  inactivityTimeoutFunction = 'settings:inactivityTimeout',
  chartsFunction = 'settings:charts',
  reportsFunction = 'settings:reportsFilters'
}

@Injectable({
  providedIn: 'root'
})
export class TenantSettingsService extends BaseService {

  private currentSettingsLoaded: TenantSettingsLoaded = {
    inactivityTimeOut: false,
    charts: false,
    reports: false
  };

  baseUrl = `${this._env.APIS.TENANTS}/organizations/${this._env.APIS.TENANTID}`;

  /**
   * Setting request
   * @returns Observable<Object>
   */
  private settingRequest(customMethod: string): Observable<Object> {

    return this._http.get(`${this.baseUrl}/${customMethod}`);
  }

  /**
   * Load Inactivity Timeout Settings
   * @returns Observer<number>
   */
  loadSettingInactivityTimeout(): Observable<number> {

    if (this.currentSettingsLoaded.inactivityTimeOut) {

      return of(this._env.SESSION_EXPIRE_TIMEOUT);
    }

    return this.settingRequest(TenantSettingsConst.inactivityTimeoutFunction).pipe(
      switchMap(
        (response: SettingInactivityTimeoutResponse) => {

          this.currentSettingsLoaded.inactivityTimeOut = true;
          this._env.SESSION_EXPIRE_TIMEOUT = response.inactivityTimeout;
          return of(this._env.SESSION_EXPIRE_TIMEOUT);
        }
      ),
      catchError((err) => {
        //Ignore error and return a default env value
        return of(this._env.SESSION_EXPIRE_TIMEOUT);
      })
    );
  }

  /**
   * Load Chart Settings
   * @returns Observer<any>
   */
  loadSettingsCharts(): Observable<any> {

    if (this.currentSettingsLoaded.charts) {

      return of(this._env.CHARTS);
    }

    return this.settingRequest(TenantSettingsConst.chartsFunction).pipe(
      switchMap(
        (response: SettingChartsResponse) => {

          this.currentSettingsLoaded.charts = true;
          this._env.CHARTS = {
            BASE_URL: response.baseUrl,
            SOLICITUDE_LIFE_CYCLE: response.solicitudeLifeCycle,
            DASHBOARD_CHARTS: response.dashboardCharts
          };

          return of(this._env.CHARTS);
        }
      ),
      catchError((err) => {

        this._env.CHARTS = {
          BASE_URL: window['__env'].CHARTS.BASE_URL,
          SOLICITUDE_LIFE_CYCLE: window['__env'].CHARTS.SOLICITUDE_LIFE_CYCLE.ID,
          DASHBOARD_CHARTS: (window['__env'].CHARTS.ALL || []).map(chart => chart.ID)
        };

        return of(this._env.CHARTS);
      })
    );
  }

  /**
   * Force close session
   * @returns Observer<any>
   */
  forceCloseSession(agentId: string): Observable<any> {

    return this._http.post(`${this.baseUrl}/agents/${agentId}:closeSessions`, {});
  }

  /**
   * Load Reports Settings
   * 
   * @returns Observer<any>
   * 
   * The reports settings are loaded only once
   * the settings are get from the request and setted in the environment
   * if the request fails, the default values are loaded
   * if the settings are already loaded, the current values are returned
  */
  loadSettingsReports(): Observable<any> {

    let result = of(this._env.REPORTS.FILTERS);

    if (!this.currentSettingsLoaded.reports) {

      result = this.settingRequest(TenantSettingsConst.reportsFunction).pipe(
        switchMap(
          (response: SettingReportsResponse[]) => {
  
            this.currentSettingsLoaded.reports = true;
            this._env.REPORTS.FILTERS = response;
  
            return of(this._env.REPORTS.FILTERS);
          }
        ),
        catchError((err) => {
  
          this._env.REPORTS.FILTERS = window['__env'].REPORTS.FILTERS;

          this._alerts.error(ERROR_REPORT_FILTERS);
  
          return of(this._env.REPORTS.FILTERS);
        })
      );
    }

    return result;
  }
}
