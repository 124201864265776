import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { EnvService } from 'src/app/core/services/env.service';

export enum TelemeteryNotification{
  UserLogin = 'agent-web:agent_login',
  UserLogout = 'agent-web:agent_logout'
};

enum Constants {
  TenantId = 'tenantId'
}

/**Service to handle the front interaction with Azure Application Insigth */
@Injectable({
    providedIn: 'root'
})  
export class MonitorService {

  appInsights: ApplicationInsights;
  isStarted: boolean = false;

  constructor(private readonly _auth:AuthService,
              private readonly _env: EnvService) {}
  
  //This method must be called after enviroment loaded, to get the instrumentationKey value
  start(){

    if(this._env.INSTRUMENTATION_KEY){ //To avoid issues if InstrumentationKey is not defined 

      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this._env.INSTRUMENTATION_KEY,
          enableAutoRouteTracking: this._env.ENABLE_AUTO_ROUTE_TRACKING
        }
      });

      this.appInsights.loadAppInsights();
      this.isStarted = true;
    }
  }

  private setCurrentAuthContext(){

    if(this.isStarted){

      this.appInsights.setAuthenticatedUserContext(this._auth.LoggedInUserEmail);
    }
  }

  logPageView(name?: string, url?: string) { // option to call manually
    
    if(this.isStarted){

      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    
    if(this.isStarted){

      properties[Constants.TenantId] = this._env.APIS.TENANTID;
      this.setCurrentAuthContext();
      this.appInsights.trackEvent({ name: name}, properties);
    }
  }

  logMetric(name: TelemeteryNotification, average: number, properties?: { [key: string]: any }) {

    if(this.isStarted){

      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {

    if(this.isStarted){

      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {

    if(this.isStarted){

      this.appInsights.trackTrace({ message: message}, properties);    
    }
  }
}