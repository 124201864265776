import { Injectable } from '@angular/core';
import { Upload } from '../models/upload';
import { BlobService } from './blob.service';
import { Observable } from 'rxjs';
import { IUploadService } from '../bases/services/IUploadService';

@Injectable({
  providedIn: 'root'
})
export class FormUploadService implements IUploadService {

  constructor(private blobService: BlobService) {
  }

  deleteFile(fullname: string, selectedInstance: any, selectedContainer: any) {
    return this.blobService
      .deleteFileInBlob(selectedInstance.instanceId.toUpperCase(), selectedContainer.containerId, fullname);
  }

  push(upload: Upload, ...additionalArgs: any[]): Observable<any> {
    let selectedInstanceTmp: string;
    let selectedContainerTmp: string;

    if (additionalArgs.length && additionalArgs[0].selectedInstance) {
      selectedInstanceTmp = additionalArgs[0].selectedInstance.instanceId;
      selectedContainerTmp =additionalArgs[0].selectedContainer.containerId;
    }

    selectedInstanceTmp = !selectedInstanceTmp ? upload.userId : selectedInstanceTmp.toUpperCase();
    selectedContainerTmp = !selectedContainerTmp ? upload.userId : selectedContainerTmp;

    return this.blobService
      .postFileInBlob(selectedInstanceTmp, selectedContainerTmp, upload.name, upload.fullname,
        upload.base64);
  }

  getBase64(fileUrl: string) {
    return this.blobService.getBase64(fileUrl);
  }

  getBlob(fileUrl: string) {
    return this.blobService.getBlob(fileUrl);
  }
}
