import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked/marked.min.js';

@Pipe({
  name: 'markdown'
})

export class MarkdownPipe implements PipeTransform {

  constructor() {}

  transform(text: string): any {

    const markdownContent = marked.parse(text);
    return  markdownContent;
  }
}
