import { EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { ElementsForm } from '../../../shared/models';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormAligns } from '../enums/form-aligns.enum';
import { FeatureFlagEnum } from 'src/app/shared/enums/featureFlag.enum';

export abstract class FormBaseElement implements OnDestroy {
  @Input()
  element: ElementsForm;
  @Input()
  control: AbstractControl;
  @Input()
  readonly: boolean;
  @Input()
  align: number;
  @Input()
  validation: TemplateRef<any>;
  @Input()
  comment: TemplateRef<any>;
  @Input()
  customError: string;

  @Output() onLostFocus = new EventEmitter(true);

  public featureFlagEnum = FeatureFlagEnum;

  public readonly formAlign = FormAligns;
  protected destroy = new Subject<any>();

  get canShowHelper() {
    return this.control.errors && Object.keys(this.control.errors).length > 1 && !!this.element.helperText || !!this.element.helperText;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  showHelper(errors: ValidationErrors) {
    return !errors || errors.required && Object.keys(errors).length;
  }

  lostFocus(event){

    this.onLostFocus.emit(event);
  }
}
