import { Injectable } from '@angular/core';
import { BaseService } from '../../../core/services/base-service.service';
import { Observable } from 'rxjs';
import { APP_SETTINGS } from '../../../configs/app-settings.config';
import { DtoDefinition } from '../../../shared/models/Definition/DtoDefinitionEngine';
import { QueryableService } from '../../../shared/bases/services/QueryableService';
import { DtoLinearFlowTable, DtoLinearFlowUpdate } from '../../../shared/models/Definition/DtoContainer';
import { DtoProductList } from '../../../shared/models/DtoProductList';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProductsService extends BaseService implements QueryableService {
  private endpoint = 'Definition';

  add(data: DtoDefinition): Observable<any> {
    return super.post(this.endpoint, data, { loaderName: 'new_product_loader' });
  }

  update(data: DtoDefinition, config: any): Observable<any> {
    return super.put(this.endpoint, data, config).pipe(tap(response => {
      if (response.status === 3) {
        this._alerts.error('Ocurrió un error al actualizar el proceso de negocio.');
      }
    }));
  }

  get(filter?: any): Observable<any> {
    return undefined;
  }

  post(defaultLoader?: string): Observable<DtoProductList[]> {
    const endpoint = `${ APP_SETTINGS.GATHER_API.CATALOGS.PRODUCTS }`;
    return super.post(endpoint, null, { baseUrl: window['__env'].APIS.QUERYABLE, loaderName: defaultLoader, loader: true });
  }

  getProduct(id, version): Observable<any> {
    return super.get(`${ this.endpoint }/${ id }/${ version }`, { loader: true });
  }

  getInitialStatus(): Observable<any> {
    return super.get(`catalogs/initialStatus/`);
  }

  getTemplateDefinition(): Observable<any> {
    return super.post('Query/5AF21E61-5BBB-41C7-8522-789ADBCFE211', {
      'parameters': {
        '@@TenantId': window['__env'].APIS.TENANTID
      }
    }, { baseUrl: window['__env'].APIS.QUERYABLE, loaderName: 'new_product_loader' });
  }

  validateUniqueKey(prefix: string): Observable<any> {
    return super.post(`${ APP_SETTINGS.UNIQUE_KEY }`, {
      'parameters': {
        '@@TenantId': window['__env'].APIS.TENANTID,
        '@@prefix': prefix,
        '@@suffix': ''
      }
    }, { baseUrl: `${ window['__env'].APIS.QUERYABLE }/Query` });
  }

  hasOriginations(schemaId: string, version: string): Observable<any> {
    return super.post(`${ APP_SETTINGS.HAS_ORIGINATIONS }`, {
      'parameters': {
        '@@SchemaId': schemaId,
        '@@Version': version
      }
    }, { baseUrl: `${ window['__env'].APIS.QUERYABLE }/Query` });
  }

  getChannels(): Observable<any> {
    return super.post(`${ APP_SETTINGS.CHANNELS }`, {
      'parameters': {
        '@@TenantId': window['__env'].APIS.TENANTID
      }
    }, { baseUrl: `${ window['__env'].APIS.QUERYABLE }/Query` });
  }

  createLinearFlow(linearFlow: DtoLinearFlowTable): Observable<any> {
    return super.post(`${ APP_SETTINGS.GATHER_API.CATALOGS.LINEAR_FLOW }`, linearFlow, { loader: true });
  }

  updateLinearFlow(linearFlowUpdate: DtoLinearFlowUpdate, id: string): Observable<any> {
    return super.put(`${ APP_SETTINGS.GATHER_API.CATALOGS.UPDATE_LINEAR_FLOW }${ id }`, linearFlowUpdate, { loader: true });
  }

  getLinearFlowById(id: string, version: string): Observable<any> {
    return super.get(`${ APP_SETTINGS.GATHER_API.CATALOGS.LINEAR_FLOW }${ id }/${ version }`);
  }

  publish(schemaId: string, productId: string, data: any): Observable<any> {
    return super.put(`${ APP_SETTINGS.GATHER_API.PRODUCTS.PUBLISH }/${ schemaId }/${ productId }`, data,
      { loaderName: 'product_publication' });
  }

  remove(schemaId: string, data: any): Observable<any> {
    return super.put(`${ APP_SETTINGS.GATHER_API.PRODUCTS.REMOVE }/${ schemaId }`, data, { loaderName: 'remove_product' });
  }
}
