import { FileType } from '../enums/file-type.enum';
import { FormElement } from '../enums';
import { Subject } from 'rxjs';

export class Upload {
    name: string;
    fullname: any;
    blobUrl: string;
    mimeType: string;
    fileSize: number;
    height: number;
    width: number;
    formElement: FormElement;
    validFileFormats: string;
    userId: string;
    base64: any;
    byteArray: any;
    content: any;
    fileType: FileType;
    esPrecargada: boolean;
    file: any;
    status: number;
    loaded: number;
    error: string;
    cancel: Subject<void>;
}
