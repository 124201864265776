import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'scc-footer-catalogs',
  templateUrl: './footer-catalogs.component.html',
  styleUrls: ['./footer-catalogs.component.scss']
})
export class FooterCatalogsComponent implements OnInit {

 @Input()
 conteo: number;

  constructor() { }

  ngOnInit() {
  }

}
