import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-wizard-step',
  template: `
      <ng-template #template>
          <ng-content></ng-content>
      </ng-template>`
})

export class CustomWizardStepComponent implements OnInit {
  @ViewChild('template') template: TemplateRef<any>;
  isValid = true;

  constructor() {
  }

  ngOnInit() {
  }
}
