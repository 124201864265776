import { Component, ContentChildren, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CustomWizardStepComponent } from './custom-wizard-step/custom-wizard-step.component';
import { MatHorizontalStepper, MatStep } from '@angular/material';

@Component({
  selector: 'app-custom-wizard-modal',
  templateUrl: 'custom-wizard-modal.component.html',
  styleUrls: ['custom-wizard-modal.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class CustomWizardModalComponent {
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ContentChildren(CustomWizardStepComponent) steps: QueryList<CustomWizardStepComponent>;
  @Input() title: string;

  @ViewChildren('stepElement') stepElements: QueryList<MatStep>;
  
  constructor() {
  }

  /**
   * Method be callend on event selectionChange of mat-horizontal-stepper
   */
   refreshStepStatus(stepper){

    const current = stepper.selectedIndex;

    this.stepElements.map((step,index)=>{
      
      step.reset();
      step.state = 'doneee'; //doneee is a default state
      step.interacted = false;
      if(index <= current){

        step.state = 'edit';  //Edit is a state that represent the current step is edited
        step.interacted = true;
      }
    });
  }
}
