import { Directive, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { EngineMaskService } from './engine-mask.service';

@Directive({
  selector: '[mask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EngineMaskDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EngineMaskDirective),
      multi: true,
    },
    EngineMaskService,
  ],
})
export class EngineMaskDirective implements ControlValueAccessor, OnChanges {

  @Input('mask') public mask: string = '';
  @Input() public prefix = '';
  @Input() public suffix = '';
  @Input() public allowNegativeNumbers = false; 
  // tslint:disable-next-line
  public onChange = (_: any) => {
  };
  public onTouch = () => {
  };

  public constructor(private _input: ElementRef,
                    private _service:EngineMaskService) {

    this._service.onApplyMask.subscribe((value)=>{
        
        if(value != null){

            this.onChange(value)
        }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line:max-line-length
    const {
      mask,
      prefix,
      suffix
    } = changes;

    this.mask = mask.currentValue || '';
    this.prefix = prefix.currentValue || '';
    this.suffix = suffix.currentValue || '';
    //Apply mask
    this._service.startListener(this.mask,this._input.nativeElement,this.prefix,this.suffix,this.allowNegativeNumbers);
  }

  public validate({ value }: FormControl): ValidationErrors | null {
    
    if(this._service.error){
        return { 'Mask error': true };
    }
    return null;
  }

  /** It writes the value in the input */
  public async writeValue(inputValue: string | number): Promise<void> {

    const formatedValue = this._service.applyMask(inputValue || '',true);
    this._input.nativeElement.value = formatedValue;
  }

  // tslint:disable-next-line
  public registerOnChange(fn: any): void {

    this.onChange = fn;
  }

  // tslint:disable-next-line
  public registerOnTouched(fn: any): void {

    this.onTouch = fn;
  }
  
}
