import { Component, Input } from '@angular/core';
import { CommonLoaderDirective } from '../CustomLoader.class';

@Component({
  selector: 'app-non-blocking-modal-loader',
  template: `
    <div class="loader-container" *ngIf="loaderState$ | async">
      <section>
        <mat-progress-spinner [diameter]="55" class="loader-spinner" mode="indeterminate">
        </mat-progress-spinner>
        <span class="loader-span"></span>
      </section>
    </div>
  `,
  styleUrls: ['../nonBlocking/non-blocking-loader.component.scss']
})

export class NonBlockingModalComponent extends CommonLoaderDirective {
  @Input() set name(name: string) {
    super.selector(name);
  }
}
