import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FunctionsService } from 'src/app/shared/services/functions.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  buttonResult = PopupButtonsResult;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData,
    private functionsService: FunctionsService
  ) {}

  ngOnInit() {}

  optionClicked(value: PopupButtonsResult) {
    this.functionsService.openPopupResult.emit(value);
    this.dialogRef.close();
  }
}

export enum PopupButtonsResult {
  CANCEL_BUTTON = 1,
  OK_BUTTON = 2
}

export interface PopupData {
  width: string;
  height: string;
  title: string;
  bodyHtml: string;
}
