import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FoldersFound } from '../../../modules/agents/components/agent-inactivity/agent-inactivity-modal.component';
import { FolderFilterType } from '../../../modules/form/components/form-agent-selection/modal/form-agent-selection-modal.component';

@Component({
  selector: 'app-folder-row',
  template: `
    <div style="cursor: pointer;" folder-container (click)="folderClicked.emit(folder)">
      <section *ngIf="!readOnly">
        <mat-radio-button (change)="folderSelected.emit(folder)" [checked]="folder.isChecked">
        </mat-radio-button>
      </section>
      <section folder-agent-info>
        <mat-icon>{{ folder?.groupType === folderFilterType.MAILBOXES ? 'markunread_mailbox' : 'folder_shared'}}</mat-icon>
        <span style="font-weight: 500; font-size: 14px;">{{ folder?.name }}</span>
        <span *ngIf="folder?.agent?.name"><span style="margin-left: 10px; margin-right: 10px">|</span>{{ folder?.agent?.name }}</span>
        <span *ngIf="folder?.agent?.active"><span
          style="margin-left: 10px; margin-right: 10px">|</span>{{ folder?.agent?.active ? 'Activo' : 'Inactivo' }}</span>
        <span *ngIf="folder?.description"><span style="margin-left: 10px; margin-right: 10px">|</span>{{ folder?.description }}</span>
      </section>
      <section folder-path>
        {{ getPath(folder?.hierarchyPath) }}
      </section>
      <section>
        <button mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </section>
    </div>
  `,
  styleUrls: ['folder-row.component.scss']
})
export class FolderRowComponent {
  @Input() folder: FoldersFound;
  @Input() readOnly = false;
  @Output() folderSelected = new EventEmitter<FoldersFound>();
  @Output() folderClicked = new EventEmitter<FoldersFound>();

  folderFilterType = FolderFilterType;

  getPath(arr: Array<any>): string {
    if (arr && arr.length) return arr.join(' > ');
    return '';
  }
}
