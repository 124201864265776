import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { CustomColumnComponent } from './custom-column/custom-column.component';
import { asap } from 'rxjs/internal/scheduler/asap';

import * as moment from 'moment';

@Component({
  selector: 'app-custom-table',
  templateUrl: 'custom-table.component.html',
  styleUrls: ['custom-table.component.scss']
})

export class CustomTableComponent implements OnInit, AfterViewInit {
  columnsDescription = new Array<string>();
  columnsToRender = new Array<CustomColumnTable>();
  dataSource = new MatTableDataSource<any>();
  selectedItems = new Array<any>();
  selectedItemsWithIndexes = new Array<{ row: any, index: number, checked: boolean }>();
  toggleAllActivated = false;
  hiddenColumns = [];
  private _columnsHtml: QueryList<CustomColumnComponent>;
  private _disableRowSelection = false;
  private _sort: MatSort;
  private _paginator: MatPaginator;
  /**
   * Nombre de la hoja de excel.
   */
  @Input() sheetName: string;
  /**
   * Nombre de la hoja de excel.
   */
  @Input() columns = new Array<CustomColumnTable>();
  /**
   * Boton que accionara la exportación.
   */
  @Input() exportButton: any;
  /**
   * Bandera que indica si se permite selección multiple. Por default es FALSE.
   */
  @Input() multiSelect = false;
  /**
   * Bandera que indica si se permite paginación. Por default es FALSE.
   */
  @Input() allowPagination = true;

  /**
   * Bandera que indica si se permite seleccionar registros en la tabla. Por default es TRUE.
   */
  @Input() canSelect = true;

  /**
   * Bandera que indica si se permite seleccionar registros en la tabla. Por default es TRUE.
   */
  @Input() canSelectIcon = false;

  /**
   * Bandera que indica si se permite un menu dentro de la tabla. Por default es FALSE.
   */
  private _menu = false;
  @Input() set menu(flag: boolean) {
    this._menu = flag;
  }

  /**
   * To add an extra option as template... for example to include the chart life cycle of solicitude
  */
   @Input() extraOptionTemplate: TemplateRef<any> | ViewContainerRef ;

  get menu() {
    return this._menu;
  }

  /**
   * Si se especifico que si existira menu dentro de la tabla, se tiene que enviar las opciones a mostrar.
   */
  @Input() options: CustomMenuOption[];
  /**
   * Si se especifico, es el numero de registro que mostrara la tabla.
   */
  @Input() pageSizeValue: Array<number> = [20];
  /**
   * Emite el registro seleccionado en la tabla.
   */
  @Output() selectedRow = new EventEmitter<any>();
  /**
   * Emite el registro seleccionado en la tabla, incluyendo su indice.
   */
  @Output() selectedRowAndIndex = new EventEmitter<{ row: Array<any>, index: number }>();
  /**
   * Emite los registros seleccionados en la tabla, incluyendo su indice.
   */
  @Output() selectedRowsAndIndex = new EventEmitter<Array<{ row: any, index: number }>>();
  /**
   * Emite un evento cuando se seleccionaron todos los registros de golpe.
   */
  @Output() toggleAll = new EventEmitter<any>();
  /**
   * Emite las columnas cuando esten renderizadas.
   */
  @Output() columnsReady = new EventEmitter<Array<CustomColumnTable>>();
  /**
   * Emite un evento cuando la tabla este lista.
   */
  @Output() tableReady = new EventEmitter<boolean>();
  /**
   * Emite un evento cuando se haga un cambio de pagina en la tabla.
   */
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnPage: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(CustomColumnComponent) set columnsHtml(columns: QueryList<CustomColumnComponent>) {
    this._columnsHtml = columns;
    this._createColumns();
    this._process();
  }

  @Input() ignoreColumns = new Array<string>();

  @ViewChild(MatPaginator)
  private set paginator(paginator: MatPaginator) {
    if (this.allowPagination) {
      if (this.pageIndex) paginator.pageIndex = this.pageIndex;
      this._paginator = paginator;
      this.dataSource.paginator = paginator;
    }
  }

  @ViewChild(MatSort)
  private set sort(sort: MatSort) {
    this._sort = sort;
    this.dataSource.sort = sort;
  }

  @ViewChild('table') private table: MatTable<any>;
  @ViewChild('tableContainer') private tableContainer: ElementRef;

  private _sheetFileName: string;

  get sheetFileName() {
    return this._sheetFileName;
  }

  get pageSize() {
    return [this.pageSizeValue];
  }

  /**
   * Nombre que se le dara al archivo de excel.
   */
  @Input() set sheetFileName(value: string) {
    this._sheetFileName = `${ value }_${ moment().format('YYMMDDHHmmss') }`;
  }

  /**
   * Nombre que se le dara al archivo de excel.
   */
  @Input() set disableSelectionControls(value: boolean) {
    this._disableRowSelection = value;
  }

  get disableSelectionControls() {
    return this._disableRowSelection;
  }

  /**
   * Recibe el filtro a usarse en la tabla.
   * @param filter Filtro
   */
  @Input() set filter(filter: CustomFilterTable) {
    if (!this.dataSource) return;
    const customPredicate = (data: any, valueToSearch: string) => {
      const copiedObject = { ...data };
      this.ignoreColumns.forEach(column => copiedObject[column.toString()] = '');
      return !!Object.values(copiedObject).find(value => JSON.stringify(value).toLowerCase().includes(valueToSearch.toLowerCase()));
    };
    if (filter.predicate) {
      this.dataSource.filterPredicate = filter.predicate;
    } else {
      this.dataSource.filterPredicate = customPredicate;
    }
    this.dataSource.filter = filter.data;
    // Se setea pageIndex ya que el filtro debe manejar ahora el paginado
    this.pageIndex = undefined;
  }

  /**
   * Recibe la lista a renderizar en la tabla.
   * @param source Lista
   */
  @Input()
  set source(source: Array<any>) {
    this.reset();
    this.dataSource.filter = undefined;
    this.dataSource.filterPredicate = undefined;
    this.dataSource.data = source;
    asap.schedule(() => {
      if (this.multiSelect) {
        this.selectedItems = source.filter(i => i.select);
        this.selectedItemsWithIndexes = [];
        source.forEach((row, index) => {
          if (row.select) this.selectedItemsWithIndexes.push({ row, index, checked: true });
        });
      }
    });
  }

  @Input() pageIndex: number;

  constructor(public changes: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this._createColumns()) this._process();
  }

  private _createColumns(): boolean {
    let columns: Array<any> = [];
    if (this.columns && this.columns.length) {
      columns = this.columns;
    } else if (this._columnsHtml && this._columnsHtml.length) {
      columns = this._columnsHtml.map(e => (
        {
          name: e.name,
          field: e.field,
          callbackFn: e.footerFn,
          sort: !!e.sort,
          fullWidth: e.fullWidth,
          brief: !!e.brief
        } as CustomColumnTable));
    }
    if (!columns.length) return false;
    this.columnsToRender = columns;
    this.columnsDescription = columns.map(column => column.field);
    this.columnsReady.emit(columns);
    return true;
  }

  private _process(): void {
    
    if (!this.columnsToRender.length) return;
    if (this._menu || this.extraOptionTemplate) { //when has extra option template reference the options column must be visible

      this.columnsDescription = [...this.columnsDescription.slice(0, 1), ...['menu'], ...this.columnsDescription.slice(1)];
    }
    
    if (this.canSelect) {
      if (this.multiSelect) {
        this.columnsDescription.unshift('select');
      } else if (this.canSelectIcon) this.columnsDescription.unshift('uniqueSelect');
    }
    if (this._menu && (this.canSelect && this.multiSelect)) {
      this.hiddenColumns = [0, 2];
    } else if (this._menu) {
      this.hiddenColumns = [1];
    } else if (this.canSelect && this.multiSelect) this.hiddenColumns = [0];
    this.changes.detectChanges(); 
  }

  /**
   * Función que retorna el verdadero indice del registro seleccionado.
   * @param i indice de la tabla sin paginado.
   */
  getRealPageIndex(i: number): number {
    return this._paginator && (this._paginator.pageIndex * this._paginator.pageSize + i) || i;
  }

  /**
   * Función que emite el evento de selección de un/unos checkbox(es) en la tabla.
   * @param state Estado del checkbox.
   * @param item Objeto enlazado al row.
   * @param index Indice dentro del datasource.
   */
  checkItem(state: boolean, item: any, index: number): void {
    if (this._paginator) index = this._paginator.pageIndex * this._paginator.pageSize + index;
    this._resetSelections(state, item);
    if (this.multiSelect) {
      this.selectedItems = Array.of(...this.getActiveItems()).map(i => {
        const { select, ...rest } = i;
        return rest;
      });
      if (!state) {
        this.selectedItemsWithIndexes = this.selectedItemsWithIndexes.filter(i => i.index !== index);
      } else {
        this.selectedItemsWithIndexes.push({ row: item, index, checked: state });
      }
      this.selectedRowsAndIndex.emit(this.selectedItemsWithIndexes);
    } else {
      this.dataSource.data.filter(e => e !== item).forEach(e => e.select = false);
      const { select, ...rest } = item;
      this.selectedItems = [rest];
      this.selectedItemsWithIndexes = [{ row: rest, index: index, checked: state }];
    }
    this.toggleAllActivated = this.selectedItems.length === this.dataSource.data.length;
  }

  private _resetSelections(state: boolean, item: any): void {
    item.select = state;
  }

  /**
   * Función que emite un evento cuando se selecciona un solo checkbox.
   * @param item Objeto enlazado al row el cual será emitido.
   * @param index Indice dentro del datasource
   */
  selectItem(item: any, index: number): void {
    if (this.canSelectIcon) return;
    this.selectedRow.next([item]);
    this.selectedRowAndIndex.next({ row: [item], index });
  }

  /**
   * Función que emite un evento cuando se selecciona un solo checkbox.
   * @param item Objeto enlazado al row el cual será emitido.
   * * @param index Indice dentro del datasource
   */
  selectItemByRadio(item: any, index: number): void {
    this.selectedRow.next([item]);
    let calculateIndex;
    if (this._paginator) {
      calculateIndex = this._paginator.pageIndex * this._paginator.pageSize + index;
    } else {
      calculateIndex = index;
    }
    this.selectedRowAndIndex.next({ row: [item], index: calculateIndex });
  }

  updateTable(columns?: Array<CustomColumnTable>): void {
    this.columns = columns;
    this.ngAfterViewInit();
  }

  /**
   * Función que chequea todos los items de la tabla.
   * @param state Estado que será usado para el resto de checkboxes.
   */
  toggleAllItems(state: boolean): void {
    this.dataSource.data.forEach(item => this._resetSelections(state, item));
    this.toggleAll.next(this.dataSource.data.map(i => {
      const { select, ...rest } = i;
      return rest;
    }));
    if (state) {
      this.selectedItems = this.dataSource.data;
    } else {
      this.selectedItems = [];
    }
    this.toggleAllActivated = state;
  }

  goToPage(pageIndex: number): void {
    asap.schedule(() => {
      this.dataSource.paginator.pageIndex = pageIndex;
      this.dataSource.paginator.page.next({
        pageIndex,
        pageSize: this._paginator.pageSize,
        length: this.dataSource.data.length,
        previousPageIndex: 0
      });
    }, 250);
  }

  _handlePageChange(value) {
    if (value.pageIndex > value.previousPageIndex) {
      this.pageChange.emit(value);
    } else {
      this.pageIndex = undefined;
      this.returnPage.emit(value);
    }
  }

  /**
   * Función que obtiene los registros seleccionados en la tabla.
   */
  private getActiveItems(): Array<any> {
    return this.dataSource.data.filter(item => item.select);
  }

  /**
   * Función que reinicia la tabla y la lista de checkboxes seleccionados.
   */
  private reset(): void {
    // this.dataSource = new MatTableDataSource<any>();
    this.dataSource.data = [];
    asap.schedule(() => {
      this.selectedItems = new Array<any>();
      this.selectedItemsWithIndexes = new Array<{ row: Array<any>, index: number, checked: boolean }>();
      this.tableReady.emit(true);
    });
  }
}

/**
 * Clase que sirve de contrato para las columnas.
 */
export class CustomColumnTable {
  constructor(public name: string, public field: string, mask?: string,
              callbackFn?: Function, public sort: boolean = false, fullWidth: boolean = false, public brief: boolean = false) {
  }
}

/**
 * Clase que sirve de contrato para el filtro de la columna.
 */
export class CustomFilterTable {
  public ignoreColumns?: Array<string>;

  constructor(public data: any, public predicate?: (data: any, filter: string) => boolean) {
  }
}

/**
 * Clase que sirve de contrato para el menu de la tabla.
 */
export class CustomMenuOption {
  constructor(public label: string, public fn: any, public disabled: boolean = false, public toolTip?: string) {
  }
}
