import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';

@Component({
  selector: 'app-table-menu',
  templateUrl: './table-menu.component.html',
  styleUrls: ['./table-menu.component.scss']
})
export class TableMenuComponent implements OnInit {
  @ViewChild('sidebar') private _sidebar: MatDrawer;

  constructor() {
  }

  ngOnInit() {
  }

  toggle(): void {
    this._sidebar.toggle();
  }

}
