import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base-service.service';

interface StatusResponse{

  customStatusId:string,
  customStatusName:string
}

enum ServiceConstants {
  EndpointStates = 'charts/life-cycle/documentId'
}

@Injectable({
  providedIn: 'root'
})
export class ChartSolicitudeLifeCycleService extends BaseService {

  baseUrl = this._env.APIS.CHARTS; //We can change it when api be ready

  loading: boolean = false;

  states$: StatusResponse[] = [];

  loadStates(solicitudeId:string): Observable<any> {

    this.loading = true;
    this.states$ = [];
    
    return this.get(`${ServiceConstants.EndpointStates}/${solicitudeId}`, {

      errorMsg: 'Error obteniendo estados de la solicitud'
    }).pipe(
      catchError(
        (error: any) => {

          this.loading = false;
          return throwError(error);
        }
      ),
      tap(
        (response: any) => {

          this.loading = false;
          this.states$ = response;;
        }
      )
    );

  }

}
