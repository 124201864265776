import { Component, OnInit } from '@angular/core';
import { ConfirmationModalComponent } from '../modal-confirmation/confirmation-modal.component';

@Component({
  selector: 'app-confirmation-modal-button-custom',
  templateUrl: 'confirmation-modal-button-custom.component.html',
  styleUrls: ['confirmation-modal-button-custom.component.scss']
})

export class ConfirmationModalButtonCustomComponent extends ConfirmationModalComponent implements OnInit {
  buttonLeftCorner: string;
  buttonMiddleCorner: string;
  buttonRightCorner: string;
  buttonLeftCornerAction: Function;

  ngOnInit() {
    super.ngOnInit();
    this.buttonLeftCorner = this.data.buttonLeftCorner || 'No gracias';
    this.buttonMiddleCorner = this.data.buttonMiddleCorner || 'Cancelar';
    this.buttonRightCorner = this.data.buttonRightCorner || 'Guardar';
    this.buttonLeftCornerAction = this.data.actions.buttonLeftCornerAction;
  }
}
