import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import maskConfigs from './engine-mask.config';

const CommonRegex = {
    WHITE_SPACE: / /g,
    NUMERIC_THOUSAND_SEPARETOR: /\B(?=(\d{3})+(?!\d))/g,
    SIMPLE_COMMA: /,/g
};

@Injectable({
  providedIn: 'root'
})
/**
 * Class to handle the Engine Masks without external libraries
 * The logic of this class is based on the config file engine-mask.config.ts that define the diferent type of mas than can be used
 * The types of mas are two: 
 * Numerics: Can be a numeric with decimals or not, with thousand separetor or not: 1000.50, 1,500,452 ...
 * Blocks: Fixed masks based on block of chars with some rules
 * -Dates: 10/12/1966
 * -Ip: 10.55.0.8
 * -Time: 23:12:02
 * -DateTime: 10/12/1966 00:01:59
 */
export class EngineMaskService {
    
    onApplyMask = new BehaviorSubject(null); //BehaviorSubject to send event when mask is applied

    mask = ''; //BehaviorSubject input mask based on key of config defined in engine-mask.config.ts
    prefix = '';
    suffix = '';
    input:HTMLInputElement = null; //Input element to add EventListener and apply transformations
    cursorPosition = 0;

    actualCaputure = ''; //Store the actual value of mask to work with it as previus value in the next value changes
    actualCaputureBlocks = [];
    directionBack = false; 
    directionDelete = false;
    error = false; //Variable is setted to true when the mask of type block is fully completed
    currentValue = '';
    allowNegativeNumbers = false; //Is only used on mask of type numeric
    
    /**Method to start listed of input events */
    startListener(mask,input,prefix = '',sufix = '',allowNegativeNumbers = false){

        this.mask = mask;
        this.input = input;
        this.prefix = prefix;
        this.suffix = sufix;
        this.allowNegativeNumbers = allowNegativeNumbers;

        const own = this;
        this.input.addEventListener("click", function (e) {
            
            own.input.value = own.applyPrefixSuffix(own.input.value);
            own.fixtCursorPosition();
        });
        
        own.input.addEventListener("input", function (e) {
            
            own.input.value = own.applyMask(own.input.value); 
            own.input.selectionStart = own.input.selectionEnd = own.cursorPosition;
            own.fixtCursorPosition();
            if(!maskConfigs[own.mask].sendFormatedValue){

                own.onApplyMask.next(own.currentValue);
            }else{

                own.onApplyMask.next(own.clearPrefixSufix(own.input.value));
            }
            
        });

        own.input.addEventListener("keydown", function (event) {
            
            own.actualCaputure = own.clearPrefixSufix(own.input.value);
            const clenedValue = own.clearPrefixSufix(own.input.value);
            if(!clenedValue){

                own.actualCaputureBlocks = [];
            }

            own.fixtCursorPosition();

            //Valid arrow not move to after sufix and prev sufix
            const keyCode = event.keyCode;
            const isCopyPaste = (event.key == 'x' || event.key == 'c' || event.key == 'v') && event.ctrlKey;
            
            if (!isCopyPaste && (keyCode == 8 || keyCode == 37) && (own.prefix && own.prefix.length && own.input.selectionStart == own.input.selectionEnd  && own.input.selectionStart <= own.prefix.length))  {
                
                event.preventDefault();
            }

            if (!isCopyPaste && (keyCode == 39 || keyCode == 46) && (own.suffix && own.suffix.length > 0 && own.input.selectionStart >= own.input.value.lastIndexOf(own.suffix)))  {
                
                event.preventDefault();
            }
            
            if(event.key == '-' && !own.allowNegativeNumbers){

                event.preventDefault();
            }
           
            
            //Add some extra mask validations
            
            if(!isCopyPaste && maskConfigs[own.mask].lockKeysRegex && ( !maskConfigs.defaultAllowKeyCodes.test(event.key)  && !maskConfigs[own.mask].lockKeysRegex.test(event.key)) ){

                event.preventDefault();
            }

            
            if(maskConfigs[own.mask].separetorKeyRegex &&  maskConfigs[own.mask].separetorKeyRegex.test(event.key)){
                event.preventDefault();
                
                const keyNextPos = clenedValue.slice(own.input.selectionStart - (own.prefix ? own.prefix.length : 0) ).indexOf(event.key);
                if(keyNextPos >= 0){

                    own.input.selectionStart = own.input.selectionEnd = own.input.selectionStart +  keyNextPos + 1  ;
                    
                }
            }

            own.directionBack = false; 
            own.directionDelete = false; 
            if(keyCode == 8){

                own.directionBack = true; 
            }
            if(keyCode == 46){

                own.directionDelete = true; 
            }

        });

        own.input.addEventListener("paste", function (event) {

            own.actualCaputure = own.clearPrefixSufix(own.input.value);
            const clenedValue = own.clearPrefixSufix(own.input.value);
            if(!clenedValue){

                own.actualCaputureBlocks = [];
            }
        });

    }
    
    /**Method to apply changes on cursor poxition based on current position and prefix or sufix */
    fixtCursorPosition() {

        if (this.prefix && this.prefix.length > 0 && this.input.selectionStart < this.prefix.length) {

            this.input.selectionStart = this.prefix.length;
        }

        if (this.suffix && this.suffix.length > 0 && this.input.selectionStart >= this.input.value.lastIndexOf(this.suffix)) {

            this.input.selectionStart = this.input.value.lastIndexOf(this.suffix);
        }

        if (this.prefix && this.prefix.length > 0 && this.input.selectionEnd < this.prefix.length) {

            this.input.selectionEnd = this.prefix.length;
        }

        if (this.suffix && this.suffix.length > 0 && this.input.selectionEnd >= this.input.value.lastIndexOf(this.suffix)) {
        
            this.input.selectionEnd = this.input.value.lastIndexOf(this.suffix);
        }
    }

    /**
    * Method to apply a change on cursor position for mask numnerics when som extra caacters is
    * Example: when a thousand separetor is added the cursor position must move depended of direction
    */
    nextCursorPosition(previusValue,formatedValue,stripedChars = [','],backMove = true){

        let lengthOffset = 0;
        if(this.cursorPosition > formatedValue.length){
            
            return formatedValue.length;
        }
        previusValue = previusValue.slice(0,this.cursorPosition);
        formatedValue = formatedValue.slice(0,this.cursorPosition);
        const formatedValueCopy = formatedValue;
        
        stripedChars.forEach(stripped => {
        
            if(formatedValue.slice(-1) == stripped){
                if(this.directionBack && backMove){
                    this.cursorPosition --;
                }
            }
            const regexStripped = new RegExp(`\\${stripped}`,'g');    
            previusValue = previusValue.replace(regexStripped,'');
            formatedValue = formatedValue.replace(regexStripped,'');
        });

        lengthOffset = previusValue.length - formatedValue.length;

        return this.cursorPosition + ( (lengthOffset !== 0) ? (lengthOffset / Math.abs(lengthOffset)) : 0 );
    }

    /**
     * Added prefix and sufix to value if it's required
    */
    applyPrefixSuffix(value,force = false) {

        if (this.prefix == null) {

            this.prefix = "";
        }

        if (this.suffix == null) {

            this.suffix = "";
        }
        if (!value.startsWith(this.prefix) || force) {

            value = `${this.prefix}${value}`;
        }
        if (!value.endsWith(this.suffix) || force) {

            value = `${value}${this.suffix}`;
        }

        return value;
    }

    /**
     * Remove prefix and sufix of param value
    */
    clearPrefixSufix(value){

        value = (value || '').toString();
		if(this.prefix && this.prefix.length > 0 && value.startsWith(this.prefix)) {

			value = value.slice(this.prefix.length);
            this.cursorPosition -= this.prefix.length;
		}

		if(this.suffix && this.suffix.length > 0 &&  value.endsWith(this.suffix)) {

			value = value.slice(0,this.suffix.length * -1);
            this.cursorPosition = this.cursorPosition > value.length ? value.length:  this.cursorPosition;
		}
        return value;
    }

    /**
    * Remove all unallowed chars of current mask from te current value, and move cursor to left when un-allowed char is removed 
    */
    sanitizeValue(value,position,regs){

        let result = '';
        for (let i = 0; i < value.length; i++) {

            const letter = value[i];
            let letterValid = false;
            for (let j = 0; j < regs.length; j++) {

                const reg = regs[j];
                if (letter.match(reg)) {
                  
                    letterValid = true;
                    break;
                }
            }
            if(letterValid){

                result = result + letter;
            }
            else if(!letterValid && position == i + 1){
                
                this.cursorPosition --;
            }
        }
        return result;
    }

    /**
     * Extract the valid value of block mask based on parameters of method 
    */
    handleMaskPart(input,minLength,maxLength,max,min,validLength){
        
        const length = maxLength;
        const result = {
            valid: '',
            extra: ''
        }
        result.valid = input.slice(0,length);
        result.extra = input.slice(length);
        
        let inputInteger = parseInt(result.valid.length == 1 && !this.directionBack && !this.directionDelete ? `${result.valid}0`: result.valid);
        
        if(input.length > 0 && (inputInteger < min || inputInteger > max) && (result.valid != '0')){
            
            result.valid = undefined;
        }

        if(validLength && input.length > length ){
            
            result.valid = undefined;
            result.extra = '';
        }

        if(result.valid == undefined && maxLength > minLength){

            return this.handleMaskPart(input,minLength,maxLength - 1,max,min,validLength);
        }
        
        return result;
    }

    /**
     * Apply numeric mask to value 
    */
    dynamicMaskNumeric(value,format ){
       
        // check if allow decimals is false, then round and remove decimals
        if(!format.allowDecimals){

            // clean commas
            value = value.replace(/,/g,'');
            value = Math.round(value).toString();
        }

        const previusValue = value;
        const arr = value.split('');
        const arrResult = [];
        let pos = 0;
        this.currentValue = '';
        for (let i = 0; i < arr.length; i++) {

            const char = arr[i];
            if(char.trim() != '' && !isNaN(char)){

                arrResult.push(char); 
             }
             else if(char == '.' && format.allowDecimals && (arr.lastIndexOf(char) == pos || arr.lastIndexOf(char) == -1)){
 
                 arrResult.push(char); 
             }else if(char == '-' && pos == 0){
 
                 arrResult.push(char); 
             }
             
             pos ++;
            
        }
        
        value = arrResult.join('');
        
        while (value.length > 1 && value[0] == '0') {
					
            value = value.slice(1);
            this.cursorPosition --;
        }

        if(format.milesSeparetor.length > 0){

            let separeted = value.split('.');
            separeted[0] = separeted[0].replace(new RegExp(format.milesSeparetor,'g'),'');
            separeted[0] =  separeted[0].toString().replace(CommonRegex.NUMERIC_THOUSAND_SEPARETOR, ",");
            if(!separeted[0]){

                separeted[0] = '0';
            }
            if(separeted.length == 1){
                separeted.push('');
            }
            
            if(separeted[1].length >= 2){

                separeted[1] = separeted[1].substring(0,2);
            }
            else if(separeted[1].length == 1){

                separeted[1] = `${separeted[1]}0`;
            }else if(format.allowDecimals){

                separeted[1] = `00`;
            }

            if(format.allowDecimals){

                value = separeted.join('.');
            }else{

                value = separeted[0];
            }
            
            
        }
        this.currentValue = value.replace(CommonRegex.SIMPLE_COMMA,'');
        this.cursorPosition = this.nextCursorPosition(previusValue,value,[',','.']);
        return value;
    }

    /**
     * Apply block mask to value 
    */
    dynamicMaskBlock(value,format ){

        value = this.sanitizeValue(value,this.cursorPosition,format.allowChars);
        let formatedResult = '';
        this.currentValue = '';
        let valueCp = value;
        const blocks = [];
        
        const previusBlocks = this.actualCaputureBlocks || [] ;

        for (let index = 0; index < format.blocks.length; index++) {
            const separetor = format.blocks[index].separetor;
            const separetorPosition = valueCp.indexOf(separetor);
            if (separetorPosition !== -1 && separetor != '') {
                
                blocks.push(valueCp.slice(0,separetorPosition).replace(CommonRegex.WHITE_SPACE,''));
                valueCp = valueCp.slice(separetorPosition + 1);
            }else {

                blocks.push(valueCp.replace(CommonRegex.WHITE_SPACE,''));
                break;
            }
        }
        
        if(previusBlocks.length > 0 && previusBlocks.length == format.blocks.length && blocks.length != previusBlocks.length){
            
            return this.actualCaputure;
        }
        this.error = false;
        let extraBlockContent = '';
        let formatedLength = 0;
        
        for (let i = 0; i < format.blocks.length; i++) {
            
            if (blocks.length == i) {
                
                blocks.push('');
            }

            const blockConfig = format.blocks[i];
            const block = extraBlockContent + blocks[i];
            
            extraBlockContent = '';
            let handlePart = null;
            
            handlePart = this.handleMaskPart(block,blockConfig.minLength,blockConfig.maxLength,blockConfig.max,blockConfig.min, 
                                            i == format.blocks.length - 1 || (blocks.length > i + 1 && blocks[i + 1].length != 0));
            
            if(handlePart.valid != undefined){

                blocks[i] = handlePart.valid;
            }else{

                blocks[i] = previusBlocks.length > i ? previusBlocks[i]: '';
                this.cursorPosition --;
            }
            
            if(format.blocks.length > i + 1 && blocks.length <= i + 1){

                blocks.push('');
            }

            if (blocks.length > i + 1 && blocks[i + 1].replace(CommonRegex.WHITE_SPACE,'').length == 0 ) {
                
                extraBlockContent = handlePart.extra; //To apply on next block
            }
            else if(handlePart.extra.length > 0){

                this.cursorPosition -= handlePart.extra.length;
            }

            formatedLength += blocks[i].length ;

            if((this.cursorPosition == formatedLength + i + extraBlockContent.length) && (parseInt(blocks[i] + 0) > blockConfig.max) && !this.directionBack && handlePart.valid != undefined ){

                this.cursorPosition += 1;
            }
            
            if(blocks[i].length < blockConfig.minLength){

                blocks[i] += ' '.repeat(blockConfig.minLength - blocks[i].length);
            }

            formatedResult +=  blocks[i] + format.blocks[i].separetor;

            const currentBlockCleaned = blocks[i].replace(CommonRegex.WHITE_SPACE,'');
            if(format.validation && (currentBlockCleaned < blockConfig.min || currentBlockCleaned > blockConfig.max || 
               currentBlockCleaned.length > blockConfig.maxLength  || currentBlockCleaned.length < blockConfig.minLength)){

                this.error = true;
            }
            this.currentValue += blocks[i];
        }
        //Validate
        
        this.actualCaputureBlocks = blocks;
        //let formatedResult = blocks.join(format.separetor) + (value.endsWith(format.separetor) && blocks.length < format.blocks.length ? format.separetor:'');  
        //this.cursorPosition = this.nextCursorPosition(value,formatedResult,[format.separetor],false);
        return formatedResult;

    }

    /**
     * Main method to convert value as excpected mask 
    */
    applyMask(value, ignorePrevius = false){

        if(ignorePrevius){

            this.actualCaputureBlocks = [];
        }
		if(this.input){

            this.cursorPosition = this.input.selectionStart;
        }
        value = this.clearPrefixSufix(value);
        
        if(!value){
            
			return '';
		}

        const maskCurrentConfig = maskConfigs[this.mask];
        
		switch (maskCurrentConfig.type) {
			case 'numeric':

                value = this.dynamicMaskNumeric(value,maskCurrentConfig);
                
				break;
			case 'block':

                value = this.dynamicMaskBlock(value,maskCurrentConfig);
				break;
		}

		//Restore Sufix/Prefix
		value = this.applyPrefixSuffix(value,true);
        
        //Fixt Cursor position after apply Prefix suffix
        if(this.prefix && this.prefix.length > 0){

            this.cursorPosition += this.prefix.length;
            if(this.cursorPosition < this.prefix.length){

                this.cursorPosition = this.prefix.length;
            }
        }
        if(this.cursorPosition < 0){

            this.cursorPosition = 0;
        }
        
        
		return value;
	}
}
