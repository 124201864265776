import { Loader } from './CustomLoader.interface';
import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Directive({
  selector: '[appCommonLoader]'
})
export class CommonLoaderDirective implements Loader {
  loaderState$: Observable<boolean>;

  constructor(private readonly _loader: LoaderService) {
  }

  selector(name: string): void {
    this.loaderState$ = this._loader.getLoader(name) || this._loader.setLoader(name);
  }
}
