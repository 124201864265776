import { AfterViewInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { CustomFilterTable } from '../../components/custom-table/custom-table.component';
import { BaseFilterCatalog } from './BaseFilterCatalog';

/**
 * Clase base abstracta para el filtrado personalizable de catalogos que extiende de la clase base para filtrado no personalizable.
 */
export abstract class BaseCustomFilterCatalog<T> extends BaseFilterCatalog<T> implements AfterViewInit {

  ngAfterViewInit(): void {
    this.cleanSearch();
    this.searchComponent.search.pipe(takeWhile(() => !this._stopSearch))
      .subscribe(this.refreshFilter.bind(this));
  }

  refreshFilter(value?: string): void {
    this.tableComponent.filter = this.getFilter(value);
    this.catalogLoaded.emit();
  }

  /**
   * Función abstracta que tiene que ser implementada en caso de requerirse un filtro personalizable.
   */
  protected abstract filterPredicate(): Function;

  /**
   * Función privada que obtienen el filtro correspondiente para la busqueda en la tabla.
   * @param toFilter Palabra a buscar.
   */
  private getFilter(toFilter?: string): CustomFilterTable {
    return { data: toFilter ? toFilter : this.searchComponent.control.value, predicate: this.filterPredicate() } as CustomFilterTable;
  }
}
