import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-dialog-preview-iframe',
  templateUrl: './dialog-preview-iframe.component.html',
  styleUrls: ['./dialog-preview-iframe.component.scss']
})
export class DialogPreviewIframeComponent implements OnInit {
  url: string;
  title: string;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.url = this.data.url;
  }
  onClose() {
    this.dialogRef.close();
  }

  printIFrame() {
    window.frames['printf'].focus();
    window.frames['printf'].print();
  }

}
