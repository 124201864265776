import { FormElement, FormElementSize, HelperType, TitleIndex } from '../enums';
import { FormEditSelectionType } from '../../modules/data-designer/components/designer/modal-formedit-control/modal-formedit-control.config';

interface BasicRequestForm {
  description: string;
  sections: SectionForm[];
}

export class RequestForm implements BasicRequestForm {
  description: string;
  sections: SectionForm[];
  isReadOnly: boolean;
  isToValidate: boolean;
}

export class SubRequestForm implements BasicRequestForm {
  description: string;
  sections: SectionForm[];
  enableRule?: CalculatedValue;
  rule?: SubRequestFormRule;
  isHidden: boolean;
  // FormEdit
  formEditSection?: FormEditSection;
  isValid?: boolean;
}

export class FormEditSection {
  fullName: string;
  elements: ElementsForm[];
  sections: SectionForm[];
  baseName: string;
}

export class SubRequestFormRule {
  fullName: string;
  valueCondition: string | boolean;
}

export class SectionForm {
  description: string;
  formEditFullname?: string;
  rows: RowForm[];
  selectionIndex?: number;
  enableRule?: CalculatedValue;
  isHidden?: Boolean;
  // Mapa idCont y secciones como autosave
}

export class RowForm {
  subRequestForm: SubRequestForm;
  elementSize: FormElementSize;
  elements: ElementsForm[] | ImageLoaderfullElementsForm[] | MapsElementsForm[];
}

export class ElementsForm {
  fullName: string;
  label: string;
  elementType?: FormElement;
  columnFunction?: string;
  vertical?: boolean;
  isCalculated?: boolean;
  calculatedValue?: CalculatedValue;
  textTransform?: string;
  enableRule?: CalculatedValue;
  mapFunction?: CalculatedValue;
  data?: ElementsFormData[];
  selectionIndex?: number;
  isTitle?: boolean;
  isExtendedElement?: boolean;
  isHidden?: boolean;
  isRequired?: boolean;
  mask?: string;
  maskPrefix?: string;
  maskSuffix?: string;
  maskDescription?: string;
  maskAllowNegative: boolean;
  align?: string;
  min?: string;
  max?: string;
  checkDescription?: string;
  description?: string;
  formEditAllowEdit: boolean;
  formEditAllowAdd: boolean;
  formEditAllowDelete: boolean;
  formEditAllowQuery: boolean;
  formEditAllowDuplicate: boolean;
  formEditSelectionType: FormEditSelectionType;
  formEditMaxRows: number | null;

  helperText?: string;
  helperDescription?: string;
  helperType?: HelperType;
  errorText?: string;

  sections?: SectionForm[];
  cols?: any[] = [];

  titleIndex?: TitleIndex;

  readOnly?: boolean;
  validFileFormats?: string;
  fileSize?: number;

  backgroundType?: number;
  mailboxTags?: Array<string>;
  folderTags?: Array<string>;

  constructor();

  constructor(fullName: string, label: string, elementType: FormElement);

  constructor(fullName?: string, label?: string, elementType?: FormElement) {
    if (fullName) {
      this.fullName = fullName;
    }
    if (label) {
      this.label = label;
    }
    if (elementType) {
      this.elementType = elementType;
    }
  }
}

export class CalculatedValue {
  fieldReferences: string[];
  fieldRequired?: string[];
  fieldFunction: string;
}

export class ElementsFormData {
  parentKey: string;
  key: string;
  value: string;
}

export class ReferenceChange {
  fullName: string;
  value: any;
}

//#region ' extended ElementsForm'

export interface ExtendedElementsForm {
}

export interface ImageLoaderFull extends ExtendedElementsForm {
  url?: any;
  fileName?: any;
}

export class ImageLoaderfullElementsForm extends ElementsForm
  implements ImageLoaderFull {
  url?: string;
  fileName?: string;

  constructor() {
    super();
    this.url = '';
    this.fileName = '';
  }
}

export interface MapInline extends ExtendedElementsForm {
  latitude?: any;
  longitude?: any;
}

export class MapsElementsForm extends ElementsForm implements MapInline {
  latitude?: string;
  longitude?: string;

  constructor() {
    super();
    this.latitude = '';
    this.longitude = '';
  }
}

//#endregion ' extended ElementsForm'
