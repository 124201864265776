import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-wizard-buttons',
  templateUrl: 'custom-wizard-buttons.component.html',
  styleUrls: ['custom-wizard-buttons.component.scss']
})

export class CustomWizardButtonsComponent implements OnInit {
  @Input() nextDescription = 'Siguiente';
  @Input() backDisabled = true;
  @Input() nextDisabled = true;
  @Output() next = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }
}
