import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FoldersFound } from '../../../../agents/components/agent-inactivity/agent-inactivity-modal.component';
import { HierarchyNewService } from '../../../../../shared/services/hierarchy-new.service';
import { GenericRow, mapFoldersToRows } from '../../../../../shared/components/folder-finder/folder-finder.utils';

export interface FormAgentSelectionModal {
  folderTags: Array<string>;
  mailBox: Array<string>;
  callback: (folder: FoldersFound) => void;
}

export enum FolderFilterType {
  FOLDERS = 0,
  MAILBOXES = 1,
  MAILBOXES_FOLDERS = 2,
}

export class DtoFolderFilter {
  constructor(public filterText: string, public filterType: number) {
  }
}

export function getFoldersFilters(): Array<DtoFolderFilter> {
  return [
    new DtoFolderFilter('Todos los buzones y carpetas', FolderFilterType.MAILBOXES_FOLDERS),
    new DtoFolderFilter('Buzones', FolderFilterType.MAILBOXES),
    new DtoFolderFilter('Carpetas', FolderFilterType.FOLDERS),
  ];
}

@Component({
  selector: 'scc-form-agent-selection-modal',
  templateUrl: 'form-agent-selection-modal.component.html',
  styleUrls: ['form-agent-selection-modal.component.scss'],
  providers: [HierarchyNewService]
})

export class FormAgentSelectionModalComponent implements OnInit, AfterViewInit {

  foldersFounder: Array<FoldersFound> = new Array<FoldersFound>();
  filteredFolders: Array<FoldersFound> = new Array<FoldersFound>();
  foldersFilters: Array<DtoFolderFilter>;
  filter = FolderFilterType.MAILBOXES_FOLDERS;
  genericRowsArray: Array<GenericRow> = [];
  filteredRows: Array<GenericRow> = [];

  constructor(public dialogRef: MatDialogRef<FormAgentSelectionModalComponent>,
              @Inject(MAT_DIALOG_DATA) private data: FormAgentSelectionModal,
              private _hierarchyService: HierarchyNewService) {
  }

  ngOnInit() {
    this.foldersFilters = getFoldersFilters();
  }

  ngAfterViewInit(): void {
    this._hierarchyService.getHierarchyByTags(this.data.mailBox || [], this.data.folderTags || [])
      .subscribe(res => {
        this.foldersFounder = res.value;
        this.filteredFolders = this.foldersFounder;
        mapFoldersToRows.call(this);
        this.filteredRows = this.genericRowsArray;
      });
  }

  filterRows(): void {
    switch (this.filter) {
      case FolderFilterType.FOLDERS: {
        this.filteredRows = this.genericRowsArray.filter(row => row.type === FolderFilterType.FOLDERS);
        break;
      }
      case FolderFilterType.MAILBOXES: {
        this.filteredRows = this.genericRowsArray.filter(row => row.type === FolderFilterType.MAILBOXES);
        break;
      }
      case FolderFilterType.MAILBOXES_FOLDERS: {
        this.filteredRows = this.genericRowsArray;
        break;
      }
    }
  }

  folderClicked(folder: FoldersFound): void {
    if (this.data.callback) this.data.callback(folder);
    this.dialogRef.close();
  }

  filterFoldersByName(filter: string): void {
    this.filterRows();
    if (filter) {
      this.filteredRows = this.filteredRows.filter(row => {
        const typedFolder = { ...(new GenericRow()), ...row };
        return typedFolder.toString().toLowerCase().includes(filter.toLowerCase());
      });
    }
  }
}
