import {Component, OnInit} from '@angular/core';
import {CustomModal} from './CustomModal';

@Component({
  selector: 'scc-form-alert',
  template: `
    <h3 mat-dialog-title>{{ title }}</h3>
    <mat-dialog-content>{{ message }}</mat-dialog-content>
  `
})

export class SuccessModalComponent extends CustomModal<any> implements OnInit {
  ngOnInit() {
  }
}
