import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { APP_SETTINGS } from '../configs/app-settings.config';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(
            http,
            APP_SETTINGS.BASE_I18N_URL,
            APP_SETTINGS.FILE_TYPES.JSON
          ),
        deps: [HttpClient]
      }
    })
  ],
  exports: [TranslateModule]
})
export class TranslatorModule {}
