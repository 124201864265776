import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-custom-chips',
  templateUrl: 'custom-chips.component.html',
  styleUrls: ['custom-chips.component.scss']
})
export class CustomChipsComponent implements OnInit {
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @Output() tagsChange = new EventEmitter<Array<string>>();
  @Input() label = 'Etiquetas';
  @Input() placeholder = 'Escribir etiqueta';
  @Input() onlySelect = false;
  @Input() allowSpecialCharacters = true;

  @Input()
  set allTags(value: string[]) {
    this._allTags = value.slice() || [];
  }

  get allTags(): Array<string> {
    return this._allTags;
  }

  @Input()
  set tags(value: string[]) {
    this._tags = value;
    this.tagsChange.emit(value);
  }

  get tags(): Array<string> {
    return this._tags;
  }

  private _allTags: string[] = [];
  private _tags: string[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  showError = false;

  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags));
  }

  ngOnInit(): void {
    this.allTags = this.allTags.filter(x => !this.tags.includes(x));
  }

  update(newList): void {
    if (this._tags && this._tags.length) {
      this.allTags = newList;
      this._tags = this._tags.filter(oldTag => this.allTags.find(newTag => newTag === oldTag));
      this.tagCtrl.updateValueAndValidity();
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.onlySelect) {
      // Add tag only when MatAutocomplete is not open
      // To make sure this does not conflict with OptionSelected Event
      if (!this.matAutocomplete.isOpen) {
        const input = event.input;
        const value = event.value;

        let isValid = true;
        if (!this.allowSpecialCharacters) isValid = this.containsSpecialCharacters(value);

        // Add our tag
        if ((value || '').trim() && isValid) {
          this.tags.push(value.trim());
        } else if (!isValid) {
          this.showError = true;
          setTimeout(() => this.showError = false, 3000);
        }

        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.tagCtrl.setValue(null);
      }
    }
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    if (this.onlySelect) {
      this.allTags.push(tag);
      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.onlySelect) this.allTags = this.arrayRemove(this.allTags, event.option.viewValue);

    this.tags.push(event.option.viewValue);
    this.tagCtrl.setValue(null);
    this.tagInput.nativeElement.value = '';
    this.tagInput.nativeElement.blur();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  containsSpecialCharacters(value): boolean {
    return new RegExp(/^(\w+\s?)*\s*$/).test(value);
  }
}

