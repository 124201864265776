import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../models';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FolderTreeService } from 'src/app/modules/agents/pages/folder-tree/folder-tree.service';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { EnvService } from 'src/app/core/services/env.service';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagEnum } from '../enums/featureFlag.enum';

/**
 * @description This service is used to manage the basic profile permissions
 */
@Injectable({
  providedIn: 'root'
})
export class ProfilePermissionsService {

  userProfile: User = null;

  constructor(private readonly agentFolderService: FolderTreeService,
              private readonly authService: AuthService,
              private readonly environment: EnvService,
              private readonly featureFlagService: FeatureFlagService) { }
  
  /**
   * @description Get the userProfile of logged user if it is not loaded yet, otherwise return it
   * 
   * @returns {Observable<User>}
  */
  loadUser(): Observable<User> {

    let result: Observable<User>;
    // if user profile is already loaded, return it as observable
    // otherwise, load it from server
    if (this.userProfile) {

      result = of(this.userProfile);
    }
    else {
  
     result = this.agentFolderService.getByAgentId(this.authService.userId).pipe(
        filter(agentInfo => agentInfo && Array.isArray(agentInfo) && agentInfo.length > 0),
        tap(agentInfo => {
          this.userProfile = agentInfo[0].agent;
        }),
        switchMap(
          () => of(this.userProfile)
        )
      );
    }

    return result;
  }

  /** 
   * @description This method is used to verify if the user has the permission to see the reports option in the menu.
   * 
   * @returns {Observable<boolean>}
  */
  userHasReportsPermission(): Observable<boolean> {

    return this.loadUser().pipe(
      map(user => this.environment.ALLOW_OPERATION_BOARD_BY_TAGS.some(tag => user.labels.includes(tag)))
    );
  }

  /** 
   * @description This method is used to verify if the user has the permission to see the reports option in the menu.
   * 
   * @returns {Observable<boolean>}
  */
  userHasDigitalOnboardingAdminPermission(): Observable<boolean> {

    return this.loadUser().pipe(
      map(user => this.featureFlagService.getFlagValue(FeatureFlagEnum.DIGITAL_ONBOARDING_ADMIN_PANEL) && 
                  user.labels.includes(this.environment.DIGITAL_ONBOARDING_ADMIN_TAG))
    );
  }
}
