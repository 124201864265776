export enum EngineCoreStatus {
  // No se detectaron errores durante la ejecución de la tarea.
  OK = 0,
  // Cuando el usuario que invoca a la acción no es el Usuario Asignado o Usuario Validador en el Requerimiento involucrado.
  DENIED = 1,
  // Si la acción lo requiere, el Requerimiento debe tener estatus Abierto (Open) para tener efecto.
  NOT_UPDATABLE = 2,
  // Cualquier error por validación de datos o en la originación que resultara durante la ejecución de la acción
  OPEN = 3,
  // Señala si el contenedor se encuentra abierto.
  INVALID_COMPOUNDS = 4,
  // Si existiera algún Requerimiento que tiene iguales su containerId y parentId, evitando que se cicle la ejecución.
  OVERFLOW_PREVENT = 5
}
