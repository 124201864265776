import { Component, Input, OnInit } from '@angular/core';
import { FormBaseElement } from '../../bases/FormBaseElement';
import { HelperType } from 'src/app/shared/enums';


@Component({
  selector: 'scc-form-helper',
  templateUrl: './form-helper.component.html',
  styleUrls: ['./form-helper.component.scss']
})
export class FormHelperComponent extends FormBaseElement implements OnInit {
  helperTypeEnum = HelperType;
  @Input()
  helperType: HelperType;
  @Input()
  helperText: string;
  @Input() height = 'auto';

  ngOnInit() {
  }

}
