import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  transform(value: any): string {
    return value !== null && value !== undefined ? 'folder_shared' : 'folder_open';
  }

}
