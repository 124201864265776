import { Component, OnInit } from '@angular/core';
import { DatafrontElementBase } from '../../../../../../shared/models/Datafront/bases/DatafrontElementBase';

@Component({
  selector: 'app-form-section',
  templateUrl: 'form-section.component.html',
  styleUrls: ['form-section.component.scss']
})

export class FormSectionComponent extends DatafrontElementBase implements OnInit {
  ngOnInit() {
  }
}
