import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class JavascriptEditorService {
  close = new BehaviorSubject(false);
  statusEditor = new BehaviorSubject(new DtoJSEditor(''));
  codeChange = new BehaviorSubject('');
}

export class DtoJSEditor {
  constructor(public code = '', public status = 'hide', public expand = false, public readonly= false) {
  }
}
