import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-accordion',
  styleUrls: [ 'custom-accordion.component.scss' ],
  template: `
      <mat-accordion>
          <mat-expansion-panel class="custom-expansion-panel" style="box-shadow: unset; border-top: 1px solid #D7DAE2; border-radius: 0" hideToggle="true"
                               (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false">
              <mat-expansion-panel-header style="padding: 0">
                  <mat-panel-title style="display: block">
                      <mat-icon
                              style="color: #78849E; vertical-align: middle">{{panelOpenState ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                      </mat-icon>
                      <span style="margin-left: 20px; vertical-align: middle">{{title}}</span>
<!--                      <ng-content select="[title]"></ng-content>-->
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-content select="[content]"></ng-content>
          </mat-expansion-panel>
      </mat-accordion>
  `
})
export class CustomAccordionComponent {

  @Input() title: string;
  panelOpenState: boolean;

}
