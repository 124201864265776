import { Component, ElementRef, Input, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth-service.service';
import * as ChartsEmbedSDK from 'src/assets/libs/charts-embed-dom.umd.min.js';
import { FilterData } from '../../models/MgChart/FilterData';
import { TenantSettingsService } from '../../services/tenant-settings.service';

interface ChartSettings{
  baseUrl: string;
  solicitudeLifeCycleChart: string;
  dashboardCharts: string[];
}
 
/**
 * Component to render the MongoDB Charts using the library   
 * MongoDB Charts Embedding SDK
 * https://www.npmjs.com/package/@mongodb-js/charts-embed-dom
 * Example of use:
 * <mg-chart></mg-chart>
 */
@Component({
  selector: 'mg-chart',
  templateUrl: './mg-chart.component.html',
  styleUrls: ['./mg-chart.component.scss']
})
export class MgChartComponent  {
  
  //Declare Input vars

  @Input() solicitudeId: string = ''; //If this imput has some value, then the component render only the chart for Life Cycle of solicitude
  
  @Input() filter: FilterData = {}; // Input the filters to apply

  //Declare other vars
  @ViewChildren('chartCommon') chartCommonElements: QueryList<ElementRef>;

  @ViewChild('chartLifeCycle') chartLifeCycleElement: ElementRef;
   
  private embeddingSdk: any = null; //This var must be initialize with ChartsEmbedSDK

  settings: ChartSettings = {
    baseUrl:'',
    dashboardCharts: [],
    solicitudeLifeCycleChart: ''
  } 

  constructor(private readonly _auth: AuthService,
              private readonly _sett: TenantSettingsService) { }

  async ngAfterViewInit() {

    const auth = this._auth;
    this._sett.loadSettingsCharts().subscribe(
      (data: any) => {
        
        this.settings = {
          baseUrl: data.BASE_URL,
          solicitudeLifeCycleChart: data.SOLICITUDE_LIFE_CYCLE,
          dashboardCharts: data.DASHBOARD_CHARTS
        };

        this.embeddingSdk = new ChartsEmbedSDK({

          baseUrl: this.settings.baseUrl,
          getUserToken: async function () {
    
            return  await auth.accessToken;;
          }
        });
        
        if(this.solicitudeId){

          this.renderCharts();
        }else{
          this.chartCommonElements.changes.subscribe(()=>this.renderCharts());        
        }
      }
    )
    
    
  }

  /**
   * Render the charts 
  */
  async renderCharts(){

   
    if (this.solicitudeId) { //This conditions is to render only the life cycle chart of solicitude

      const chartObject = this.embeddingSdk.createChart({
        chartId: this.settings.solicitudeLifeCycleChart,
        filter: { "documentId": this.solicitudeId }
      });

      await chartObject.render(this.chartLifeCycleElement.nativeElement);
     
    } else { //This conditions is to render the charts of dashboard

      this.chartCommonElements.forEach(
        (chartElement:ElementRef,index:number) => {
          
          const queryFilters = this.applyFilter();
          const chartObject = this.embeddingSdk.createChart({
            chartId: this.settings.dashboardCharts[index],
            filter: queryFilters
          });
    
          chartObject.render(chartElement.nativeElement);
        }
      );
    }
    
  }

  /**
   * Detect changes to apply filter charts
   */
  async ngOnChanges(changes: SimpleChanges) {
    
    if (this.embeddingSdk && changes.hasOwnProperty('filter')) { //To exec only if sdk is loaded and input filter has been changed

      this.renderCharts();
    }
  }

  /***
   * Apply filter after update Input Filter
   */
  applyFilter(){
    
    let result = {};
    const query = {
      '$and': []
    };
    
    if(this.filter.agentIds && this.filter.agentIds.length > 0){

      query['$and'].push({
        'agentId': { $in:this.filter.agentIds}
      });
    }

    if(this.filter.productIds && this.filter.productIds.length > 0){

      query['$and'].push({
        'productId': { $in:this.filter.productIds}
      });
    }

    if(this.filter.initialDate && this.filter.finalDate){

      query['$and'].push({
        '$and': [
          {
            date: {$gte: new Date(this.filter.initialDate)}
          },
          {
            date: {$lte: new Date(this.filter.finalDate)}
          }
        ]
      });
    }
    if(query['$and'].length > 0){
      result = query;
    }
    
    return result;
  }

}
