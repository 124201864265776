import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

/**
 * Directive to apply a height to element base on window innerHeight
 */
@Directive({ selector: '[dynamicHeight]' })
export class DynamicHeightDirective {

  private _applyHeight: boolean = false; //Option to enable or disable the dynamicHeigth 
  private _offset: number = 0; //Input option to use a custom offset

  @Input() set offset(value: number) {
    
    this._offset = value;
    this.setHeight();
  }

  @Input() set applyHeight(value: boolean) {
    
    this._applyHeight = value;
    this.setHeight();
  }
  
  constructor(private readonly _element: ElementRef, private readonly _render: Renderer2) {

    this.setHeight();
  }

  setHeight() {
    
    if(this._applyHeight){

      this._render.setStyle(this._element.nativeElement, 'height', (window.innerHeight - this._offset) + 'px');
    }else{

      this._render.removeStyle(this._element.nativeElement, 'height');
    }
  }
}
