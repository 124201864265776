import { User } from './index';

export class Priority {
  formattedValue: string;
  value: number;
  color: string;
}

export class AssignedTask {
  id: string;
  name: string;
  date: string;
  users: User[];
  priority: Priority;
}

export class GroupTask {
  //#region Required properties JSON
  name: string;
  phase: string;
  subPhase: string;
  tasks: AssignedTask[];
  //#endregion
  isOpened: boolean;
}

export class Task {
  //#region Required properties JSON
  id: string;
  title: string;
  description: string;
  groupTask: GroupTask[];
  //#endregion
}
