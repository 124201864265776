import { Component, Input, OnInit, forwardRef, ViewChild, ElementRef, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT } from '@angular/platform-browser';

interface FontStyles {

  bold: boolean;
  italic: boolean;
  underline: boolean;
};

const Regex = {
  htmlBold: /<b>|<\/b>/g,
  htmlItalic: /<i>|<\/i>/g,
  htmlEndline: /<br>|<\/div>|<\/p>/g,
  htmlUnderline: /<u[^>]*>|<\/u>/g,
  htmlImage: /<img.*?src="(.*?)".*?>/g,
  htmlClearTags: /<(?!u|\/u).*?>/g,
  mdBold: /\*\*([^<]+)\*\*/g,
  mdItalic: /_([^<]+)_/g,
  mdEndline: /\n/g,
  mdImage:  /!\[(.*?)\]\((.*?)\)/g
};

/**
 * Wysiwyg custom component
 * with ControlValueAccessor so that it can be used in Reactive Forms 
 * Example of use:
 * <input-richtext-editor [(ngModel)]="editorText" [disabled]="false" [useMarkdonw]="true" ></input-richtext-editor>
 */
@Component({
  selector: 'input-richtext-editor',
  templateUrl: './input-richtext-editor.component.html',
  styleUrls: ['./input-richtext-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRichTextEditorComponent),
      multi: true
    }
  ]
})
export class InputRichTextEditorComponent implements OnInit, ControlValueAccessor {

  // #region Inputs 

  @Input() label: string = '';

  @Input() useMarkdown: boolean = false;

  @Input() disabled: boolean = false;

  @Input() placeholder: string = '';

  // #endregion


  // #region Variables

  @ViewChild('editor') editor: ElementRef<HTMLInputElement>;

  currentValue: string = '';

  public currentStyle: FontStyles = {
    bold: false,
    italic: false,
    underline: false
  };

  // #endregion

  constructor(@Inject(DOCUMENT) protected _document: Document) {
  }

  ngOnInit(): void {

    this.editor.nativeElement.addEventListener('input', this.onEditorInput.bind(this), false);

    this._document.addEventListener('selectionchange', this.onEditorSelectionChange.bind(this));
  }

  onChange = (_: any) => { }

  onTouch = () => { }

  writeValue(value: string): void {

    if (this.editor) {

      if (this.useMarkdown) {

        value = this.markdownToHtml(value);
      }

      this.currentValue = value;
      this.editor.nativeElement.innerHTML = value;
    }
  }

  registerOnChange(fn: any): void {

    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {

  }

  // #region Applies formats to the text

  onApplyBold() {

    this.editor.nativeElement.focus();

    //TODO find another alternative to execCommand
    this._document.execCommand('bold', false, null);
  }

  onApplyItalic() {

    this.editor.nativeElement.focus();

    //TODO find another alternative to execCommand
    this._document.execCommand('italic', false, null);
  }

  onApplyUnderline() {

    this.editor.nativeElement.focus();
    
    //TODO find another alternative to execCommand
    this._document.execCommand('underline', false, null);
  }

  onClearFormat() {
    
    const currentContent = this.editor.nativeElement.innerHTML;
    let clearedContent = '';
    clearedContent = currentContent.replace(Regex.htmlBold, '');
    clearedContent = clearedContent.replace(Regex.htmlItalic, '');
    clearedContent = clearedContent.replace(Regex.htmlUnderline, '');
    clearedContent = clearedContent.replace(Regex.htmlClearTags, '');

    this.editor.nativeElement.innerHTML = clearedContent;
    this.editor.nativeElement.focus();
    this.currentValue = clearedContent;
    this.onChange(clearedContent);
  }

  onApplyImage() {

    //For now current method is only for testing purposes
    let imageUrl = prompt('Enter image URL');
    if (imageUrl) {
      this.editor.nativeElement.focus();
      this._document.execCommand('insertImage', false, imageUrl);
      this.editor.nativeElement.focus();
    }
  }

  // #endregion

  // #region Editor events

  onEditorSelectionChange() {

    if (!this.disabled) {

      //Remove active class from buttons
      this.currentStyle.bold = false;
      this.currentStyle.italic = false;
      this.currentStyle.underline = false;

      if (this.editor.nativeElement.contains(window.getSelection().anchorNode.parentNode)) {

        this.findParentTagActive(window.getSelection().anchorNode.parentNode);
      }
    }
  }

  /**
   * Find parents of elements and determine current tag
  */
  findParentTagActive(element) {

    if (element && element.classList && !element.classList.contains(this.editor.nativeElement.className)) {

      // active by tag names
      let tagName = element.tagName.toLowerCase();

      switch (tagName) {

        case 'b':

          this.currentStyle.bold = true;
          break;
        case 'i':

          this.currentStyle.italic = true;
          break;
        case 'u':

          this.currentStyle.underline = true;
          break;
      }

      return this.findParentTagActive(element.parentNode);
    }
  }

  onEditorInput() {

    if (this.editor.nativeElement.innerHTML.length > 0) {

      let value = this.editor.nativeElement.innerHTML;
      
      if (this.useMarkdown) {

        value = this.htmlToMarkdown(value);
      }

      this.currentValue = value;
      this.onChange(value);
    }
    else {

      this.currentValue = '';
      this.onChange('');
    }
  }

  // #endregion

  // #region CustomFunctions

  private htmlToMarkdown(html: string): string {

    let result = '';

    if (html) {

      result = html.replace(Regex.htmlBold, '**');
      result = result.replace(Regex.htmlItalic, '_');
      result = result.replace(Regex.htmlImage, '![]($1)');
      result = result.replace(Regex.htmlEndline, '\n');
      result = result.replace(Regex.htmlClearTags, '');
    }
    
    return result;
  }

  private markdownToHtml(text: string): string {

    let result = '';

    if (text) {

      result = text.replace(Regex.mdBold, '<b>$1</b>');
      result = result.replace(Regex.mdItalic, '<i>$1</i>');
      result = result.replace(Regex.mdEndline, '</br>');
      result = result.replace(Regex.mdImage, '<img alt="$1" src="$2">');
    }

    return result;
  }

  // #endregion
}
