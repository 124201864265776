import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from '../../core/services/base-service.service';

@Injectable()
export class BrowserService extends BaseService {
  private _browserMap = new Map();
  private _browserGrid$ = new Subject<any[]>();
  private _selectedItemId$ = new BehaviorSubject(null);

  get browserMap(): Map<any, any> {
    return this._browserMap;
  }

  set browserMap(value: Map<any, any>) {
    this._browserMap = value;
  }

  get browserGrid$(): Subject<any[]> {
    return this._browserGrid$;
  }

  get selectedItemId$(): BehaviorSubject<any[]> {
    return this._selectedItemId$;
  }

  getGrid(): Observable<any> {
    return this.browserGrid$;
  }
}
