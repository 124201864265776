import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-side-element',
  templateUrl: './custom-side-element.component.html',
  styleUrls: [ './custom-side-element.component.scss' ]
})
export class CustomSideElementComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() subtitleBackground: string;
  @Input() src: string;
  @Input() comment: string;
  @Input() titleButton: TitleButton;
  @Input() functionValue: any;
  @Input() buttonDisabled = false;

  _icon: string;
  @Input('icon')
  set icon(value) {
    if (typeof value === 'string') {
      this._icon = value;
    } else {

      switch (value) {
        case 0 :
        case 1 :
        case 2 :
        case 4 :
        case 5 :
        case 6 :
        case 7 :
        case 8 : {
          this._icon = 'assignment';
          break;
        }
        case 3 : {
          this._icon = 'cancel';
          break;
        }
        case 9 : {
          this._icon = 'account_circle';
          break;
        }
        default: {
          this._icon = 'assignment';
          break;
        }
      }
    }
  }

  _time: string;
  @Input('time')
  set time(value) {
    this._time = moment(value).format('HH:mm');
  }

  @Input() solicitud: string;
  @Input() sujeto: string;

}

export class TitleButton {
  constructor(public label: string, public fn: any) {
  }
}


