import { ApplicationType } from '../enums';

export class Application {
  //#region Required properties JSON
  id: string;
  title: string;
  iconUrl: string;
  materialIcon: string;
  type: ApplicationType;
  url: string;
  //#endregion
}
