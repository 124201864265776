import { FoldersFound } from '../../../modules/agents/components/agent-inactivity/agent-inactivity-modal.component';

export function mapFoldersToRows(): void {
  this.genericRowsArray = this.foldersFounder.map((folder: FoldersFound) => ({
    unMappedElement: folder,
    id: folder.id,
    type: folder.groupType,
    title: folder.name,
    text1: folder.agent && folder.agent.name ? folder.agent.name : '',
    text2: folder.agent && (folder.agent.active ? 'Activo' : 'Inactivo'),
    text3: folder.description,
    text4: folder.hierarchyPath && folder.hierarchyPath.length ? folder.hierarchyPath.join(' > ') : ''
  } as GenericRow));
}

export enum RowType {
  FOLDER = 0,
  MAILBOX = 1,
  PRODUCT = 2,
}

export class GenericRow {
  id?: string;
  unMappedElement?: any;
  isChecked: boolean;
  elementType: any;
  type: number;
  title: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  rowType: number;

  toString = function () {
    return `${ this.title } ${ this.text3 }`;
  };
}
