import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomTable } from './custom-table';
import { EngineTable, TableConfig } from './table-config.interface';
import { MatSort } from '@angular/material';
import { asap } from 'rxjs/internal/scheduler/asap';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-custom-table-v2',
  templateUrl: 'custom-table-v2.component.html',
  styleUrls: ['custom-table-v2.component.scss']
})

export class CustomTableV2Component extends CustomTable implements EngineTable {

  @ViewChild (CdkVirtualScrollViewport) scrollContainer: CdkVirtualScrollViewport

  @Input() set source(config: TableConfig) {
    this._initSource(config);
  }

  @Input() set filter(search: string) {
    this._searchFor(search);
  }

  get filter() {
    return this.dataSource.filter;
  }

  @Output() scrollIndex = new EventEmitter<number>();

  @ViewChild(MatSort) set sortCmp(s: MatSort) {
    this.sort = s;
  }

  sortData($event: any): void {

    const sortFn = this.configuration.columns.find(c => c.field === $event.active).sortFn;

    if(sortFn) {

      this.dataSource.data = this.configuration.source.sort((a, b) => sortFn(a, b, $event));
    }
  }

  /**
   * To add an extra option as template... for example to include the chart life cycle of solicitude
  */
  @Input() extraOptionTemplate: TemplateRef<any> | ViewContainerRef ;

  /**
   * Emits each time scroll is moved.
   * @param index Row index hidden by scroll
   */
  public scrolling(index: any): void {
    this.scrollIndex.emit(index);
  }

  /**
   * Implementación de función abstracta.
   * @param row Registro de la tabla
   * @private
   */
  protected _isRowValid(row: [string, any]): boolean {
    return false;
  }

  /**
   * Implementación de función abstracta.
   * @private
   */
  protected _adjust(): void {
    asap.schedule(
      () => {
        this.dataSource.data = this.dataSource.data.concat(this.configuration.source)
      }, 
    200);
  }

  /**
   * Implementación de función abstracta.
   * @private
   */
  protected _sortColumns(): void {
    const columns = this.configuration.columns.map(c => c.field);
    if (Array.isArray(this.configuration.menu) && this.configuration.menu.length) {
      this.columns = [columns[0], this.MENU_PROP_NAME, ...columns.slice(1)];
    } else {
      this.columns = columns;
    }
  }
}

