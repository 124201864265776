import { EnvService } from '../services/env.service';

export const EnvServiceFactory = () => {
  const env = new EnvService();

  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};
  Object.assign(env, browserWindowEnv);

  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
