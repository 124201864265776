import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsightsListComponent } from './pages/insights-list/insights-list.component';
import { MaterialModule } from 'src/app/core/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InsightsRoutingModule } from './insights-routing.module';
import { InsightsLayout } from './insights.layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    InsightsLayout,
    InsightsListComponent
  ],
  imports: [
    SharedModule,
    MaterialModule.forRoot(),
    CommonModule,
    InsightsRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: []
})
export class InsightsModule {
}
