import { Component, Input, OnInit } from '@angular/core';
import { CustomModal } from '../CustomModal';

@Component({
  selector: 'scc-confirmation-modal',
  template: `
      <app-modal-base [message]="message" [title]="title">
          <scc-custom-button kind="confirm" (customClick)="yes()" mat-dialog-close>{{ confirmText }}</scc-custom-button>
          <scc-custom-button kind='cancel' (customClick)="no()" mat-dialog-close>{{ cancelText }}</scc-custom-button>
      </app-modal-base>
  `,
  styles: [
    'scc-custom-button { float: right; margin: 0 10px; }'
  ]
})

export class ConfirmationModalComponent extends CustomModal<any> implements OnInit {
  confirmText: string;
  cancelText: string;

  message: string;
  yesAction: Function;
  noAction: Function;
  alwaysAction: Function;

  ngOnInit() {
    const { yes, no, always } = this.data.actions;
    this.yesAction = yes;
    this.noAction = no;
    this.alwaysAction = always;

    this.confirmText = this.data.confirmText ? this.data.confirmText : 'Sí';
    this.cancelText = this.data.cancelText ? this.data.cancelText : 'No';
  }

  yes(): void {
    if (this.yesAction) this.yesAction();
    this.always();
  }

  no(): void {
    if (this.noAction) this.noAction();
    this.always();
  }

  always(): void {
    if (this.alwaysAction) this.alwaysAction();
  }
}

export interface ConfirmationModalActions {
  yes?: () => void;
  no?: () => void;
  always?: () => void;
}
