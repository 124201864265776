import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-card',
  templateUrl: './custom-side-card.component.html',
  styleUrls: ['./custom-side-card.component.scss']
})
export class CustomSideCardComponent {
  @Input() title: string;
  @Input() titleClass: string;
}
