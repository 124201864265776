import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { FunctionsService } from '../../shared/services/functions.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth-service.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { EnvService } from '../services/env.service';

// #region Constants

const BEARER = 'Bearer';
const AUTHORIZATION_KEY = 'Authorization';
const OCP_APIM_SUBSCRIPTION_KEY = 'Ocp-Apim-Subscription-Key';

// #endregion

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  
  private readonly HTTP_UNAUTHORIZED = 401;
  private readonly HTTP_FORBIDDEN = 403;
  private readonly TIME_OUT_ALERTS:number = 10;
  
  constructor(private readonly _auth: AuthService, 
              private readonly functionsService: FunctionsService,
              private readonly _loader: LoaderService,
              private readonly router: Router,
              private readonly _alerts: AlertService,
              private readonly _env:EnvService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(this.isValidForInterceptor(req.url)){
      
      return this._auth.tokenValue().pipe(
        catchError(
          (error)=>{

            this.stopLoader();
            setTimeout(() => {

              this._alerts.error(error,true);
            }, this.TIME_OUT_ALERTS); //It is important to show the alert, because the navigateUrl kill the alerts
            
            this.goLogin();
            return throwError(error);
          }
        ),
        filter(token => token != null),
        take(1),
        switchMap(
          (token) => {
            
            const currentHeaders = req.headers.keys().reduce((headers, key) => {
              headers[key] = req.headers.get(key);

              return headers;
            }, {});

            const headers = new HttpHeaders({
              [AUTHORIZATION_KEY]: `${BEARER} ${ token }`,
              [OCP_APIM_SUBSCRIPTION_KEY]: this._env.SUBSCRIPTION_KEY,
              ...currentHeaders
            });

            const authReq = req.clone({ headers });
            return next.handle(authReq).pipe(
              catchError((error: any) => {
                
                this.stopLoader();
                if (error instanceof HttpErrorResponse && error.status === this.HTTP_UNAUTHORIZED) {
                  
                  setTimeout(() => {
                    
                    this._auth.showAuthorizationError();
                  }, this.TIME_OUT_ALERTS); //It is important to show the alert, because the navigateUrl kill the alerts
                  
                  //this.goLogin(); is important remove to avoid redirect and show explcit error message
                }
                return throwError(error);
              })
            );
          }
        )
      );

    }else{

      return next.handle(req);
    }
    
  }

  private isValidForInterceptor(url:string):boolean{
    
    return  url.startsWith(this._env.BASE_URL);
  }

  private stopLoader(){

    this.functionsService.loader(false);
    this._loader.hide();
  }

  private goLogin(){

    this.router.navigateByUrl(this._env.OFFICE365.REDIRECT);;
  }
}
