import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { URLS_SETTINGS } from 'src/app/configs/urls.config';
import { PermissionsService } from '../../../core/services/permissions.service';
import { CATALOGS, URLS_ENUM } from '../../../core/enums/catalogs.enum';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MainMenuComponent implements OnInit {
  @Output()
  itemClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  URLS = URLS_SETTINGS;
  CATALOGS = CATALOGS;
  URLS_ENUM = URLS_ENUM;

  constructor(private router: Router, public permissionsService: PermissionsService) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.itemClicked.emit(true);
      }
    });
  }
}
