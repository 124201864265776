import { Injectable } from '@angular/core';
import { IBrowserItem } from 'src/app/shared/components/browser/browser-item';
import { IBrowserItemAction } from 'src/app/shared/components/browser/browser-item-action';
import { Subject } from 'rxjs';

const TYPE_FOLDER = 0;
const TYPE_INBOX = 1;

@Injectable({
  providedIn: 'root'
})
export class Browser2Service {

  finishedHierarchy = new Subject<boolean>();

  constructor() {
  }

  public buildItemsProducts(definition, browserActions: Array<IBrowserItemAction>): Array<IBrowserItem> {
    const items: Array<IBrowserItem> = [];
    if (definition !== null) {
      Object.values(definition.containers).forEach((def: any) => {
        const childrens = this.childrens(definition, def);
        items.push({
          id: def.containerId,
          parent: def.parentId,
          childrens: childrens,
          title: def.title,
          description: this.description(def),
          icon: null,
          actions: null,
          root: this.checkRoot(def),
          channels: this.channels(def)
        });
      });
      this.buildMenus(items, browserActions);
    }
    return items;
  }

  private buildMenus(items: Array<IBrowserItem>, browserActions: Array<IBrowserItemAction>) {
    let actions: Array<IBrowserItemAction>;
    items.forEach(item => {
      const level = this.checkLevel(item, items);
      if (level !== 3) {
        actions = browserActions.filter(a => !a.label.includes('Crear formulario') && !a.label.includes('Ver formulario'));
        item.icon = 'folder';
      } else {
        actions = browserActions;
        item.icon = 'assignment';
      }
      item.actions = actions;
    });
  }

  public checkLevel(item: IBrowserItem, items: Array<IBrowserItem>): number {
    let i = 0;
    do {
      item = this.findParent(items, item);
      i++;
    } while (item !== undefined);

    return i - 1; // es de nivel especificado
  }

  private findParent(items: Array<IBrowserItem>, item: IBrowserItem) {
    return items.find((i: IBrowserItem) => {
      return item !== undefined ? i.id === item.parent : false;
    });
  }

  private checkRoot(def): boolean {
    return !def.parentId;
  }

  private description(def: any): string {
    let status = '';
    if (def.customStatusInfo !== undefined) {
      def.customStatusInfo.forEach((value, number) => {
        if (number === def.customStatus) {
          status = value.description;
        }
      });
    }
    return status;
  }

  private channels(def: any): Array<string> {
    const channels: Array<string> = [];
    if (def.customStatusInfo !== undefined) {
      def.customStatusInfo.forEach((value, number) => {
        if (number === def.customStatus && value.actions.edit !== undefined) {
          value.actions.edit.channelIds.forEach(channel => {
            channels.push(channel);
          });
        }
      });
    }
    return channels;
  }

  private childrens(definition, def): Array<string> {
    const node = this.findNode(def.containerId, definition.structure);
    const childs: Array<string> = [];
    if (node.containers !== undefined) {
      node.containers.forEach((value) => {
        childs.push(value.containerId);
      });
    }
    return childs;
  }

  private findNode(id, structure) {
    let i;
    let currentChild;
    let result;

    if (id === structure.containerId) {
      return structure;
    } else {
      if (structure.containers !== undefined) {
        for (i = 0; i < structure.containers.length; i += 1) {
          currentChild = structure.containers[i];
          result = this.findNode(id, currentChild);
          if (result !== false) {
            return result;
          }
        }
      }
      return false;
    }
  }

  checkItem(current: Array<IBrowserItem>, definition, browserActions: Array<IBrowserItemAction>): IBrowserItem {
    let actions: Array<IBrowserItemAction>;
    let obj: any;
    const objects = Object.values(definition.containers);
    if (current.length >= 1) {
      obj = objects.find((d: any) => {
        const l = current.find(c => {
          return d.containerId === c.id;
        });
        return l === undefined;
      });
    } else {
      obj = objects[0];
    }
    const item: IBrowserItem = {
      id: obj.containerId,
      parent: obj.parentId,
      childrens: [],
      title: obj.title,
      description: this.description(obj),
      icon: null,
      actions: null,
      root: !obj.parentId,
      channels: []
    };
    const i = this.checkLevel(item, current);
    if (i !== 3) {
      actions = browserActions.filter(a => a.label !== 'Crear formulario');
      item.icon = 'folder';
    } else {
      actions = browserActions;
      item.icon = 'assignment';
    }
    item.actions = actions;
    return item;

  }

  buildItemsHierarchy(dataBrowser: any, browserActions: Array<IBrowserItemAction>): Array<IBrowserItem> {
    const items: Array<IBrowserItem> = [];
    dataBrowser.forEach(def => {
      const actions = browserActions.filter(action => {
        return !action.hidden(def.id);
      });
      let iconName;
      let acts = [];
      if (def.groupType === 1) {
        iconName = 'markunread_mailbox';
        acts = actions.filter((act) => act.type === TYPE_INBOX);
      } else {
        iconName = def.agent !== undefined ? 'folder_shared' : 'folder_open';
        acts = actions.filter((act) => act.type === TYPE_FOLDER);
      }
      items.push({
        id: def.id,
        title: def.name,
        actions: acts,
        channels: [],
        childrens: def.children ? def.children : [],
        description: def.groupType ? 'Buzón' : this.getAgentName(def.agent),
        icon: iconName,
        parent: def.parent[def.parent.length - 1],
        root: !def.parent.length
      });
    });
    this.finishedHierarchy.next();
    return items;
  }

  private getAgentName(agent: any): string {
    if (agent) {
      return agent.nombreCompleto;
    } else {
      return 'Sin responsable';
    }
  }

  public buildItemsExecutionPlan(definition): Array<IBrowserItem> {
    const items: Array<IBrowserItem> = [];
    if (definition !== null) {
      definition.forEach((def: any) => {
        items.push({
          id: def.containerId,
          parent: def.parents.length >= 1 ? def.parents[def.parents.length - 1] : undefined,
          childrens: def.childrens,
          title: def.title,
          description: this.description(def),
          icon: 'radio_button_unchecked',
          actions: [],
          root: this.checkRoot(def),
          channels: this.channels(def)
        });
      });
    }
    return items;
  }

}
