import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { GeoLocation, InstanceContainer, ReferenceChange } from 'src/app/shared/models';
import { DataTransfer } from 'src/app/shared/enums';
import { FieldComment } from 'src/app/shared/models/field-comment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import {
  NotificationsComponent,
  NotificationsComponentData,
  NotificationType
} from 'src/app/shared/components/notifications/notifications.component';
import { PopupButtonsResult, PopupData } from 'src/app/shared/index';
import { TranslateService } from '@ngx-translate/core';
import { CommentDialogData, CommentDialogResult } from 'src/app/shared/components/pop-up/comment-dialog/comment-dialog.component';
import { URLS_SETTINGS } from '../../configs/urls.config';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  // Event start
  public listCommentsEvent: EventEmitter<[string, string, FieldComment[]]>;
  public loaderEvent: EventEmitter<boolean>;
  public openPopupEvent: EventEmitter<PopupData>;
  public openPopupResult: EventEmitter<PopupButtonsResult>;
  public openCommentDialogEvent: EventEmitter<CommentDialogData>;
  public openCommentDialogResult: EventEmitter<CommentDialogResult>;
  public textToSearchEvent: EventEmitter<boolean>;
  public updatedSelectedContainerEvent: EventEmitter<boolean>;
  public updatedSelectChildEvent: EventEmitter<[string, number, string, string[]]>;
  public updateSubSectionEvent: EventEmitter<ReferenceChange>;
  toggleLoader = new Subject();
  toggleForceLoader = new Subject();
  private _location = new BehaviorSubject<GeoLocation>(new GeoLocation);

  // Event end

  constructor(private http: HttpClient, private router: Router, private datePipe: DatePipe, private snackBar: MatSnackBar,
    private translateService: TranslateService) {
    this.listCommentsEvent = new EventEmitter<[string, string, FieldComment[]]>();
    this.loaderEvent = new EventEmitter<boolean>();
    this.openPopupEvent = new EventEmitter<PopupData>();
    this.openPopupResult = new EventEmitter<PopupButtonsResult>();
    this.openCommentDialogEvent = new EventEmitter<CommentDialogData>();
    this.openCommentDialogResult = new EventEmitter<CommentDialogResult>();

    this.textToSearchEvent = new EventEmitter<boolean>();
    this.updatedSelectChildEvent = new EventEmitter<[string, number, string, string[]]>();
    this.updatedSelectedContainerEvent = new EventEmitter<boolean>();
    this.updateSubSectionEvent = new EventEmitter<ReferenceChange>();
  }

  openPopup(data: PopupData) {
    this.openPopupEvent.emit(data);
  }

  loader(isActive: boolean) {
    this.toggleLoader.next(new BehaviorSubject(isActive));
  }

  forceLoader(isActive: boolean) {
    this.toggleForceLoader.next(new BehaviorSubject(isActive));
  }

  toggleLoaderEvent(): Observable<any> {
    return this.toggleLoader;
  }

  toggleForceEvent(): Observable<any> {
    return this.toggleForceLoader;
  }

  /**
   * Clone a JavaScript Object Notation (JSON) With Obvservable
   * @param json Element to clone
   */
  cloneJsonOF = (json: object) => of(JSON.parse(JSON.stringify(json)));

  /**
   * Clone a JavaScript Object Notation (JSON).
   * @param json Element to clone
   */
  cloneJson = (json: object) => JSON.parse(JSON.stringify(json));

  /**
   * Saves the value in localstorage
   * @param key Key to identify the value to save
   * @param value Value to save
   */
  saveValueInLocalStorage(key: DataTransfer, value: string) {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  }

  /**
   * Recover localstorage value
   * @param key Key to identify the value to recover
   */
  recoverValueStorage(key: DataTransfer): string {
    return JSON.parse(localStorage.getItem(key.toString())) as string;
  }

  /**
   * Remove localstorage value
   * @param key Key to identify the value to recover
   */
  removeValueStorage(key: DataTransfer): void {
    localStorage.removeItem(key.toString());
  }

  /**
   * Get the formatted date
   * @param date
   * @param format use a standard format
   * https://angular.io/api/common/DatePipe#pre-defined-format-options
   */
  getFormattedDate(date: Date, format: string = 'dd-MM-yyyy') {
    return this.datePipe.transform(date, format);
  }

  /**
   * Missing comments
   */
  textToSearch(search: string) {
    this.saveValueInLocalStorage(DataTransfer.QUESTION, search);
    if (this.router.url !== URLS_SETTINGS.ADMINISTRATION.SEARCH) {
      this.router.navigate([URLS_SETTINGS.ADMINISTRATION.SEARCH]);
    } else {
      this.textToSearchEvent.emit(true);
    }
  }

  /**
   * Missing comments
   */
  fastSearch(search: string, option: string) {

    this.saveValueInLocalStorage(DataTransfer.QUESTION, search);
    this.saveValueInLocalStorage(DataTransfer.OPTION, option);

    if (this.router.url !== URLS_SETTINGS.ADMINISTRATION.FAST_SEARCH) {
      this.router.navigate([URLS_SETTINGS.ADMINISTRATION.FAST_SEARCH]);
    } else {
      this.textToSearchEvent.emit(true);
    }
  }

  /**
   *
   * @param obj
   */

  /**
   * Missing comments
   */
  isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0;
  }

  /**
   * Missing comments
   */
  updatedSelectedContainer() {
    this.updatedSelectedContainerEvent.emit(true);
  }

  /**
   * Missing comments
   */
  updatedSelectChild(fullName: string, selectionIndex: number, key: string, array: string[]) {
    this.updatedSelectChildEvent.emit([fullName, selectionIndex, key, array]);
  }

  /**
   * Missing comments
   */
  getStatusByContainer(container: InstanceContainer) {
    let result = '';
    if (container && container.customStatusDescription && container.customStatusDescription.length) {
      if (0 <= container.customStatus && container.customStatus < container.customStatusDescription.length) {
        result = container.customStatusDescription[container.customStatus];
      } else {
        result = container.customStatusDescription[0];
      }
    }
    return result;
  }

  /**
   * Missing comments
   */
  evalFunction(body: string, values: any) {
    let result: any = null;
    if (values && values.length && Array.isArray(values)) {
      try {
        let toEvaluate = body;
        if (body.indexOf('_v_') !== -1) {
          toEvaluate = 'var _v_ = ' + JSON.stringify(values) + '; ' + body;
        }
        result = eval(toEvaluate);
      } catch (ex) {
        console.error(ex);
      }
    } else if (values && typeof values === 'object' && values.length) {
      try {
        let toEvaluate = body;
        if (body.indexOf('_v_') !== -1) {
          toEvaluate = 'var _v_ = ' + JSON.stringify(values[0]) + '; ' + body;
        }
        if (body.indexOf('_w_') !== -1) {
          toEvaluate = 'var _w_ = ' + JSON.stringify(values[1]) + '; ' + body;
        }
        if (body.indexOf('_x_') !== -1) {
          toEvaluate = 'var _x_ = ' + JSON.stringify(values[2]) + '; ' + body;
        }
        if (body.indexOf('_y_') !== -1) {
          toEvaluate = 'var _y_ = ' + JSON.stringify(values[3]) + '; ' + body;
        }
        if (body.indexOf('_z_') !== -1) {
          toEvaluate = 'var _z_ = ' + JSON.stringify(values[4]) + '; ' + body;
        }
        result = eval(toEvaluate);
      } catch (ex) {
        console.error(ex);
      }
    } else {
      try {
        let toEvaluate = body;
        if (body.indexOf('_v_') !== -1) {
          toEvaluate = 'var _v_ = ' + JSON.stringify(values) + '; ' + body;
        }
        result = eval(toEvaluate);
      } catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  /**
   * Missing comments
   */
  b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(parseInt('0x' + p1, 16));
    })
    );
  }

  /**
   * Missing comments
   */
  b64DecodeUnicode(str: string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    try {
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join('')
      );
    } catch {
      return atob(str);
    }
  }

  showInfoMessage(message: string, isI18nMessage: boolean, durationInMiliseconds: number = 3000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'left', verticalPosition: MatSnackBarVerticalPosition = 'bottom'
  ) {
    this.showMessage(message, isI18nMessage, NotificationType.INFO, durationInMiliseconds, horizontalPosition, verticalPosition);
  }

  showSuccessMessage(message: string, isI18nMessage: boolean, durationInMiliseconds: number = 3000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'left', verticalPosition: MatSnackBarVerticalPosition = 'bottom'
  ) {
    this.showMessage(message, isI18nMessage, NotificationType.SUCCESS, durationInMiliseconds, horizontalPosition, verticalPosition);
  }

  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  extend = function (out, ...elements: any[]) {
    out = out || {};
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i]) {
        continue;
      }
      for (const key in elements[i]) {
        if (elements[i].hasOwnProperty(key)) {
          out[key] = elements[i][key];
        }
      }
    }
    return out;
  };

  deepExtend = function (out, ...elements: any[]) {
    out = out || {};
    for (let i = 0; i < elements.length; i++) {
      const obj = elements[i];
      if (!obj) {
        continue;
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object') {
            out[key] = this.deepExtend(out[key], obj[key]);
          } else {
            out[key] = obj[key];
          }
        }
      }
    }
    return out;
  };

  getTransalteText(originalText: string, textToSearch: string, textToReplace: string, isI18n: boolean = false) {
    if (isI18n) {
      this.translateService.get(textToReplace).subscribe((result: string) => {
        textToReplace = result;
      });
    }
    return originalText.replace(textToSearch, textToReplace);
  }

  strToBool(s) {
    const regex = new RegExp(/^\s*(true|1|on)\s*$/i);
    return regex.test(s);
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  setLocation(location: GeoLocation) {
    this._location.next(location);
  }

  getLocation(): Observable<GeoLocation> {
    return this._location;
  }

  decodeSpecialCharacters(s) {
    for (var a, b, i = -1, l = (s = s.split('')).length, o = String.fromCharCode, c = 'charCodeAt'; ++i < l;
      ((a = s[i][c](0)) & 0x80) &&
      (s[i] = (a & 0xfc) == 0xc0 && ((b = s[i + 1][c](0)) & 0xc0) == 0x80 ?
        o(((a & 0x03) << 6) + (b & 0x3f)) : o(128), s[++i] = '')
    ) {
      ;
    }
    return s.join('');
  }

  private showMessage(message: string, isI18nMessage: boolean, messageType: NotificationType, durationInMiliseconds: number = 3000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'left',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom') {
    const dataToTransfer: NotificationsComponentData = { message: message, isI18nMessage: isI18nMessage, messageType: messageType };
    this.snackBar.openFromComponent(NotificationsComponent, {
      duration: durationInMiliseconds,
      data: dataToTransfer,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition
    });
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 2048;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
