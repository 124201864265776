import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-administration-title',
  template: `
    <h3 [innerHTML]="title | titleStyle"></h3>
  `,
  styleUrls: ['navbar-administration-title.component.scss']
})

export class NavbarAdministrationTitleComponent implements OnInit {
  @Input() title: string;

  constructor() {
  }

  ngOnInit() {
  }
}
