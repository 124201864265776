import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';
import { asap } from 'rxjs/internal/scheduler/asap';
import { BaseCustomFilterCatalog } from 'src/app/shared/bases/catalog/BaseCustomFilterCatalog';
import { CustomColumnTable, CustomMenuOption } from 'src/app/shared/components/custom-table/custom-table.component';
import { InsightsService } from './insights.service';

@Component({
  selector: 'app-insights-list',
  templateUrl: './insights-list.component.html',
  styleUrls: ['./insights-list.component.scss'],
  providers: [InsightsService]
})
export class InsightsListComponent extends BaseCustomFilterCatalog<any> implements OnInit, AfterViewInit {
  @ViewChild('columnsSelect') columnsSelect: MatSelect;
  minFinalDate: Moment;
  maxInitialDate: Moment;
  initialValue = moment();
  finalValue = moment();
  instanceId: any;
  uid: any;
  columnsFilterNames = ['instancia'];
  columnsFilter = new Array<any>();

  protected filterPredicate(): Function {
    const columnaBuscar = this.columnsSelect.value as string;
    if (this.columnsSelect.value === 'all') return;
    return (item: {}, filter: string) => {
      const itemProp = item[columnaBuscar] as string;
      return itemProp !== undefined && itemProp.toString().toLowerCase().includes(filter.toLocaleLowerCase());
    };
  }

  constructor(public _service: InsightsService) {
    super(_service);
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    return this._service.getByDate(moment(this.initialValue).format("YYYY-MM-DD"), moment(this.finalValue).format("YYYY-MM-DD"), this.instanceId, this.uid).subscribe(data => {
      this.tableComponent.source = data.values;
    });;
  }

  saveColumnDescriptions(columns: Array<CustomColumnTable>): void {
    asap.schedule(() => this.columnsFilter = columns.filter(col=> this.columnsFilterNames.indexOf(col.field) >= 0));
  }

  initialDateSelected(date: Moment): void {
    this.minFinalDate = date;

  }

  finalDateSelected(date: Moment): void {
    this.maxInitialDate = date;
  }

  showField(event): void {

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }
}
