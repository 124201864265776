import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { HierarchyService } from '../../../../shared/services/hierarchy.service';
import { GenericRow, mapFoldersToRows } from '../../../../shared/components/folder-finder/folder-finder.utils';

@Component({
  selector: 'app-agent-inactivity-modal',
  templateUrl: 'agent-inactivity-modal.component.html',
  styleUrls: ['agent-inactivity-modal.component.scss'],
  providers: [HierarchyService]
})

export class AgentInactivityModalComponent implements OnInit, OnDestroy {
  readonly MIN_DATE = new Date();
  agentInactivityInfo: DtoAgentInactivityInfo = new DtoAgentInactivityInfo();
  foldersFounder: Array<FoldersFound> = new Array<FoldersFound>();
  selectedFolder = new FoldersFound();
  minFinalDate: Moment;
  maxInitialDate: Moment;
  genericRowsArray: Array<GenericRow> = [];

  get isFirstStepValid(): boolean {
    return !!this.agentInactivityInfo.motivo && !!this.agentInactivityInfo.motivo.trim() && !!this.agentInactivityInfo.initialValue
      && !!this.agentInactivityInfo.finalValue;
  }

  get isSecondStepValid(): boolean {
    return !!this.agentInactivityInfo.idFolderDelegada;
  }

  constructor(private _dialog: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private agentInfo: any,
              private hierarchyService: HierarchyService) {
  }

  ngOnInit() {
    if (this.agentInfo && this.agentInfo.inactivity) {
      this.agentInactivityInfo = JSON.parse(JSON.stringify(this.agentInfo.inactivity));
      this.foldersFounder.push({
        name: this.agentInactivityInfo.main,
        agent: {
          name: this.agentInactivityInfo.name,
          active: this.agentInactivityInfo.active
        },
        hierarchyPath: this.agentInactivityInfo.folders,
        id: this.agentInactivityInfo.idFolderDelegada,
        isChecked: true
      } as FoldersFound);
      mapFoldersToRows.call(this);
    }
  }

  ngOnDestroy(): void {

  }

  folderSelected(selectedFolder: FoldersFound): void {
    this.selectedFolder = selectedFolder;
    this.agentInactivityInfo.main = selectedFolder.name;
    this.agentInactivityInfo.name = selectedFolder.agent.name;
    this.agentInactivityInfo.active = selectedFolder.agent.active;
    this.agentInactivityInfo.folders = selectedFolder.hierarchyPath;
    this.agentInactivityInfo.idFolderDelegada = selectedFolder.id;
    this.agentInactivityInfo['folderId'] = selectedFolder.folder && selectedFolder.folder.folderId || null;
  }

  close(): void {
    this._dialog.close();
  }

  save(): void {
    this._dialog.close(this.agentInactivityInfo);
  }

  getFoldersByName(folderName: string): void {
    this.hierarchyService.searchByName(folderName).pipe(map(res => res.value)).subscribe(folders => {
      this.agentInactivityInfo.idFolderDelegada = null;
      this.foldersFounder = folders.filter(folder => folder.agent);
      mapFoldersToRows.call(this);
    });
  }

  initialDateSelected(date: Moment): void {
    this.minFinalDate = date;

  }

  finalDateSelected(date: Moment): void {
    this.maxInitialDate = date;
  }

}

export class DtoAgentInactivityInfo {
  motivo: string;
  initialValue: Moment;
  finalValue: Moment;
  idFolderDelegada: string;
  main: string;
  name: string;
  folders: Array<any>;
  active: boolean;
  index: any;
}

export class FoldersFound {
  groups: Array<any>;
  groupType: number;
  id: string;
  name: string;
  folder: any;
  tags: Array<any>;
  agentId: string;
  description: string;
  status: number;
  hierarchyPath: Array<any>;
  agent: any;
  instancesNumber: number;
  taskNumber: number;
  isChecked: boolean;

  toString = function () {

    return `${ this.name } ${ this.description }`;
  };
}
