import { Component, Input, TemplateRef, ViewChild,ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EnvService } from 'src/app/core/services/env.service';
import { ChartSolicitudeLifeCycleService } from './chart-solicitude-life-cycle.service';

const CSS_CONTAINER:string = 'chart-solicitude-dialog-container';
const AGENT_LABELS_KEY = 'agentlabels';

/**
 * Component to render the chart life cycle of solicitude   
 * Example of use:
 * <chart-solicitude-life-cycle solicitudeId="id"></chart-solicitude-life-cycle>
 */
@Component({
  selector: 'chart-solicitude-life-cycle',
  templateUrl: './chart-solicitude-life-cycle.component.html',
  styleUrls: ['./chart-solicitude-life-cycle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartSolicitudeLifeCycleService]
})
export class ChartSolicitudeLifeCycleComponent  {
  
  //Declare Input vars

  @Input() solicitudeId: string = ''; //If this imput has some value, then the component render only the chart for Life Cycle of solicitude
  
  //Declare vars

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  currentTab = 0;
  agentCanView = false;
  
  constructor(private readonly _dialog: MatDialog,
              public readonly _service: ChartSolicitudeLifeCycleService,
              private readonly _env: EnvService) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    try {

      const agentLabels = localStorage.getItem(AGENT_LABELS_KEY);
      if (agentLabels) {

        const tags = JSON.parse(agentLabels);
        this.agentCanView = this._env.ALLOW_VIEW_INSTANCES_HISTORY_BY_TAGS.some(tag => tags.includes(tag));
      }
    } 
    catch (error) {
      // To avoid truble if storage value can not be parsed
      this.agentCanView  = false;
    }
  }
  openChart(){

    this._service.loadStates(this.solicitudeId).subscribe();
    
    this._dialog.open(this.dialogTemplate,{
      width: '100%',
      height: '100%',
      panelClass: CSS_CONTAINER
    });
  }
}