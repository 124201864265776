import { RedirectToAction } from '../enums';

export class Notification {
  //#region Required properties JSON
  id: string;
  title: string;
  shortDescription: string;
  longDescription: string;
  taskCounter: number;
  taskDate: string;
  tags: string[];
  iconUrl: string;
  redirectToAction: RedirectToAction;
  //#endregion
}
