export class FieldsCatalogLabel {
    idetiqueta: number;
    name: string;
    description: string;
}

export interface FileldCustomCatalog {
   name: string;
   file?: any;
   chargeType?: string;
   catalogElements?: {
    parentKey?: any;
    key?: any;
    value?: any;
   };
}
