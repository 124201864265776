export class Widgets {
  //#region Required properties JSON
  id?: string;
  type: number;
  title: string;
  subtitle?: string;
  footer?: string;
  items: Widget[];
  //#endregion
}

export class Widget {
  result?: number;
  description?: string;
  barChar?: WidgetGraphic[];
  barCharValues?: number[];
  barCharColors?: string[];
}

export class WidgetGraphic {
  values: number[];
  colors: string[];
}
