import { Component, OnInit } from '@angular/core';
import { CustomModal, ICustomModal } from '../../../modules/form/components/form-alert/CustomModal';

@Component({
  selector: 'app-custom-one-input-field-modal',
  templateUrl: 'custom-one-input-field-modal.component.html'
})

export class CustomOneInputFieldModalComponent extends CustomModal<CustomOneInputFieldModal> implements OnInit {
  value: string;

  get isValid(): boolean {
    return !!this.value && !!this.value.trim();
  }

  get isRequired() {
    return this.data.isRequired;
  }

  get textAcceptance() {
    return this.data.acceptText;
  }

  get maxlength() {
    return this.data.maxlength;
  }

  ngOnInit(): void {
    this.value = this.data.value;
  }

  cancel(): void {
    if (this.data.cancel) this.data.cancel();
    this._dialog.close();
  }

  accept(): void {
    if (this.data.accept) this.data.accept(this.value);
    this._dialog.close();
  }
}

export interface CustomOneInputFieldModal extends ICustomModal {
  isRequired: boolean;
  value: string;
  maxlength: number;
  acceptText: string;
  cancel: () => void;
  accept: (value: string) => void;
}
