import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/AuthGuard';
import { ProfileResolverService } from './core/resolvers/profile.resolver';

const ROUTES: Routes = [
  {
    path: '',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
    resolve: {
      profile: ProfileResolverService
    }
  },
  {
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule'
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { useHash: false })],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
