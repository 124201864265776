import { Directive, ElementRef, Renderer2, Input, HostBinding } from '@angular/core';

@Directive({ selector: '[engineLoaderContainer]' })
export class LoaderContainerDirective {
  @Input() set defaultHeight(state: boolean) {
    this._defaultHeight = state;
    this.setHeight();
  }
  private _defaultHeight = true;

  constructor(private readonly _element: ElementRef, private readonly _render: Renderer2) {
    this._render.setStyle(this._element.nativeElement, 'position', 'relative');
    this._render.setStyle(this._element.nativeElement, 'height', '100%');
    this.setHeight();
  }

  setHeight() {
    if (this._defaultHeight) this._render.setStyle(this._element.nativeElement, 'min-height', '200px');
    else this._render.removeStyle(this._element.nativeElement, 'min-height');
  }
}
