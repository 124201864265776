import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DtoDefinition } from '../models/Definition/DtoDefinitionEngine';

@Injectable({
  providedIn: 'root'
})
export class StoragesService {
  private readonly SEPARATOR = '//';
  // KEYS
  private readonly NAVEGATION_HISTORY = 'navegation';
  private readonly BUSINESSPROCESS_HISTORY = 'businessprocess';
  private readonly PROCESS_VERSION = 'processversion';
  private readonly TABLE_PAGE_INDEX = 'tablepageindex';

  constructor(private readonly _profile: ProfileService, private readonly _localStorage: LocalStorage) {
  }

  getNavegationStorage<T>(): Observable<T> {
    return this._localStorage.getItem(`${ this.getToken() }${ this.NAVEGATION_HISTORY }`).pipe(map(state => {
      if (state) return JSON.parse(state) as T;
      return null;
    }));
  }

  setNavegationStorage(value: any): Observable<boolean> {
    return this._localStorage.setItem(`${ this.getToken() }${ this.NAVEGATION_HISTORY }`, value);
  }

  removeNavigationStorage(): Observable<boolean> {
    return this._localStorage.removeItem(`${ this.getToken() }${ this.NAVEGATION_HISTORY }`);
  }

  setTablePageIndexStorage(catalog: string, index: number): Observable<boolean> {
    return this._localStorage.setItem(`${ this.getToken() }${ this.TABLE_PAGE_INDEX }_${ catalog }`, index);
  }

  removeTablePageIndexStorage(catalog: string): Observable<boolean> {
    return this._localStorage.removeItem(`${ this.getToken() }${ this.TABLE_PAGE_INDEX }_${ catalog }`);
  }

  getTablePageIndexSelected(catalog: string): Observable<number> {
    return this._profile.userProfile$.pipe(
      filter(user => !!user),
      switchMap(() => {
        return this._localStorage.getItem(`${ this.getToken() }${ this.TABLE_PAGE_INDEX }_${ catalog }`);
      }));
  }

  setProcessVersionSelected(value: DtoDefinition): Observable<boolean> {
    return this._localStorage.setItem(`${ this.getToken() }${ this.PROCESS_VERSION }`, value);
  }

  getProcessVersionSelected(): Observable<DtoDefinition> {
    return this._profile.userProfile$.pipe(
      filter(user => !!user),
      switchMap(() => {
        return this._localStorage.getItem(`${ this.getToken() }${ this.PROCESS_VERSION }`);
      }));
  }

  removeProcessVersionSelected(): Observable<boolean> {
    return this._profile.userProfile$.pipe(
      filter(user => !!user),
      switchMap(() => {
        return this._localStorage.removeItem(`${ this.getToken() }${ this.PROCESS_VERSION }`);
      }));
  }

  setLastProcessSelected(value: any): Observable<boolean> {
    return this._localStorage.setItem(`${ this.getToken() }${ this.BUSINESSPROCESS_HISTORY }`, value);
  }

  getLastProcessSelected<T>(): Observable<T> {
    return this._profile.userProfile$.pipe(
      filter(user => !!user),
      switchMap(() => {
        return this._localStorage.getItem(`${ this.getToken() }${ this.BUSINESSPROCESS_HISTORY }`);
      }));
  }

  removeLastProcessSelected(): Observable<boolean> {
    return this._localStorage.removeItem(`${ this.getToken() }${ this.BUSINESSPROCESS_HISTORY }`);
  }

  private getToken(): string {
    return `${ this.getFolderIdWithoutDashes() }${ this.SEPARATOR }`;
  }

  private getFolderIdWithoutDashes(folderId: string = ''): string {

    if(!folderId){ //To avoid random console bug when user is logout

      const profile = this._profile.userProfile$.getValue();
      folderId =  profile ? profile.folderId : '';
    }
    return folderId.replace(new RegExp('-', 'g'), '');
  }
}
