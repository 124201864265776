import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-search-modal',
  template: `
    <h5 mat-dialog-title>Buscar</h5>
    <div mat-dialog-content>
      <mat-form-field appearance="outline" color="primary" class="focus-accent-border" floatLabel="always">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [formControl]="searchControl"/>
        <button *ngIf="searchControl.value" mat-icon-button matSuffix (click)="erase()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <div id="actions">
        <section>
          <button mat-stroked-button (click)="close()">CANCELAR</button>
        </section>
        <section>
          <button mat-button color="primary" [disabled]="searchControl.invalid" (click)="search()">BUSCAR</button>
          <button mat-button [disabled]="!canReset" (click)="reset()" tabindex="1">REINICIAR</button>
        </section>
      </div>
    </div>
  `,
  styleUrls: ['search-modal.component.scss']
})

export class SearchModalComponent implements OnInit {
  searchControl = new FormControl(undefined, [Validators.required]);

  get canReset() {
    return !!this._data.previousSearch;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: SearchModalConfig, private _dialog: MatDialogRef<any>) {
  }

  ngOnInit() {
    this.searchControl.setValue(this._data.previousSearch);
  }

  /**
   * Función que borra el campo de búsqueda.
   */
  erase(): void {
    this.searchControl.reset();
  }

  reset(): void {
    this.erase();
    this.search();
  }

  /**
   * Función que ejecuta el mecanismo de búsqueda.
   */
  search(): void {
    this._data.searchCallback(this.searchControl.value);
    this._dialog.close();
  }

  /**
   * Función que cierra la ventana de búsqueda.
   */
  close(): void {
    this._dialog.close();
  }
}

export interface SearchModalConfig {
  previousSearch?: string;
  searchCallback: (text: any) => void;
}
