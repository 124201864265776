import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoaderState } from './nonBlocking/non-blocking-loader.component';
import { LoaderType } from './loaderType.enum';

@Injectable()
export class LoaderService {
  activeLoaders = new Map<string, Subject<boolean>>();
  private _stateInfo: LoaderState;
  private _state$ = new BehaviorSubject<LoaderState>(this._stateInfo);

  get state(): BehaviorSubject<LoaderState> {
    return this._state$;
  }

  set type(type: LoaderType) {
    this._stateInfo.type = type;
  }

  set name(name: string) {
    this.activeLoaders.set(name, new Subject<boolean>());
  }

  constructor() {
    this._reset();
  }

  setLoader(name: string): Observable<boolean> {
    const newLoader = new Subject<boolean>();
    this.activeLoaders.set(name, newLoader);
    return newLoader;
  }

  getLoader(name: string): Observable<boolean> {
    const obs = this.activeLoaders.get(name);
    return obs || undefined;
  }

  show(message?: string): void {
    if (message && message.trim()) this._stateInfo.message = message;
    this._stateInfo.state = true;
    this._state$.next(this._stateInfo);
  }

  hide(): void {
    this._reset();
    this._state$.next(this._stateInfo);
  }

  private _reset(): void {
    this._stateInfo = { state: false, message: 'Cargando...', type: LoaderType.blocking } as LoaderState;
  }
}
