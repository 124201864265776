import { Component, Input, OnInit, forwardRef  } from '@angular/core';
import { AdvancedSearchPeriodsEnum } from '../../models/AdvancedSearch/DtoAdvancedSearch';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';

//Enum with the name of controls
enum PeriodFieldsForm {
  TYPE_OF_PERIOD = 'typeOfPeriods',
  INITIAL = 'initial',
  END = 'end'
}

//TInterface for input datatype
interface DatePeriodData {
  typeOfPeriods: number;
  initial: string;
  end: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const DEFAULT_CONTROL_OPTIONS = { emitEvent: false, onlySelf: true }; //Using as default options for method setValue int FormControl

/**
 * Component to encapsulate the "range of date" functionality 
 * with ControlValueAccessor so that it can be used in Reactive Forms 
 * Example of use:
 * <input-date-range [(ngModel)]="range" ></input-date-range>
 */
@Component({
  selector: 'input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrls: ['./input-date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateRangeComponent),
      multi: true
    }
  ]
})
export class InputDateRangeComponent implements OnInit,ControlValueAccessor  {
  
  //Declare inputs

  @Input() dateFormat:string = DATE_FORMAT;


  //Declare properties and constants

  readonly PERIODS: typeof AdvancedSearchPeriodsEnum = AdvancedSearchPeriodsEnum;

  private periodForm: FormGroup;
  private stopSubcription = false;

  public today = moment(new Date());
  public maxDate = moment(); //max day must be today .endOf('month');
  public minDate = moment(new Date()).add(-25, 'years');
  
  public isDisabled:boolean = false;

  onChange = (_:any) => { }
  onTouch = () => { }

  /**Get the control to period type */
  get periodType(): AbstractControl {

    return (this.periodForm && this.periodForm.controls[PeriodFieldsForm.TYPE_OF_PERIOD]) || undefined;

  }

  /**Get the control to initial date */
  get initial(): AbstractControl {

    return (this.periodForm && this.periodForm.controls[PeriodFieldsForm.INITIAL]) || undefined;

  }
  
  /** Get the control to end date */
  get end(): AbstractControl {

    return (this.periodForm && this.periodForm.controls[PeriodFieldsForm.END]) || undefined;

  }

  constructor(private readonly _formBuilder: FormBuilder) { }

  ngOnInit(): void {

    //Create period form
    this.periodForm = this._formBuilder.group({
      [PeriodFieldsForm.TYPE_OF_PERIOD]: this._formBuilder.control(null),
      [PeriodFieldsForm.INITIAL]: this._formBuilder.control(null),
      [PeriodFieldsForm.END]: this._formBuilder.control(null)
    });
    
    //Listen type period
    this.periodType.valueChanges
      .pipe(
        takeWhile(() => !this.stopSubcription), 
        distinctUntilChanged()
        )
      .subscribe(period => {
        this.setPeriodType(period);
    });

    //Listen for Filter changes
    this.periodForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(() => this.periodForm.valid),
        map(this.getFiltersToPost.bind(this)))
      .subscribe(
        (data => {
          this.onChange(data);
        }
      )
    );

    this.setPeriodType(this.PERIODS.LAST10);
    
  }


  writeValue(value: DatePeriodData): void {
    
    if(value){

      this.setPeriodType(value.typeOfPeriods);

      if(value.typeOfPeriods === AdvancedSearchPeriodsEnum.RANGE){
        
        const momentInitial = moment(value.initial,this.dateFormat);
        const momentEnd = moment(value.end,this.dateFormat);

        const initialValue = momentInitial.isValid() ? momentInitial : this.today;
        const endValue = momentEnd.isValid() ? momentEnd : this.today;

        this.initial.setValue(initialValue, DEFAULT_CONTROL_OPTIONS);
        this.end.setValue(endValue, DEFAULT_CONTROL_OPTIONS);
      }

    }else{
      
      this.periodType.setValue('', { onlySelf: true, emitEvent: true }); //To clear the component values
      //this.setPeriodType(null); //Update to empty
    }
    
  }

  registerOnChange(fn: any): void {
    
    this.onChange = fn;

  }

  registerOnTouched(fn: any): void {
    
    this.onTouch = fn;

  }

  setDisabledState?(isDisabled: boolean): void {

    this.isDisabled = isDisabled;

  }

  /** set period type */
  setPeriodType(period: AdvancedSearchPeriodsEnum): void {
    
    this.periodType.setValue(period, { onlySelf: true, emitEvent: false });

    if(period != null){

      if (period !== AdvancedSearchPeriodsEnum.RANGE) {

        this.initial.disable(DEFAULT_CONTROL_OPTIONS);
        this.end.disable(DEFAULT_CONTROL_OPTIONS);
      } else {

        this.initial.enable(DEFAULT_CONTROL_OPTIONS);
        this.end.enable(DEFAULT_CONTROL_OPTIONS);
      }
  
      this.updatePeriods(period);
    }else{

      this.initial.disable(DEFAULT_CONTROL_OPTIONS);
      this.end.disable(DEFAULT_CONTROL_OPTIONS);
      this.initial.setValue('', DEFAULT_CONTROL_OPTIONS);
      this.end.setValue('', DEFAULT_CONTROL_OPTIONS);
    }

  }
  
  /** Update start and end date */
  private updatePeriods(state: AdvancedSearchPeriodsEnum):void {

    const ONE_MONTH = -9; //To take today as tenth day
    let initial: Moment;
    let end: Moment;
    switch (state) {
  
      case AdvancedSearchPeriodsEnum.LAST10:
  
        initial = moment().add(ONE_MONTH, 'days');
        end = moment();
        break;
      case AdvancedSearchPeriodsEnum.RANGE:
      case AdvancedSearchPeriodsEnum.ACTUAL_MONTH:
  
        initial = moment().startOf('month');
        end = moment(); //End day must be today //moment().endOf('month');
        break;
      case AdvancedSearchPeriodsEnum.ACTUAL_WEEK:
  
        initial = moment().startOf('week');
        end = moment();//End day must be today //moment().endOf('week');
        break;
      case AdvancedSearchPeriodsEnum.ACTUAL_DAY:
  
        initial = moment().startOf('day');
        end = moment().endOf('day');
        break;
  
    }

    this.initial.setValue(initial, DEFAULT_CONTROL_OPTIONS);
    this.end.setValue(end, DEFAULT_CONTROL_OPTIONS);
    
  }

  private getFiltersToPost(): DatePeriodData {
    
    const result:DatePeriodData =  {
      typeOfPeriods: this.periodType.value,
      end: moment(this.end.value).format(this.dateFormat),
      initial: moment(this.initial.value).format(this.dateFormat),
    };
    return result;
    
  }

  ngOnDestroy(): void {

    this.stopSubcription = true;

  }
}
