import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SurveyService } from './survey.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

enum SurveyFieldsForm {
  RATE = 'rate',
  COMMENT = 'comment'
}


@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SurveyService]
})
export class SurveyComponent implements OnInit {
  
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  
  public frmSurvey: FormGroup;
  private dialogRef: MatDialogRef<any>;
  
  get rate() { 
    
    return this.frmSurvey.get(SurveyFieldsForm.RATE); 
  }

  get comment() { 
    
    return this.frmSurvey.get(SurveyFieldsForm.COMMENT); 
  }

  get isValidData() {

    for (var i in this.frmSurvey.controls) {
      this.frmSurvey.controls[i].markAsTouched();
    }
    return this.frmSurvey.valid;
  }

  constructor( private readonly _dialog: MatDialog,
              private _service: SurveyService,
              private formBuilder: FormBuilder) {
            
    this.frmSurvey = this.formBuilder.group({
      [SurveyFieldsForm.RATE]: [null, Validators.required],
      [SurveyFieldsForm.COMMENT]: [''] //Comments are optional
    });
  }
 
  ngOnInit() {
    
    
    this._service.check().subscribe();
   
    this._service.currentSurvey$.subscribe(
      (result:boolean)=>{
        
        if(result){

          this.dialogRef = this._dialog.open(this.dialogTemplate,{
            width: '80%',
          });
        }
      }
    );

  }
  
  onSkip(){
    
    this._service.setSurveySkipped();
    this.dialogRef.close();
  }

  onComplete(){
    
    if(this.isValidData){

      this._service.send({
        value: parseInt(this.rate.value),
        comment: this.comment.value
      }).subscribe(
        (resp:boolean)=>{
          
          if(resp){

            this.dialogRef.close();
          }
        }
      );
    }
  }
  
}
