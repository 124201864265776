import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CatalogsSidebarService } from '../../shared/services/catalogs-sidebar.service';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { AlertService } from '../../shared/services/alerts.service';
import { BaseApiService } from './base-service.abstract';
import { UserInfoService } from './user-info.service';
import { AuthService } from './auth-service.service';
import { EnvService } from './env.service';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag.service';

// #region Constants

const HEADERS_KEY = 'headers';

// #endregion

@Injectable()
export class BaseService implements BaseApiService {
  protected baseUrl = window['__env'].APIS.ORIGINATIONS;
  protected instancesUrl = window['__env'].APIS.INSTANCES_API;
  private isTransactionPending = 0;

  constructor(public _http: HttpClient, 
              public readonly _alerts: AlertService, 
              private readonly _loader2: LoaderService,
              public readonly catalogsSidebar: CatalogsSidebarService, 
              protected readonly _auth: AuthService,
              protected readonly userInfoSrv: UserInfoService, 
              public _httpBackend: HttpBackend,
              protected readonly _env: EnvService,
              protected readonly _dateHelper: DateHelperService,
              protected readonly featureFlagService: FeatureFlagService) {
  }

  get(endpoint: string, config: ConfigurationRequest = { baseUrl: this.baseUrl }, query: any = null): Observable<any> {

    const loader = this._loader2.activeLoaders.get(config.loaderName);

    if (loader) {

      loader.next(true);
    }
    else if (config.loader) {

      this._loader2.show(config.loaderMessage);
      this.isTransactionPending++;
    }

    // add query params if it's not null
    const httpGetConfig = {};
    if (query) {

      httpGetConfig['params'] = query;
    }

    const httpEndpoint = this.composedUrl(endpoint, config && config.baseUrl || this.baseUrl);

    return this._http.get(httpEndpoint, httpGetConfig).pipe(
      tap(() => {

        if (loader) {

          loader.next(false);

        }
        else {

          this.isTransactionPending = this.isTransactionPending > 0 ? this.isTransactionPending - 1 : 0;
        }

        if (config.loader && this.isTransactionPending === 0) {

          this.isTransactionPending = 0;
          this._loader2.hide();
        }
      }),
      catchError(err => this.treatError(err, config.errorMsg))
    );
  }

  post(endpoint: string, data: any, config: ConfigurationRequest = { baseUrl: this.baseUrl }): Observable<any> {

    const loader = this._loader2.activeLoaders.get(config.loaderName);

    if (loader) {

      loader.next(true);
    } 
    else if (config.loader) {
      
      this._loader2.show();
      this.isTransactionPending++;
    }

    const options = {};
    
    if (config.headers) {

      options[HEADERS_KEY] = config.headers;
    }

    return this._http.post(this.composedUrl(endpoint, config && config.baseUrl || this.baseUrl), data, options)
      .pipe(
        tap(() => {

          if (loader) {

            loader.next(false);
          } 
          else if (config.loader && --this.isTransactionPending === 0) {
            this.isTransactionPending = 0;
            this._loader2.hide();
          }
        }),
        catchError(err => this.treatError(err, config.errorMsg))
      );
  }

  put(endpoint: string, data: any, config: ConfigurationRequest = { baseUrl: this.baseUrl }): Observable<any> {
    const loader = this._loader2.activeLoaders.get(config.loaderName);
    if (loader) {
      loader.next(true);
    } else if (config.loader) {
      this._loader2.show();
      this.isTransactionPending++;
    }
    return this._http.put(this.composedUrl(endpoint, config && config.baseUrl || this.baseUrl), data).pipe(
      tap(() => {
        if (loader) {
          loader.next(false);
        } else if (config.loader && --this.isTransactionPending === 0) {
          this.isTransactionPending = 0;
          this._loader2.hide();
        }
      }),
      catchError(err => this.treatError(err, config.errorMsg))
    );
  }
  
  /**
   * Patch http method
  */
  patch(endpoint: string, data: any, config: ConfigurationRequest = { baseUrl: this.baseUrl }): Observable<any> {
    
    const loader = this._loader2.activeLoaders.get(config.loaderName);
    if (loader) {
      loader.next(true);
    } else if (config.loader) {
      this._loader2.show();
      this.isTransactionPending++;
    }
    return this._http.patch(this.composedUrl(endpoint, config && config.baseUrl || this.baseUrl), data).pipe(
      tap(() => {
        if (loader) {
          loader.next(false);
        } else if (config.loader && --this.isTransactionPending === 0) {
          this.isTransactionPending = 0;
          this._loader2.hide();
        }
      }),
      catchError(err => this.treatError(err, config.errorMsg))
    );
  }

  delete(endpoint: string, config: ConfigurationRequest = { baseUrl: this.baseUrl }): Observable<any> {
    const loader = this._loader2.activeLoaders.get(config.loaderName);
    if (loader) {
      loader.next(true);
    } else if (config.loader) {
      this._loader2.show();
    }
    return this._http.delete(this.composedUrl(endpoint, config && config.baseUrl || this.baseUrl)).pipe(
      tap(() => {
        if (loader) {
          loader.next(false);
        } else if (config.loader) {
          this._loader2.hide();
        }
      }),
      catchError(err => this.treatError(err, config.errorMsg))
    );
  }

  custom(type: BaseTypeHttpRequest, customRequest: Observable<any>): Observable<any> {
    this._loader2.show();
    return customRequest.pipe(
      tap(() => {

      }),
      catchError(this.treatError.bind(this)));
  }

  getLog(id: string, eventNames: any, config: ConfigurationRequest = { baseUrl: this.baseUrl }): () => Observable<any> {
    return () => {
      const loader = this._loader2.activeLoaders.get(config.loaderName);
      if (loader) {
        loader.next(true);
      } else if (config.loader) {
        this._loader2.show();
      }
      return this._http.post(this.composedUrl(`Log/${ id }`, window['__env'].APIS.ORIGINATIONS), ({ eventNames: eventNames }))
        .pipe(
          tap(() => {
            if (loader) {
              loader.next(false);
            } else if (config.loader) {
              this._loader2.hide();
            }
          }),
          catchError(this.treatError.bind(this))
        );
    };
  }

  private composedUrl(endpoint: string, baseUrl: string): string {
    return `${ baseUrl }/${ endpoint }`;
  }

  private treatError(err: any, errorMsg: string | undefined): any {
    this._loader2.hide();
    this._alerts.error(errorMsg || 'Ha ocurridó un error con la petición.');
    if (this.isTransactionPending > 0) {
      this.isTransactionPending--;
    }
    return throwError(err);
  }
}

export class ConfigurationRequest {
  baseUrl?: string;
  loader?: boolean;
  loaderName?: string;
  errorMsg?: string;
  loaderMessage?: string;
  headers?: any;
}

export enum BaseTypeHttpRequest {
  POST,
  GET,
  PUT,
  DELETE
}
