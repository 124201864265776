import { ColumnFormat, ColumnType, RedirectToAction } from 'src/app/shared/enums';
import { MenuItems, Row, TabTable } from '../models';
import { URLS_SETTINGS } from 'src/app/configs/urls.config';

export const MeasurecaterogyLists: TabTable[] = [
  {
    order: 0,
    name: '',
    columns: [
      {
        order: 0,
        title: 'IdCategorymeasure',
        columnId: 'idmeasurecategory',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Nombre categoria',
        columnId: 'namecategoria',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'Descripcion categoria',
        columnId: 'description',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        IdCategorymeasure: 1,
        namecategoria: 'comportamiento',
        description:
          '[persona] Que es muy hábil o tiene gran experiencia en un trabajo o actividad'
      },
      {
        IdCategorymeasure: 2,
        namecategoria: 'conversion',
        description:
          // tslint:disable-next-line:max-line-length
          'Son lenguas de la tierra, llenas de información geográfica, ecológica y climática compleja, que aunque está basada en el ámbito loca'
      },
      {
        IdCategorymeasure: 3,
        namecategoria: 'estabilidad',
        description:
          // tslint:disable-next-line:max-line-length
          'Conjunto de actividades y conocimientos desarrollados por el hombre, destinados a cultivar la tierra y cuya finalidad es obtener productos vegetales (como verduras, frutos, granos y pastos) para la alimentación del ser humano y del ganado.'
      },
      {
        IdCategorymeasure: 4,
        namecategoria: 'Ganaderia',
        description:
          // tslint:disable-next-line:max-line-length
          'Cría de ganado para su explotación y comercio."las tierras altas de la montaña son poco aptas para la agricultura, por lo que se dedican a la ganadería'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const ROLES: TabTable[] = [
  {
    order: 0,
    name: 'Roles',
    columns: [
      {
        order: 0,
        title: 'Id',
        columnId: 'id',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Checkbox',
        columnId: 'check',
        type: ColumnType.NORMAL,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'CATALOGS_HEADERS.NAME',
        columnId: 'name',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 3,
        title: 'Niveles de jerarquía donde aplican',
        columnId: 'levels',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 4,
        title: 'CATALOGS_HEADERS.DESCRIPTION',
        columnId: 'description',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Asesor comercial',
        levels: ['Asesores de inclusión financiera', 'Asesores de banca'],
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      },
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Asesor financiero',
        levels: ['Asesores de inclusión financiera', 'Asesores de banca'],
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      },
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Subdirector Financiero',
        levels: ['Asesores de inclusión financiera', 'Asesores de banca'],
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const LabelsLists: TabTable[] = [
  {
    order: 0,
    name: 'Etiquetas',
    columns: [
      {
        order: 0,
        title: 'IdEtiqueta',
        columnId: 'id',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Nombre',
        columnId: 'name',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'Descripcion',
        columnId: 'description',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        id: 'ijihihhihihi',
        name: 'experto',
        description:
          '[persona] Que es muy hábil o tiene gran experiencia en un trabajo o actividad'
      },
      {
        id: 2,
        name: 'Lenguas indigenas',
        description:
          // tslint:disable-next-line:max-line-length
          'Son lenguas de la tierra, llenas de información geográfica, ecológica y climática compleja, que aunque está basada en el ámbito loca'
      },
      {
        id: 3,
        name: 'Agricultura',
        description:
          // tslint:disable-next-line:max-line-length
          'Conjunto de actividades y conocimientos desarrollados por el hombre, destinados a cultivar la tierra y cuya finalidad es obtener productos vegetales (como verduras, frutos, granos y pastos) para la alimentación del ser humano y del ganado.'
      },
      {
        id: 4,
        name: 'Ganaderia',
        description:
          // tslint:disable-next-line:max-line-length
          'Cría de ganado para su explotación y comercio."las tierras altas de la montaña son poco aptas para la agricultura, por lo que se dedican a la ganadería'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const Roles: TabTable[] = [
  {
    order: 0,
    name: 'Roles',
    columns: [
      {
        order: 0,
        title: 'Id',
        columnId: 'id',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Checkbox',
        columnId: 'check',
        type: ColumnType.NORMAL,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'Nombre',
        columnId: 'name',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 3,
        title: 'Niveles de jerarquía donde aplican',
        columnId: 'levels',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 4,
        title: 'Descripción',
        columnId: 'description',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Asesor comercial',
        levels: 'Asesores de inclusión financiera, Asesores de banca',
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      },
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Asesor financiero',
        levels: 'Asesores de inclusión financiera, Asesores de banca',
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      },
      {
        id: 'ytftftd5d672',
        check: false,
        name: 'Subdirector Financiero',
        levels: 'Asesores de inclusión financiera, Asesores de banca',
        description:
          'Persona en campo que puede ofrecer los productos de la financiera'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const VARIABLES_CATALOG: TabTable[] = [
  {
    order: 0,
    name: 'Variables',
    columns: [
      {
        order: 0,
        title: 'Id',
        columnId: 'id',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Checkbox',
        columnId: 'check',
        type: ColumnType.NORMAL,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'Nombre',
        columnId: 'name',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 3,
        title: 'Descripción',
        columnId: 'description',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        id: 'hvyvuihih6r64',
        check: true,
        name: 'monto',
        description: 'Lorem ipsum dolor, sit amet, consectetr adipiscing, elit.'
      },
      {
        id: 'hvyvuihih6r64',
        check: true,
        name: 'contador',
        description: 'Lorem ipsum dolor, sit amet, consectetr adipiscing, elit.'
      },
      {
        id: 'hvyvuihih6r64',
        check: true,
        name: 'resultado',
        description: 'Lorem ipsum dolor, sit amet, consectetr adipiscing, elit.'
      },
      {
        id: 'hvyvuihih6r64',
        check: true,
        name: 'valorDeEntrada',
        description: 'Lorem ipsum dolor, sit amet, consectetr adipiscing, elit.'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const LISTADO_PANEL_ADMIN: MenuItems[] = [
  {
    title: 'MENU_COMPONENT.DATA',
    items: [
      {
        name: 'CATALOG_NAME.DATA',
        url: URLS_SETTINGS.ADMINISTRATION.DATA
      }
    ]
  },
  {
    title: 'MENU_COMPONENT.FLOWS',
    items: []
  },
  {
    title: 'MENU_COMPONENT.PRODUCTS',
    items: [
      {
        name: 'CATALOG_NAME.PRODUCTS',
        url: URLS_SETTINGS.ADMINISTRATION.PRODUCTS
      }
    ]
  },
  {
    title: 'MENU_COMPONENT.POLYGONS',
    items: [
      {
        name: 'CATALOG_NAME.POLYGONS_CATALOG',
        url: URLS_SETTINGS.ADMINISTRATION.POLYGINS_CATALOGS
      },
    ]
  },
  {
    title: 'MENU_COMPONENT.AGENTS',
    items: [
      {
        name: 'AGENTS_COMPONENT.TITLE',
        url: URLS_SETTINGS.ADMINISTRATION.AGENTS
      },
      {
        name: 'CATALOG_NAME.ROLES',
        url: URLS_SETTINGS.ADMINISTRATION.ROLES
      },
      {
        name: 'CATALOG_NAME.VARIABLES_GROUP_CATALOG',
        url: URLS_SETTINGS.ADMINISTRATION.VARIABLE_GROUP
      },
      {
        name: 'CATALOG_NAME.CUSTOM_CATALOGS',
        url: URLS_SETTINGS.ADMINISTRATION.CUSTOM_CATALOGS
      },
      {
        name: 'CATALOG_NAME.HIERARCHY_AND_ASSIGMENTS',
        url: URLS_SETTINGS.ADMINISTRATION.HIERARCHY
      }
    ]
  },
  {
    title: 'MENU_COMPONENT.CHANNELS',
    items: []
  },
  {
    title: 'MENU_COMPONENT.SUBJECTS',
    items: []
  },
  {
    title: 'MENU_COMPONENT.ROLES_AND_PERMISSIONS',
    items: []
  }
];
export const TAREAS_PENDIENTES: TabTable[] = [
  {
    order: 0,
    name: '',
    columns: [
      {
        order: 0,
        title: 'Id',
        columnId: 'id',
        type: ColumnType.ID,
        isHidden: true,
        format: ColumnFormat.NONE
      },
      {
        order: 1,
        title: 'Tareas',
        columnId: 'tareas',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 2,
        title: 'Estado',
        columnId: 'estado',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 3,
        title: 'Etapa',
        columnId: 'etapa',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 4,
        title: 'Producto',
        columnId: 'producto',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 4,
        title: 'Titular/Banca',
        columnId: 'titular',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      },
      {
        order: 4,
        title: 'Localidad',
        columnId: 'localidad',
        type: ColumnType.NORMAL,
        isHidden: false,
        format: ColumnFormat.NONE
      }
    ],
    rows: [
      {
        id: 'ytftftd5d672',
        tareas: 'Información del cliente',
        estado: 'Por capturar',
        etapa: 'Solicitud',
        producto: 'Avanzar',
        titular: 'Mary Luz Rico',
        localidad: 'Bucaramanga, asturias'
      },
      {
        id: 'ytftftd5d672',
        tareas: 'Información del cliente',
        estado: 'Por capturar',
        etapa: 'Solicitud',
        producto: 'Avanzar',
        titular: 'Mary Luz Rico',
        localidad: 'Bucaramanga, asturias'
      },
      {
        id: 'ytftftd5d672',
        tareas: 'Información del cliente',
        estado: 'Por capturar',
        etapa: 'Solicitud',
        producto: 'Avanzar',
        titular: 'Mary Luz Rico',
        localidad: 'Bucaramanga, asturias'
      }
    ],
    columnFilter: null,
    rowAction: RedirectToAction.NONE
  }
];

export const groupVariables: TabTable = {
  order: 0,
  name: 'Variables',
  columns: [
    {
      order: 0,
      title: 'Id',
      columnId: 'id',
      type: ColumnType.ID,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 1,
      title: 'Checkbox',
      columnId: 'check',
      type: ColumnType.NORMAL,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.NAME',
      columnId: 'name',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    },
    {
      order: 3,
      title: 'CATALOGS_HEADERS.DESCRIPTION',
      columnId: 'description',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    }
  ],
  rows: [],
  columnFilter: null,
  rowAction: RedirectToAction.NONE,
  useCheckBox: true,
  contextMenu: [
    {
      title: 'Agregar Variables',
      materialIcon: 'add'
    }
  ]
};

export const CustomizableCatalog: TabTable = {
  order: 0,
  name: 'customizableCatalog',
  columns: [
    {
      order: 0,
      title: 'Id',
      columnId: 'id',
      type: ColumnType.ID,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.CATALOG_NAME',
      columnId: 'name',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    }
  ],
  rows: [],
  columnFilter: null,
  rowAction: RedirectToAction.NONE,
  useCheckBox: true,
  contextMenu: [
    {
      title: 'Agregar elementos al catálogo',
      materialIcon: 'add'
    }
  ]
};

export const RowCatalog: Row = [
  {
    totalRows: 4,
    name: 'actividadconyuge'
  },
  {
    totalRows: 4,
    name: 'amortizacion'
  },
  {
    totalRows: 54369,
    name: 'barrios'
  },
  {
    totalRows: 3,
    name: 'beneficiarioshijoseg'
  },
  {
    totalRows: 3,
    name: 'cantidadactividades'
  },
  {
    totalRows: 4,
    name: 'cantidadmiembos'
  },
  {
    totalRows: 4,
    name: 'cantidadmiembros'
  },
  {
    totalRows: 3,
    name: 'cantidadmiembros6a12'
  },
  {
    totalRows: 7,
    name: 'caunegcentralriesconyuge'
  },
  {
    totalRows: 5,
    name: 'caunegcentralriestitular'
  },
  {
    totalRows: 7,
    name: 'caunegcreasolicitud'
  },
  {
    totalRows: 22,
    name: 'caunegevaluacion'
  },
  {
    totalRows: 14,
    name: 'caunegprevaluacion'
  },
  {
    totalRows: 6,
    name: 'clasecodeudor'
  },
  {
    totalRows: 33,
    name: 'departamentos'
  },
  {
    totalRows: 20,
    name: 'destinocredito'
  },
  {
    totalRows: 4,
    name: 'diadepagopo'
  },
  {
    totalRows: 6,
    name: 'estadocivil'
  },
  {
    totalRows: 6,
    name: 'estrato'
  },
  {
    totalRows: 4,
    name: 'estructuranegocio'
  },
  {
    totalRows: 9,
    name: 'formapagorefencias'
  },
  {
    totalRows: 2,
    name: 'generos'
  },
  {
    totalRows: 3,
    name: 'interesado'
  },
  {
    totalRows: 5,
    name: 'lugaresconexion'
  },
  {
    totalRows: 5,
    name: 'materialpisovivienda'
  },
  {
    totalRows: 5,
    name: 'moneda'
  },
  {
    totalRows: 1162,
    name: 'municipios'
  },
  {
    totalRows: 5,
    name: 'niveleseducativos'
  },
  {
    totalRows: 5,
    name: 'numeroactividades'
  },
  {
    totalRows: 5,
    name: 'opcionpoldan'
  },
  {
    totalRows: 5,
    name: 'opcionpoldancon'
  },
  {
    totalRows: 39,
    name: 'pais'
  },
  {
    totalRows: 13,
    name: 'parentescocodeudor'
  },
  {
    totalRows: 25,
    name: 'parentescoexequial'
  },
  {
    totalRows: 25,
    name: 'parentescopolizavida'
  },
  {
    totalRows: 18,
    name: 'parentescoreferencia'
  },
  {
    totalRows: 4,
    name: 'planconyugepolvida'
  },
  {
    totalRows: 8,
    name: 'plantitularpolvida'
  },
  {
    totalRows: 4,
    name: 'ppcionpoldan'
  },
  {
    totalRows: 21,
    name: 'procedenciacliente'
  },
  {
    totalRows: 32,
    name: 'productos'
  },
  {
    totalRows: 2,
    name: 'recomendacion'
  },
  {
    totalRows: 3,
    name: 'riesgoasegurar'
  },
  {
    totalRows: 2,
    name: 'tipocodeudor'
  },
  {
    totalRows: 2,
    name: 'tiposconstrucción'
  },
  {
    totalRows: 3,
    name: 'tiposdocumentos'
  },
  {
    totalRows: 14,
    name: 'tiposgarantia'
  },
  {
    totalRows: 3,
    name: 'tiposlocal'
  },
  {
    totalRows: 5,
    name: 'tiposmoneda'
  },
  {
    totalRows: 4,
    name: 'tiposnegocio'
  },
  {
    totalRows: 4,
    name: 'tiposolicitud'
  },
  {
    totalRows: 5,
    name: 'tipovivienda'
  },
  {
    totalRows: 171,
    name: 'actividadeseconomicas'
  },
  {
    totalRows: 8,
    name: 'sectoreseconomicos'
  },
  {
    totalRows: 2,
    name: 'tipobienes'
  },
  {
    totalRows: 12,
    name: 'productosrec'
  },
  {
    totalRows: 11400,
    name: 'papas'
  }
];

export const CATALOG_ELEMENT: TabTable = {
  order: 0,
  name: 'customizableCatalog',
  columns: [
    {
      order: 0,
      title: 'Id',
      columnId: 'id',
      type: ColumnType.ID,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 1,
      title: 'Checkbox',
      columnId: 'check',
      type: ColumnType.NORMAL,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.PARENTKEY',
      columnId: 'parentKey',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.KEY',
      columnId: 'key',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.VALUE',
      columnId: 'value',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    }
  ],
  rows: [],
  columnFilter: null,
  rowAction: RedirectToAction.NONE,
  useCheckBox: true
};

export const POLYGON_CATALOG: TabTable = {
  order: 0,
  name: 'polygonCatalog',
  columns: [
    {
      order: 0,
      title: 'Id',
      columnId: 'id',
      type: ColumnType.ID,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 1,
      title: 'Checkbox',
      columnId: 'check',
      type: ColumnType.NORMAL,
      isHidden: true,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.NAME',
      columnId: 'name',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.DESCRIPTION',
      columnId: 'description',
      type: ColumnType.NORMAL,
      isHidden: false,
      format: ColumnFormat.NONE
    },
    {
      order: 2,
      title: 'CATALOGS_HEADERS.TAGS',
      columnId: 'tags',
      type: ColumnType.ARRAY,
      isHidden: false,
      format: ColumnFormat.JOIN_BY_COMA
    }
  ],
  rows: [],
  columnFilter: null,
  rowAction: RedirectToAction.NONE,
  useCheckBox: true,
  contextMenu: [
    {
      title: 'Duplicar'
    },
    {
      title: 'Eliminar'
    }
  ]
};

export const polygons: Row = [
  {
    id: '2E5A7EE9-0A2E-453B-A390-E851743872D5',
    name:  'Colombia',
    description : 'ghdhyddhdhj',
    tags: ['cordoba', 'colombia', 'papas']
  },
  {
    id: 'DB2659F8-F1EC-4932-BB4C-89B0ADB4023B',
    name: 'Paris',
    description: 'jhufbefuu',
    tags: ['cordoba', 'colombia']
  }
];
