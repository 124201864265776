import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

enum DATE_RANGE {
  CUSTOM_RANGE,
  CURRENTLY_MONTH,
  CURRENTLY_WEEK
}

@Component({
  selector: 'date-range-widget',
  templateUrl: './date-range-widget.component.html',
  styleUrls: ['./date-range-widget.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    }
  ],
})

export class DateRangeWidgetComponent {

  @Output() dateRangeEvent = new EventEmitter<Date[]>();
  @Input() dateCaption: string = '';
  dateRange: Date[] = [];
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  selectedRangeIndex: number;
  activeButton: number = -1;
  disableCalendar: boolean = false;
  startDate: Date;
  today: Date = new Date();
  customRanges: Object;
  optionsRange: string[] = ['Rango personalizado', 'Mes en curso', 'Semana en curso']

  constructor() {

    // Set the start date to the first day of the next month fot the endDate
    this.startDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
  }

  ngOnInit() {
    
    this.customRanges = this.generateDateRanges();
    this.assignDatesIfExist();
  }

  /**
   * Generates date ranges for the date range widget.
   * 
   * @returns An object containing different date ranges.
   */
  generateDateRanges() {

    const startWeek = new Date(this.today);
    startWeek.setDate(this.today.getDate() - this.today.getDay() + (this.today.getDay() === 0 ? -6 : 1)); 
    const endWeek = new Date(this.today);
    endWeek.setDate(startWeek.getDate() + 6);

    return {
      [DATE_RANGE.CUSTOM_RANGE]: [
        new Date(new Date().setDate(new Date().getDate())),
        new Date(new Date().setDate(new Date().getDate()))
      ],
      [DATE_RANGE.CURRENTLY_MONTH]: [
        new Date(new Date().setDate(1)),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      [DATE_RANGE.CURRENTLY_WEEK]: [
        startWeek,
        endWeek
      ]
    };
  }

  /**
   * Sets the selected index of the date range widget.
   * 
   * @param typeRange - The index to set as the selected index.
   * @returns void
   */
  setSelectedIndex(typeRange: number): void {

    this.activeButton = typeRange + 1;

    this.selectedRangeIndex = typeRange;
    this.dateRange = this.customRanges[this.selectedRangeIndex];
  
    this.selectedStartDate = (this.selectedRangeIndex !== DATE_RANGE.CUSTOM_RANGE) ? this.dateRange[0] : null;
    this.selectedEndDate = (this.selectedRangeIndex !== DATE_RANGE.CUSTOM_RANGE) ? this.dateRange[1] : null;
  
    this.disableCalendar = this.selectedRangeIndex !== DATE_RANGE.CUSTOM_RANGE;
  }
  
  /**
   * Selects the date range and emits the selected range through the dateRangeEvent.
   * If the selected range is set to CUSTOM_RANGE and no start and end dates are provided,
   * it sets the default range as the selected range.
   */
  selectDateRange() {

    if (!this.selectedRangeIndex && !this.selectedStartDate || !this.selectedEndDate) {

      this.selectedStartDate = this.selectedEndDate = new Date(this.today);
    }

    this.dateRange[0] = this.selectedStartDate instanceof Date ? this.selectedStartDate : new Date(this.selectedStartDate)
    this.dateRange[1] = this.selectedEndDate instanceof Date ? this.selectedEndDate : new Date(this.selectedEndDate)

    this.dateRangeEvent.emit(this.dateRange);
  }

  /**
   * Assigns the selected start and end dates if the date caption from principal component 
   * is not empty to save the previous selected dates.
   */
  assignDatesIfExist() {

    this.activeButton = 1;

    if (this.dateCaption !== '') {

      const [start, end] = this.dateCaption.split(' - ').map(dateStr => dateStr.split('/').reverse().join('/'));

      this.selectedStartDate = new Date(start);
      this.selectedEndDate = new Date(end);
    }
  }
}