import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Company } from '../../../../shared/models';
import { APP_SETTINGS } from '../../../../configs/app-settings.config';
import { ProfileService } from '../../../../shared/services/profile.service';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { SurveyService } from 'src/app/shared/components/survey/survey.service';
import { IdleUserService } from 'src/app/shared/services/idle-user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers:[SurveyService]
})
export class LoginComponent implements OnInit {
  company: Company = new Company();

  constructor(private profileService: ProfileService, 
              private _auth: AuthService, 
              private router: Router,
              private _survey:SurveyService,
              private _idle: IdleUserService) {

    this._survey.clearSurveySkipped(); //To clear survey skipped status
    this._idle.reset(); //To ensure the inactivity block counter if reset
    if (this._auth.isAuthenticated) {
     
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {

    this._auth.clearLocalStorage(); //To avoid conflict if already exists some value of adal in localStorage
    this.getCompany();
  }

  signIn() {
    
    this._auth.login();
  }

  public getCompany(): void {

    this.profileService.getCompany().subscribe(company => {

      this.company = company;
      if (!this.company.loginScreenUrl) company.loginScreenUrl = APP_SETTINGS.IMAGES.LOGIN_SCREEN;
    });
  }

  public getBackgroundUrl(url: string): string {

    return `url("${ url }")`;
  }

  public getVersion() {

    return APP_SETTINGS.VERSION_SYSTEM;
  }
}
