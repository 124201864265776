import { Pipe, PipeTransform } from '@angular/core';
import { ProductSharedService } from 'src/app/modules/products/shared/product-shared.service';

@Pipe({
  name: 'containerName'
})

export class ContainerNamePipe implements PipeTransform {
  constructor(private productSharedService: ProductSharedService) {
  }

  transform(text: string): any {
    if (!text || !this.productSharedService.getContainer(text) ) return;
    return this.productSharedService.getContainer(text).title;
  }
}
