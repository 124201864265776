import { Component, Input, ViewChild } from '@angular/core';
import { CustomTable } from '../../custom-table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CollapsableTableConfig, EngineTable } from '../../table-config.interface';
import { MatSort } from '@angular/material';

@Component({
  selector: 'app-custom-collapsable-table',
  templateUrl: 'custom-collapsable-table.component.html',
  styleUrls: ['../../custom-table-v2.component.scss', 'custom-collapsable-table.component.scss'],
  animations: [
    trigger('showHide', [
      state('hide', style({
        height: '0px',
        minHeight: 0,
        visibility: 'hidden'
      })),
      state('show', style({
        height: '*',
        visibility: 'visible'
      })),
      transition('show => hide, hide => show', animate('0.3s'))
    ])
  ]
})

export class CustomCollapsableTableComponent extends CustomTable<CollapsableTableConfig> implements EngineTable {
  // WARNING: Propiedades reservadas de esta tabla
  readonly DETAIL_PROP_NAME = '__hasDetail';
  readonly CHILD_PROP_NAME = '__gap';
  readonly COLLAPSABLE_PROP_NAME = '__collapsable';

  selectedRow: any;
  foundByFilter = [];

  @Input() set source(config: CollapsableTableConfig) {
    this._initSource(config);
  }

  @Input() set filter(search: string) {
    this.foundByFilter = [];
    this._searchFor(search);
  }

  get filter() {
    return this.dataSource.filter;
  }

  @ViewChild(MatSort) sort: MatSort;

  /**
   * Función de acceso para obtener las columnas adecuadas para las filas colapsables.
   */
  get childColumns() {
    return [this.CHILD_PROP_NAME, ...this.columns.slice(1)];
  }

  protected _isRowValid(row: [string, any]): boolean {
    return row[0] === this.configuration.collapsableProperty;
  }

  /**
   * Ver clase abstracta CustomTable.
   * Función que realiza los ajustes necesarios para trabajar con filas colapsables.
   * @private
   */
  protected _adjust(): void {
    this._prepareCollapsableRows();
  }

  /**
   * Función que verifica si un registro debe contar con una fila colapsable.
   * @param index Indice del registro seleccionado.
   * @param item Registro
   */
  allowExpand = (index: number, item: any) => !!Object.getOwnPropertyNames(item).find(prop => prop === this.DETAIL_PROP_NAME);

  /**
   * Función que colapsa la fila ligada al registro recibido.
   * @param item Registro
   */
  collapse(item: any): void {
    if (item === this.selectedRow) {
      this.selectedRow = undefined;
    } else {
      this.selectedRow = item;
    }
  }

  /**
   * Función que filtra la tabla.
   * @param row Registro.
   * @private
   */
  protected _getFilterResult(row: any): boolean {
    const values = !row[this.DETAIL_PROP_NAME] ? this._getOnlyValuesRegardColumns(row) : Object.values(row);
    for (let i = 0; i < values.length; i++) {
      if (!row[this.DETAIL_PROP_NAME] && this._recursiveSearch(values[i])) {
        this.foundByFilter.push(row);
        return true;
      } else if (row[this.DETAIL_PROP_NAME] && this.foundByFilter.some(f => f === row.data)) return true;
    }
  }

  /**
   * Función que es utilizada por la función _getFilterResult() para buscar en profundidad en estructuras complejas.
   * @param value Valor.
   * @private
   */
  private _recursiveSearch(value: any): boolean {
    if (Array.isArray(value)) {
      const source = Array.of(...value);
      for (let i = 0; i < source.length; i++) {
        const res = this._getFilterResult(source[i]);
        if (res) return res;
      }
    } else {
      return value.toString().toLowerCase().includes(this.dataSource.filter.toLowerCase());
    }
  }

  /**
   * Función que ordena las columnas de la configuración con las propias de la tabla.
   * @private
   */
  protected _sortColumns(): void {
    const columnsName = this.configuration.columns.map(column => column.field);
    this.columns = [this.COLLAPSABLE_PROP_NAME, columnsName[0], this.MENU_PROP_NAME, ...columnsName.slice(1)];
  }

  /**
   * Función que agrega las filas colapsables a la lista original.
   * @private
   */
  private _prepareCollapsableRows(): void {
    const newList = [];
    this.configuration.source
      .forEach(item => {
        newList.push(item);
        if (item[this.configuration.collapsableProperty]) newList.push({ [this.DETAIL_PROP_NAME]: true, data: item });
      });
    this.dataSource.data = newList;
  }
}
