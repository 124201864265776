import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from 'src/app/core/services/base-service.service';

export interface HistoricalReportModel {

  headerValues: any;
  historicalReport: any[]
};

@Injectable({
  providedIn: 'root'
})
export class InstanceHistoryReportService extends BaseService {

  protected baseUrl = `${this._env.APIS.INSTANCES}/organizations/${this._env.APIS.TENANTID}`

  getHistory(instanceId: string): Observable<any> {

    return this.get(`/agents/${this._auth.userId}/instances/${instanceId}:searchHistoricalReport`, {
      loader: true,
      errorMsg: 'Error obteniendo el reporte histórico'
    });
  }

}
