export class DashboardDataset {
  //#region Required properties JSON
  title: string;
  subTitle: string;
  result: string;
  color: string;
  type: number;
  values: number[];
  labels: string[];
  //#endregion
  isSelected: boolean;
}
