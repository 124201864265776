import { Injectable } from '@angular/core';
import { BaseService } from '../../../../core/services/base-service.service';
import { APP_SETTINGS } from '../../../../configs/app-settings.config';
import { QueryableService } from '../../../../shared/bases/services/QueryableService';

@Injectable()
export class InsightsService extends BaseService implements QueryableService {
  private endpoint = `${ APP_SETTINGS.GATHER_API.INSIGHTS.LOG }`;

  getByDate(fromDate: any, toDate: any, instanceId: any, uid: any) {
    return super.get(`${ this.endpoint }${ '?fromDate=' + fromDate }${ '&toDate=' + toDate }${ instanceId ? '&instanceId=' + instanceId : '' }${ uid ? '&uid=' + uid : '' }`, { loader: true });
  }
}
