import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EngineNotification } from '../../../../shared/models/notification/notification.interface';
import { NotificationTypes } from '../../../../shared/services/notifications.service';

@Component({
  selector: 'app-notification-item',
  template: `
    <div class="notification-container">
      <div class="icon-container d-flex align-items-center">
        <section class="d-flex align-items-center {{ data.type }}">
          <mat-icon class="icon-title">{{ getIcon(data) }}</mat-icon>
        </section>
      </div>
      <div class="body">
        <div class="title">{{ data.title }}</div>
        <div>
          <small>{{ data.subtitle }}</small>
        </div>
        <div class="link" *ngIf="!!data.link">
          <a (click)="openLink(data.link)"> {{data.linkCaption}} </a>
        </div>
        <div class="d-flex justify-content-start time-ago mt-2">
          {{ data.timestamp | timeAgo }}
        </div>
      </div>
      <div class="close-button-container">
        <button mat-icon-button (click)="delete.emit(data.notificationId)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['notification-item.component.scss']
})

export class NotificationItemComponent {

  @Input() data: EngineNotification;
  @Output() delete = new EventEmitter<string>();

  /**
   * Calculates the notification icon according to type property.
   * @param notification Notification.
  */
  public getIcon(notification: EngineNotification): string {
    
    const newNotification = { ...notification };
    
    switch (newNotification.type) {

      case NotificationTypes.SUCCESS:

        return 'check_circle_outline';
      case NotificationTypes.ERROR:

        return 'error_outline';
      case NotificationTypes.WARNING:

        return 'warning';
      case NotificationTypes.LOADING:

        return 'rotate_left';
      case NotificationTypes.LINK:

        return 'file_download';
      default:

        return 'info';
    }
  }

  /**
   * Opens the notification link at blank page.
   * @param link Link to open
   */
  public openLink(link: string): void {

    if (link){

      window.open(link,'_blank');
    }
  }
}
