export class MenuStep {
  menuStepsDescription: string;
  isProduct: boolean;
  definitionId: string;
  //
  title: string;
  description: string;
  moreInformation: string;
  menuSteps: MenuStep[];
}
