import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth-service.service';

//Guard to avoid user enter to login view if is authenticated
@Injectable({
  providedIn: 'root',
  deps: [AuthService,Router]
})
export class AuthRedirectGuard implements CanActivate {
  
  constructor(private readonly _auth: AuthService,
              private readonly _route: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    
    //Is user is autenticated no need enter to login
    if(this._auth.isAuthenticated){
       
      this._route.navigate(['/']); //Go to home
      return of(false);
    }
    return of(true);
  }
}
