import { Injectable } from '@angular/core';
import { Instance, InstanceContainer, ReducedInstance } from '../models';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class GlobalService {
  userFolderId: string;
  menuState: any;
  currentTaskId: string;
  private _SelectedInstance = new BehaviorSubject<Instance>(new Instance());
  private _SelectedContainer = new BehaviorSubject<InstanceContainer>(new InstanceContainer());
  private _SelectedReducedContainer = new BehaviorSubject<ReducedInstance>(new ReducedInstance());
  private _IsPollingContainers = new BehaviorSubject<boolean>(false);
  private _IsSavingData = new BehaviorSubject<boolean>(false);
  private _IsContainerDisabled = new BehaviorSubject<boolean>(false);
  formActionsAvailable = new BehaviorSubject<boolean>(true);
  reloadContainer = new Subject<string>();
  processFormEditActive = new Subject();

  getInstance() {
    return this._SelectedInstance;
  }

  setInstance(instance: Instance) {
    this._SelectedInstance.next(instance);
  }

  getContainer() {
    return this._SelectedContainer;
  }

  setContainer(selectedContainer: InstanceContainer) {
    this._SelectedContainer.next(selectedContainer);
  }

  getReducedContainer() {
    return this._SelectedReducedContainer;
  }

  setReducedContainer(selectedReducedContainer: ReducedInstance) {
    this._SelectedReducedContainer.next(selectedReducedContainer);
  }

  setIsPollingContainers(isPollingContainers: boolean) {
    this._IsPollingContainers.next(isPollingContainers);
  }

  setIsSavingData(isSavingData: boolean) {
    this._IsSavingData.next(isSavingData);
  }

  setContainerDisabledStatus(isDisabled: boolean) {
    this._IsContainerDisabled.next(isDisabled);
  }

  getContainerDisabledStatus(): BehaviorSubject<boolean> {
    return this._IsContainerDisabled;
  }
}
