import { Injectable } from '@angular/core';
import { BrowserService } from '../../../../shared/services/browser.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProductEditService extends BrowserService {
  baseUrl = `${ window['__env'].APIS.ORIGINATIONS }`;

  get(id, version): Observable<any> {
    this.catalogsSidebar.history.next(this.getLog(id, ['AdministrativeCatalogElementAdded', 'AdministrativeCatalogElementUpdated']));
    return super.get(`Definition/${ id }/${ version }`, { loader: true });
  }

  setMapContainers(containers) {
    this.browserMap = new Map(Object.entries(containers));
  }

  setChildrensInMap(container, parent = []) {
    const childrens = [];
    const parents = parent;
    if (!container || !container['containerId']) return;
    const _container = this.browserMap.get(container.containerId);
    if (_container && _container.parentId) {
      parents.push(_container.parentId.toString());
    }
    if (container.containers) {
      container.containers.forEach(el => {
        childrens.push(el.containerId);
        this.setChildrensInMap(el, Array.from(parents));
      });
    }
    _container.childrens = childrens;
    _container.parents = parents;
  }

  setCol(id) {
    const grid = [];
    const container = this.browserMap.get(id);
    if (container && container.parents) {
      if (container.parents.length) {
        grid.push([container.parents[0]]);
        container.parents.forEach(parentId => {
          grid.push(Array.from(this.browserMap.get(parentId).childrens));
        });
      } else {
        grid.push(Array.from([id]));
      }
      if (this.browserMap.get(id).childrens.length) {
        grid.push(Array.from(this.browserMap.get(id).childrens));
      }
      this.browserGrid$.next(grid);
    }
    this.setStatus(id);
    this.selectedItemId$.next(id);
  }

  setStatus(id) {
    const container = this.browserMap.get(id);
    if (container) {
      if (container.parents.length) {
        if (container.childrens.length) {
          container.childrens.forEach(childId => {
            this.browserMap.get(childId).statusEvent = 0;
            if (this.browserMap.get(childId).check) {
              this.browserMap.get(childId).check = false;
            }
          });
        }
        this.browserMap.get(container.parentId).childrens.forEach(childId => {
          this.browserMap.get(childId).statusEvent = 0;
        });
      }
      container.statusEvent = 1;
    }
  }

  searchByName(value) {
    if (!value) return;
    const result = Array.from(this.browserMap).filter(v => v[1].title.toLowerCase().includes(value.replace(/ /g, '').toLowerCase()));
    return result.map(r => {
      const obj = r[1];
      const sub = (obj.customStatusInfo !== undefined && obj.customStatusInfo.length >= 1) ?
        obj.customStatusInfo[obj.customStatus].description :
        '';
      return {
        id: r[0],
        type: obj.parents ? obj.parents.length : 0,
        title: obj.title,
        subtitle: sub,
        parents: obj.parents ? obj.parents.map(p => this.browserMap.get(p).title) : 0
      };
    });
  }
}
