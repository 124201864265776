import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'scc-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
  exportAs: 'button'
})
export class CustomButtonComponent {

  customClass = 'custom-button-default';
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() class = '';
  @Input() route: string;
  @Input() tabIndex: number;
  @Input() maxWidth: string;
  @Output() customClick = new EventEmitter();

  @Input()
  set kind(value: string) {
    this.evaluateKind(value.toLocaleLowerCase());
    this.addTruncateClass();
  }

  evaluateKind(kind: string) {
    switch (kind) {
      case 'confirm': {
        this.customClass = 'custom-button-confirm';
        break;
      }
      case 'cancel': {
        this.customClass = 'custom-button-cancel';
        break;
      }
      case 'alternate': {
        this.customClass = 'custom-button-alternate';
        break;
      }
      case 'modal-secondary': {
        this.customClass = 'modal-secondary';
        break;
      }
      case 'transparent': {
        this.customClass = 'transparent';
        break;
      }
      default: {
        this.customClass = 'custom-button-default';
        break;
      }
    }
  }

  private addTruncateClass(): void {
    if (this.maxWidth) {
      this.customClass = this.customClass.concat(' truncate');
    }
  }
}
