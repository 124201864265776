import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-card',
  template: `
    <mat-card [ngClass]="cardTypeClass" [style.padding]="padding">

      <div class="custom-title-18" *ngIf="title">
        {{title}}
      </div>

      <mat-card-content [ngStyle]="{ 'height': fullContent ? '100%' : '' }">
        <ng-content></ng-content>
      </mat-card-content>

      <div class="sticky-footer">
        <ng-content select="[mainCardFooter]"></ng-content>
      </div>

    </mat-card>
  `,
  styleUrls: ['main-card.component.scss']
})

export class MainCardComponent {
  @Input() title: string;
  @Input() cardTypeClass = 'catalog';
  @Input() fullContent = false;
  @Input() padding = 'auto';
}

