import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterSelfExclude',
    pure: false
})
export class FilterSelfExcludePipe implements PipeTransform {
    transform(items: any[], filter: string, index: number): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter((item, idx) => index !== idx - 2);
    }
}
