import { Injectable } from '@angular/core';
import { DtoDefinition } from '../../../shared/models/Definition/DtoDefinitionEngine';
import { DtoContainer, DtoLinearFlowTable, DtoLinearFlowUpdate } from '../../../shared/models/Definition/DtoContainer';
import { DtoDataType } from '../../../shared/models/Definition/DtoDataType';
import { ProductsService } from './products.service';
import { Observable } from 'rxjs';
import { StoragesService } from '../../../shared/services/storages.service';
import { switchMap } from 'rxjs/operators';
import { HeaderFunctionModel } from 'src/app/shared/services/header-functions.service';

interface HeaderFunctionsData {

  updated: boolean,
  list: Array<HeaderFunctionModel>;
}

@Injectable()
export class ProductSharedService implements Definition {
  definition: DtoDefinition;
  isReadonly = false;
  selectedContainerId: string;
  private readonly PK_DATATYPE: string = 'keyName';
  private readonly PK_CONTAINER: string = 'containerId';

  headerFunctions: HeaderFunctionsData = {
    updated: false,
    list: []
  };

  get isHotfix() {
    return this.definition.schemaId.version.split('.').length > 2;
  }

  get containers(): Array<DtoContainer> {
    if (this.definition.containers) {
      return Object.entries(this.definition.containers).map(container => (<DtoContainer>container[1]));
    } else {
      return [];
    }
  }

  get dataTypes(): Array<DtoDataType> {
    if (this.definition.dataTypes) {
      return Object.entries(this.definition.dataTypes).map(dataType => (<DtoDataType>dataType[1]));
    } else {
      return [];
    }
  }

  constructor(private readonly _service: ProductsService, private readonly _storage: StoragesService) {
  }

  updateDefinition(): Observable<any> {
    return this._storage.removeProcessVersionSelected().pipe(
      switchMap(() => this._service.update(this.definition, { loader: true }))
    );
  }

  addContainer(container: DtoContainer): void {
    if (!container || !container[this.PK_CONTAINER]) throw new Error('Contenedor inválido.');
    const containerFound = this.getContainer(container[this.PK_CONTAINER]);
    Object.assign(containerFound, container);
  }

  addDataType(dataType: DtoDataType): void {
    if (!dataType || !dataType[this.PK_DATATYPE]) throw new Error('Tipo de dato inválido.');
    const dataTypeFound = this.getDataType(dataType[this.PK_DATATYPE]);
    Object.assign(dataTypeFound, dataType);
  }

  getContainer(id: string): DtoContainer {
    return this.definition.containers[id];
  }

  getDataType(id: string): DtoDataType {
    return (this.dataTypes.find(dataType => dataType[this.PK_DATATYPE] === id));
  }

  getDataTypes(): Array<DtoDataType> {
    return this.dataTypes;
  }

  createLinearFlow(linearFlow: DtoLinearFlowTable): Observable<any> {
    return this._service.createLinearFlow(linearFlow);
  }

  updateLinearFlow(linearFlowUpdate: DtoLinearFlowUpdate, id: string): Observable<any> {
    return this._service.updateLinearFlow(linearFlowUpdate, id);
  }

  getLinearFlowById(id: string, version: string): Observable<any> {
    return this._service.getLinearFlowById(id, version);
  }

  getSummary(): {} {
    if (!this.definition.summary) this.definition.summary = {};
    return this.definition.summary;
  }
}

export interface Definition {
  addContainer(container: DtoContainer): void;

  getContainer(id: string): DtoContainer;

  addDataType(dataType: DtoDataType): void;

  getDataType(id: string): DtoDataType;

  getDataTypes(): Array<DtoDataType>;
}
