import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-folders-browser',
  templateUrl: './folders-browser.component.html',
  styleUrls: ['./folders-browser.component.scss']
})
export class FoldersBrowserComponent implements OnInit {
  @Input() showSearch = false;
  @Input() grids = [];
  @Input() data;
  @Input() selectId;
  @Output() menu = new EventEmitter();
  @Output() SelectId = new EventEmitter();
  @Input() Titles = new EventEmitter<string[]>();
  @Input() enableRadioBtn = false;
  @Input() enableCheckBtn = false;

  templateType: number;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.templateType = this.activatedRoute.snapshot.component['name'];
  }

  openChildren(id) {
    if (id !== this.selectId) {
      this.SelectId.emit(id);
    }
  }

  menuAction(ev) {
    this.menu.emit(ev);
  }

  trackByFn(index) {
    return index;
  }
}

