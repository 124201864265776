import { Component, OnInit } from '@angular/core';
import { NotificationsService, NotificationTypes } from '../../services/notifications.service';
import { EngineNotification } from '../../models/notification/notification.interface';
import { filter } from 'rxjs/operators';

const WAITING_TIME = 10000; //10 Seconds

@Component({
  selector: 'notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent implements OnInit {
  
  notification: EngineNotification = null;

  iconType = 'check_circle_outline';
  cssClass = '';
  viewMore = false;
  currentTimeout; //Defined to take control with overloads notifications

  constructor(private _notifications: NotificationsService) {
  }

  ngOnInit() {
    
    this._notifications.notificationReceived$
    .pipe(filter(()=> this.viewMore == false)) //To show incoming notification only when view more is false
    .subscribe(
      (current)=>{

        this.notification = current;
        this.viewMore = false; //To reset option
        this.startTimeout();
      }
    );
  }


  onClose() {
    
    this.viewMore = false;
    this.notification = null;
  }

  openLink() {

    if (this.notification && this.notification.link) {

      window.open(this.notification.link, '_blank');
    }
  }

  onReceiveNotification():void {

    switch (this.notification.type) {

      case NotificationTypes.SUCCESS:

        this.iconType = 'check_circle_outline';
        this.cssClass = 'info';
        break;
      case NotificationTypes.ERROR:

        this.iconType = 'error_outline';
        this.cssClass = 'error';
        break;
      case NotificationTypes.WARNING:

        this.iconType = 'info_outline';
        this.cssClass = 'warning';
        break;
      case NotificationTypes.LOADING:

        this.iconType = 'rotate_left';
        this.cssClass = 'info';
        break;
      case NotificationTypes.LINK:

        this.iconType = 'check_circle_outline';
        this.cssClass = 'info';
        break;
      default:

        this.iconType = 'info';
        this.cssClass = 'info';
    }
  }

  toogleViewMore() {

    this.viewMore = !this.viewMore;

    if(this.viewMore == false) {

      this.startTimeout();
    }
  }

  startTimeout() {

     if(this.notification != null) {

          if(this.currentTimeout) {
            
            clearTimeout(this.currentTimeout);
          }

          this.onReceiveNotification();
          this.currentTimeout = setTimeout(() => {

            if(!this.viewMore) { //Timeout to close notification exec only if viewMore option is false

              this.onClose();
            }
            
          }, WAITING_TIME);
    }
  }
  
}
