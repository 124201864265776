import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blocking-loader',
  templateUrl: './blocking-loader.component.html',
  styleUrls: ['./blocking-loader.component.scss']
})


export class BlockingLoaderComponent {
  @Input() show: boolean;
  @Input() message = 'Cargando...';
}
