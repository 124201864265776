import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapInline } from '../../../../shared/models';
import { AgmMap } from '@agm/core/directives/map';

declare var google: any;

@Component({
  selector: 'scc-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('engineMap') private _map: AgmMap;
  @Output() coordinatesEmitter: EventEmitter<MapInline>;
  @Input() draggable: boolean;
  @Input() searchBox: string;
  @Input() coordinates: any;
  @Input() formControlValue: FormControl;
  map: any;
  zoom = 10;
  lat: number;
  lng: number;
  private _stopSubscription$ = new Subject();

  constructor() {
    this.coordinatesEmitter = new EventEmitter<MapInline>();
    this.draggable = false;
    this.lat = 0;
    this.lng = 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.formControlValue.valueChanges.pipe(
      takeUntil(this._stopSubscription$),
      filter(coor => !!coor),
      distinctUntilChanged(),
      debounceTime(1500)
    ).subscribe(() => {
      
      const data = this.formControlValue.value;
      this.lat = (data.Latitud && data.Latitud.value) || data.Latitud;
      this.lng = (data.Longitud && data.Longitud.value) || data.Longitud;
      this.map.panTo(new google.maps.LatLng(this.lat, this.lng));

      // Added this to ensure the formControlValue has the correct value data , solution of bug91652
      this.formControlValue.patchValue({ Latitud: this.lat, Longitud: this.lng }, { emitEvent: false });
    });
  }

  ngOnDestroy(): void {
    this._stopSubscription$.next();
    this._stopSubscription$.complete();
  }

  mapReady(map: any) {
    this.map = map;
    this.map.panTo(new google.maps.LatLng(this.lat, this.lng));
  }

  markerDragEnd($event: any) {
    this.lat = $event['coords'].lat;
    this.lng = $event['coords'].lng;
    this.formControlValue.patchValue({ Latitud: this.lat, Longitud: this.lng });
  }
}
