import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRedirectGuard } from 'src/app/core/authentication/auth-redirect.guard';
import { LoginComponent } from './components/login/login.component';

//Added guard to avoid Enter to Login Page if user is authenticated
const ROUTES: Routes = [
  { 
    path: '', 
    canActivate: [ AuthRedirectGuard ],
    component: LoginComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
