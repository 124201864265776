import { Injectable } from '@angular/core';
import { FormContext } from 'src/app/shared/enums';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormUtilitiesService {
  private changeContext: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public contextChanged$: Observable<any> = this.changeContext.asObservable();

  constructor() {
  }

  setCurrentFormContext(formContext: FormContext) {
    this.changeContext.next(formContext);
  }

  findInObject(objectElement: object, stringWithDots: string | Array<string>) {
    let result = null;
    try {
      if (typeof stringWithDots === 'string') {
        result = this.findInObject(objectElement, stringWithDots.split('.'));
      } else if (!stringWithDots.length) {
        result = objectElement;
      } else {
        result = this.findInObject(objectElement[stringWithDots[0]], stringWithDots.slice(1));
      }
      return result;
    } catch (error) {
      return {};
    }
  }

  expand(key, value) {
    let objectRef = {};
    const result = objectRef;
    const arr = key.split('.');
    for (let i = 0; i < arr.length - 1; i++) {
      objectRef = objectRef[arr[i]] = {};
    }
    objectRef[arr[arr.length - 1]] = value;
    return result;
  }
}
