import { NgModule } from '@angular/core';
// Modules
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../core/material.module';
import { LoginRoutingModule } from './login.routing';
// Components
import { LoginComponent } from './components/login/login.component';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    TranslateModule,
    MaterialModule
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule { }
