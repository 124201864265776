import { I } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, forwardRef  } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChanged, takeUntil, takeWhile } from 'rxjs/operators';

const DEFAULT_CONTROL_OPTIONS = { emitEvent: false, onlySelf: true }; //Using as default options for method setValue int FormControl

/**
 * Component to encapsulate and customise the functionality of common material select 
 * with ControlValueAccessor so that it can be used in Reactive Forms 
 * Example of use:
 * <input-custom-select label="Please select something" [(ngModel)]="selection" items="arrayItems" itemId="id" itemText="name" ></input-custom-select>
 */
@Component({
  selector: 'input-custom-select',
  templateUrl: './input-custom-select.component.html',
  styleUrls: ['./input-custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCustomSelectComponent),
      multi: true
    }
  ]
})
export class InputCustomSelectComponent implements OnInit,ControlValueAccessor  {
  
  //Declare inputs

  @Input() label:string = '';

  @Input() items:any[] = [];

  @Input() itemId:string = 'id'; //Id is the default itemId

  @Input() itemText:string = 'name';  //Name is the default itemText

  @Input() multiple:boolean = false; //Default is a simple dropdown

  //Declare consts

  readonly FIELD_NAME = 'fieldSelect';

  //Declare others vars
  
  private frmSelect: FormGroup;

  public isDisabled:boolean = false;

  private stopSubcription:boolean = false; 

  public filterData = new FormControl();

  onChange = (_:any) => { }

  onTouch = () => { }

  /**Get the control fieldSelect */
  get fieldSelect(): AbstractControl {

    return (this.frmSelect && this.frmSelect.controls[this.FIELD_NAME]) || undefined;
  }

  /**check if all items are selected */
  get isAllSelected(): boolean {

    return this.fieldSelect.value && this.fieldSelect.value.length > 0 && this.fieldSelect.value.length == this.items.length ;
  }

  get filteredItems(): any[] {
     
    let result = this.items;
    const filterDataValue = this.filterData.value;
    if (filterDataValue) {
      
      result = result.filter( 
        d => d[this.itemText].toLowerCase().indexOf(filterDataValue.toLowerCase()) >= 0 
      );
    } 
    return result;
  }

  constructor(private readonly _formBuilder: FormBuilder) { }

  ngOnInit(): void {

    //Create period form
    this.frmSelect = this._formBuilder.group({
      [this.FIELD_NAME]: this._formBuilder.control(null)
    });
    
    //Listen selection change
    this.fieldSelect.valueChanges
      .pipe(
        takeWhile(() => !this.stopSubcription), 
        distinctUntilChanged())
      .subscribe(value => {
        this.writeValue(value);
    });
  }

  writeValue(value: string | string[] ): void {
    
    this.onChange(value); 
    this.fieldSelect.setValue(value, DEFAULT_CONTROL_OPTIONS ); //It's important to update initial value or reset it
  }

  registerOnChange(fn: any): void {
    
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

    this.isDisabled = isDisabled;
  }

  ngOnDestroy(): void {

    this.stopSubcription = true;
  }

  getTextValue(id:string){
    
    let result = '';
    const itemIndex = this.items.findIndex(f=>f[this.itemId] == id);

    if(itemIndex >= 0){

      result = this.items[itemIndex][this.itemText];
    }

    return result;
  }

  updateSelectAll(all:boolean){

    const values = [];

    if(all){

      this.items.forEach((item:any)=> {
        
          values.push(item[this.itemId]);
        }
      );
    }

    this.writeValue(values); //To work better
  }

}
