import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-help-desk-button',
  templateUrl: './help-desk-button.component.html',
  styleUrls: ['./help-desk-button.component.scss']
})
export class HelpDeskButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openHelpDesk() {
    $('#launcher').contents().find('#Embed button').click();
  }
}
