import { Component, Input, OnInit, forwardRef, ViewEncapsulation, HostListener, ViewChild, SimpleChanges, Inject  } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { isArray } from 'util';
import { DOCUMENT } from '@angular/platform-browser';

class TreeNode{
  key:string
  isExpanded:boolean;
  checkStatus:boolean; 
  isIndeterminate: boolean;
  match:boolean;
  data:{
    id:string,
    text:string,
    subtext:string,
    status: string
  };
  children:TreeNode[];
};

const FIELD_FILTER_NAME = 'fieldFilter';

/**
 * Component to encapsulate and customise the functionality of render tree with filter input 
 * with ControlValueAccessor so that it can be used in Reactive Forms 
 * Example of use:
 * <input-tree-select label="Please select something on tree" [(ngModel)]="selection" items="treeData"  ></input-tree-select>
 */
@Component({
  selector: 'input-tree-select',
  templateUrl: './input-tree-select.component.html',
  styleUrls: ['./input-tree-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('0.1s ease-out', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('0.1s ease-in', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTreeSelectComponent),
      multi: true
    }
  ]
})
export class InputTreeSelectComponent implements OnInit,ControlValueAccessor  {
  
  //Declare inputs

  @Input() label:string = '';
  
  @Input() items:any[] = [];

  @Input() itemId:string = 'id'; //Id is the default itemId

  @Input() itemText:string = 'name';  //Name is the default itemText

  @Input() itemSubText:string = 'name';  //Name is the default itemText

  @Input() itemStatus:string = 'status';  //Name is the default itemText

  @Input() multiple:boolean = true; //Default is a simple tree 

  @Input() handleInderminates:boolean = true;

  onChange = (_:any) => { }

  onTouch = () => { }

  //Variables
  @ViewChild('controlHandle') controlHandle: any;
  @ViewChild('treeContainer') treeContainer: any;

  value: string[] = [];

  showTreeContainer: boolean = false;
  
  treeControl: TreeNode[] = [];
  listControl: any[] = [];
  
  get selection(){

    let result:string[] = [];
    this.value.forEach((value:string)=>{

      const detail = this.listControl.find(t => t.id == value);
      if(detail){
        result.push(detail[this.itemText]);
      }
    });
    return result.join(',');
  }

  allSelected: boolean = false;
  someSelected: boolean = false;

  private frmFilter: FormGroup;

  /**Get the control fieldSelect */
  get fieldFilter(): AbstractControl {

    return (this.frmFilter && this.frmFilter.controls[FIELD_FILTER_NAME]) || undefined;
  }

  constructor(private readonly _formBuilder: FormBuilder,
             @Inject(DOCUMENT) private document: Document) { }

  writeValue(value: string[]): void {
    
    if(value == undefined) value = [];
    this.onChange(value); 
    this.value = value;
    this.updateTreeStatus();
    this.updateIndeterminates();
  }

  registerOnChange(fn: any): void {
   
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
   
  }

  ngOnInit(): void {
    
    this.frmFilter = this._formBuilder.group({
      [FIELD_FILTER_NAME]: this._formBuilder.control(null)
    });

    this.fieldFilter.valueChanges.subscribe(
      (filter:string)=>{

        this.findInTree(filter,this.treeControl[0]);
      }
    )
  }

  @HostListener('window:click', ['$event']) onClick(event) {
    
    if (this.controlHandle && this.treeContainer && 
        !this.treeContainer.nativeElement.contains(event.target) && 
        !this.controlHandle.nativeElement.contains(event.target)){

      this.showTreeContainer = false;
      this.fieldFilter.setValue(''); //To clear filter text
    }
  }

  /**
   * Detect changes 
  */
  async ngOnChanges(changes: SimpleChanges) {
    
    if (changes.hasOwnProperty('items')) {

      this.treeControl = [];
      this.buildTreeControl(this.items,null);
      this.updateTreeStatus();
    }
  }
   
  /**
   * Prepare a tree control base on input tree data 
  */
  buildTreeControl(data:any[],root:TreeNode){
    
    data.forEach((item:any)=>{
      
      this.listControl.push(item);
      
      const treeCtr:TreeNode =  {
        key:  Math.random().toString(36).substr(2, 9), //To generate random string as key
        match: false,
        checkStatus: false,
        isIndeterminate: false,
        children: [],
        data:{
          id: item[this.itemId],
          text: item[this.itemText],
          subtext: item[this.itemSubText],
          status: item[this.itemStatus]
        },
        isExpanded: false
      }; 
      if(!root){

        this.treeControl.push(treeCtr);
      }else{

        root.children.push(treeCtr);
      }

      if(this.hasChild(item)){
        this.buildTreeControl(item.children,treeCtr);
      }
  });
   
  }

  hasChild(node:any){

    return node.hasOwnProperty('children') && isArray(node.children) && node.children.length > 0;
  }

  isValue(itemValue:string){

    return this.value.indexOf(itemValue) >=0;
  }

  updateValue(itemValue:string,checked:boolean){
    
    if(checked){

      if(!this.isValue(itemValue)){

        this.value.push(itemValue);
      }
    }else{
      
      const index = this.value.findIndex(t=>t == itemValue);
      
      this.value.splice(index,1);
    }
    this.onChange(this.value);
    this.updateIndeterminates();
    this.updateTreeStatus();
  }

  findInTree(text:string,root:TreeNode){
    
    root.match = false;
    root.isExpanded = false;
    for(let item of root.children){
      
      item.match = false;
      if(text && (item.data.text.toLowerCase().indexOf(text) >=0 || item.data.subtext.toLowerCase().indexOf(text) >= 0) ){
        
        root.isExpanded = true;
        item.match = true;
        this.scrollToItem(item);
        return true;
      }else{
        
        let childFinded = this.findInTree(text,item);
        if(childFinded){

          root.isExpanded = true;
          return true;
        } 
      }
    }
    return false;
  }

  private scrollToItem(node:TreeNode){

    const element = this.document.querySelector(`[data-key="${node.key}"]`);
    setTimeout(() => {
      element.scrollIntoView({ block: 'center' });  
    }, 1);
  }

  private updateIndeterminates(root: TreeNode = null){

    if(!this.handleInderminates){

      return false;
    }

    if(root == null){

      this.treeControl.forEach((node)=>this.updateIndeterminates(node));
      return false;
    }

    root.isIndeterminate = false;
    for(let item of root.children){
      
      if(this.isValue(item.data.id) ){
        
        root.isIndeterminate = true;
        this.updateIndeterminates(item);
      }else{
        
        const childChecked = this.updateIndeterminates(item);
        if(childChecked){

          root.isIndeterminate = true;
        } 
      }
    }
    return root.isIndeterminate;
  }

  /**
   * Recursive function to update check status of tree node and its children
   * @param root Root node
   * @param value Value to set
   */
  updateChildValues(root, value){

    if(root == null){

      root = this.treeControl[0];
    }
    
    if(root.data.id){

      this.updateValue(root.data.id,value);
    }

    for(let item of root.children){
      
      if (item.data.id) {
        
        this.updateValue(item.data.id,value);
      } 
      this.updateChildValues(item,value);
    }
  }

  /**
   * Function to set the tree node check status
  */
  updateTreeStatus(){

    this.allSelected = this.listControl.filter(t => t.id).length == this.value.length;
    this.someSelected = this.allSelected == false && this.value.length > 0;
  }
}
