import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ElementsForm, SectionForm } from 'src/app/shared/models';
import { FormGroup } from '@angular/forms';
import { FormDataService } from '../../../../../shared/services/form-data.service';
import { FormElement } from 'src/app/shared/enums';
import * as momento from 'moment';
import { isBoolean } from 'util';
import { asap } from 'rxjs/internal/scheduler/asap';
import { DOCUMENT } from '@angular/common';
import { FormFileUploaderSharedService } from '../../../../../shared/services/form-file-uploader-shared.service';

declare var solicitud;

@Component({
  selector: 'scc-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.scss']
})
export class FormEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formTitle: string;
  @Input() element: ElementsForm;
  @Input() sections: SectionForm[];
  data: any[] = [];
  formulario: FormGroup;

  constructor(private readonly formDataService: FormDataService, @Inject(DOCUMENT) public readonly _document: Document,
              private readonly _formFileUploaderSharedSrv: FormFileUploaderSharedService) {
    this._formFileUploaderSharedSrv.inFormEdit = true;
  }

  ngOnInit() {
    this.formulario = this.formDataService.getFormEditFormGroup(this.formDataService.activeFormEdit);
    if (!this.formulario) {
      this.formulario = this.formDataService.addFormEditFormGroup(this.formDataService.activeFormEdit);
    }
    this.sections = this.formDataService.formEditSections.get(this.formDataService.activeFormEdit);
  }

  ngAfterViewInit() {
    // Funcionalidad para editar
    if (this.formDataService.data) {
      const dates = [];

      this.sections.forEach(section => {
        section.rows.forEach(row => {
          dates.push(...(row.elements as ElementsForm[])
            .filter(fil => fil.elementType === FormElement.DATE_PICKER_INLINE)
            .map(({ fullName }) => (fullName)));
        });
      });

      const tmpData = { ...this.formDataService.data };

      dates.filter(item => !!tmpData[item]).forEach(item => {
        if (typeof tmpData[item] === 'string' && tmpData[item].length === 10) tmpData[item] = momento(tmpData[item], 'DD/MM/YYYY');
      });

      // Selects
      Object.keys(tmpData).forEach(prop => {
        if (Array.isArray(tmpData[prop])) {
          const datatypeName = this.formDataService.getKeyNamesByFullNameMap().get(prop);
          const datatypeModel = this.formDataService.getDataTypesMap().get(datatypeName);
          if (datatypeModel.selection.length === 1 && tmpData[prop].length) {
            if (tmpData[prop][0]) {
              tmpData[prop] = tmpData[prop][0].key || tmpData[prop][0];
            } else {
              tmpData[prop][0] = null;
            }
          } else if (datatypeModel.selection.length > 1 && tmpData[prop].length) {
            tmpData[prop] = tmpData[prop].map(arr => arr.key || arr);
          }
        }
      });

      try {
        asap.schedule(() => {
          this.formulario.patchValue(tmpData);
          this.formulario = this.formDataService.updateFormEditFormGroup(this.formDataService.activeFormEdit, this.formulario);
        });
      } catch (err) {
        console.error('El resultado de la transformación del FormEdit, fué defectuosa.');
      }
    } else {
      // Valores por default
      const autoSaveData = this.formDataService.getActualAutoSavedData();
      const properties = this.formulario.value;
      if (autoSaveData) {
        Object.keys(properties).forEach(key => {
          if (Array.isArray(properties[key])) {
            if (!properties[key].find(element => !!element)) properties[key] = autoSaveData[key];
          } else if (!properties[key] && isBoolean(autoSaveData[key])) {
            properties[key] = autoSaveData[key] === 'true' ? true : false;
          } else if (!properties[key] && !!autoSaveData[key]) {
            properties[key] = autoSaveData[key];
          }
        });
        this.formulario.patchValue(properties);
        solicitud.formEdit = this.formulario.controls;
      }
    }
  }

  ngOnDestroy() {
    this._formFileUploaderSharedSrv.inFormEdit = false;
  }
}
