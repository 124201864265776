export enum ColumnType {
  NORMAL = 0,
  ARRAY = 12,
  // NUMBER = 1,
  // BOLEAN = 2,
  ID = 3,
  DATE = 4,
  TASK = 5,
  MEMBERS = 6,
  PRIORITY = 7,
  CIRCLE = 8,
  INSTITUTION_RELATIONSHIP = 9,
  CONTEXT_MENU = 10, // Dont use, this column is reserved
  CHECKBOX_COLUMN = 11 // Dont use, this column is reserved
}
