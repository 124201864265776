import { Component, Input, OnInit } from '@angular/core';
import { DtoProductList } from '../../models/DtoProductList';

@Component({
  selector: 'app-custom-product-info',
  templateUrl: 'custom-product-info.component.html',
  styleUrls: [ 'custom-product-info.component.scss' ]
})

export class CustomProductInfoComponent implements OnInit {
  @Input() selectedProduct: DtoProductList;
  @Input() onlyViewer = false;
  @Input() productType: string;

  constructor() {
  }

  ngOnInit() {
  }
}
